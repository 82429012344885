import styled, { css } from "styled-components";
import Text from "../../../core/components/Text";
import TextInput from "../../../core/components/TextInput";
import Select from "../../../core/components/Select";
import PostalCodeInput from "../../../core/components/PostalCodeInput";
import NumberField from "../../../core/components/NumberField";
import Label from "../../../core/components/Label";
import Checkbox from "../../../core/components/Checkbox";
import MultiTextInput from "../../../core/components/MultiTextInput";

export const HeadingContainer = styled.div`
  padding: 30px 0 30px 30px;
  border-bottom: 1px solid #C2C2C2;
`;

export const CategoryContainer = styled.div`
  padding: 10px 30px;
`;

export const HeadingMediumContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 10px 30px 10px 30px;
  border-bottom: 1px solid #C2C2C2;
  ${HeadingContainer}:has(+ &) {
    border-bottom: none;
  }
`;

const Row = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Column = css`
  display: flex;
  flex-direction: column;
`;

export const Row0 = styled.div`
  ${Row}
`;

export const Row10 = styled.div`
  ${Row}
  gap: 10px;
`;

export const Row10WithGrow = styled.div`
  ${Row}
  gap: 10px;
  flex-grow: 1;
`;

export const Column5 = styled.div`
  ${Column}
  gap: 5px;
`;

export const Column16 = styled.div`
  ${Column}
  gap: 16px;
`;

export const SectionHeadingLarge = styled.h3`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #444444;
`;

export const SectionHeadingMedium = styled.h3`
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SectionHeadingMediumDescription = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  align-items: center;
`;

export const GridRowsContainer = styled.div`
`;

export const GridRow = styled(SectionGrid)`
  min-height: 56px;

  &:not(:last-child) {
    border-bottom: 1px solid #C2C2C2;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const LabelCell = styled.div`
  padding: 10px 30px 10px 30px;
  word-break: break-all;
`;

export const FieldCell = styled.div`
  padding: 10px 20px;
`;

export const Note = styled(Text)`
  color: #E93232;
`;

export const UnitText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LocalTextInput = styled(TextInput)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalTextInputSmall = styled(TextInput)`
  width: 80px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalTextInputGrow = styled(TextInput)`
  padding: 0 10px;
  flex-grow: 1;
`;

export const LocalTextInputExtend = styled(TextInput)`
  width: 100%;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalMultiTextInput = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
  height: 40px;
`;

export const LocalMultiTextInputSmall = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
  height: 40px;
`;

export const LocalMultiTextInputMiddle = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
  height: 60px;
`;

export const LocalNumberField = styled(NumberField)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalSelect = styled(Select)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
  border-radius: 4px;
`;

export const LocalSelectExtend = styled(Select)`
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
`;

export const LocalPostCodeInput = styled(PostalCodeInput)`  
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalCheckBox = styled(Checkbox)`
  & > div {
    margin-right: 10px;
  }
`;

export const DatePickerWrapper = styled.div`
  //This width adjusts the location of the date picker triangle
  width: 60px;
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const CheckboxesRowWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const CustomLabel = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  color: #444444;
  & > div {
    height: 17px;
    width: 28px;
    padding: 1px 4px;
    font-size: 10px;
    color: #FF730D;
    background-color: #FFEADB;
  }
`;

export const WordBreakLabel = styled(CustomLabel)`
  & > span {
    width: 132px;
  }
`;

export const NowrapLabel = styled(CustomLabel)`
  width: 180px;
`;

export const TransValFieldGroup = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto 1fr;
  gap: 10px;
`;
