import { Company } from "../../../core/types/api";
import {HousingEnsureSituation} from "../../../core/enums/supportPlans";
import {
  NON_TITLE,
  APPROPRIATE,
  APPROPRIATE_INVOLUNTARILY_LEAVE,
  NOT_OWN_HOUSING, QUARTERLY, APPROPRIATE_AFTER_CONTRACT
} from "../../../core/constants/defaultValue";
import {JobSupportPlan} from "../../types/api";

type PreparationConfig = {
  company: Company;
};

export const prepareJobSupportData = ({
  company,
}: PreparationConfig): Partial<JobSupportPlan> => {
  const initVals: Partial<JobSupportPlan> = {};

  /*** Organization section (特定技能所属機関) ***/
  //氏名又は名称（日本語）
  initVals.spOrganizationOfAffiliationJaName = company.jaName ?? "";

  //氏名又は名称（英語）
  initVals.spOrganizationOfAffiliationEnName = company.enName ?? "";

  //郵便番号
  initVals.spOrganizationOfAffiliationPostalCode = company.postalCode ?? "";

  //都道府県（日本語）
  initVals.spOrganizationOfAffiliationJaPrefecture = company.jaPrefecture ?? "";

  //都道府県（英語）
  initVals.spOrganizationOfAffiliationEnPrefecture = company.enPrefecture ?? "";

  //市区（日本語）
  initVals.spOrganizationOfAffiliationJaCityWard = company.jaCityWard ?? "";

  //市区（英語）
  initVals.spOrganizationOfAffiliationEnCityWard = company.enCityWard ?? "";

  //町村（日本語）
  initVals.spOrganizationOfAffiliationJaTown = company.jaTown ?? "";

  //町村（英語）
  initVals.spOrganizationOfAffiliationEnTown = company.jaTown ?? "";

  //番地（日本語）
  initVals.spOrganizationOfAffiliationJaAddressNumber = company.jaAddressNumber ?? "";

  //番地（英語）
  initVals.spOrganizationOfAffiliationEnAddressNumber = company.enAddressNumber ?? "";

  //建物名等（日本語）
  initVals.spOrganizationOfAffiliationJaBuilding = company.jaBuildingName ?? "";

  //建物名等（英語）
  initVals.spOrganizationOfAffiliationEnBuilding = company.enBuildingName ?? "";

  //電話番号
  initVals.spOrganizationOfAffiliationPhoneNumber = company.phoneNumber ?? "";

  /*** Support Organization Base Section (登録支援機関（基本情報）) ***/
  //登録番号
  initVals.spSupportOrganizationBaseRegistrationNumberPrefix = "23";
  initVals.spSupportOrganizationBaseRegistrationNumberSuffix = "008552";

  //登録年月日
  initVals.spSupportOrganizationBaseRegistrationDate = "2023-07-07";

  //支援業務を開始する予定年月日
  initVals.spSupportOrganizationBaseScheduledStartDate = "2023-04-15";

  //氏名又は名称（日本語）
  initVals.spSupportOrganizationBaseJaName = "トクティー株式会社";

  //氏名又は名称（英語）
  initVals.spSupportOrganizationBaseEnName = "Tokuty Co, Ltd.";

  //氏名又は名称ふりがな
  initVals.spSupportOrganizationBaseNameFurigana = "とくてぃーぶしきがいしゃ";

  //郵便番号
  initVals.spSupportOrganizationBasePostalCode = "1050022";

  //都道府県（日本語）
  initVals.spSupportOrganizationBaseJaPrefecture = "東京都";

  //都道府県（英語）
  initVals.spSupportOrganizationBaseEnPrefecture = "Tokyo";

  //市区（日本語）
  initVals.spSupportOrganizationBaseJaCityWard = "港区";

  //市区（英語）
  initVals.spSupportOrganizationBaseEnCityWard = "Minato-ku";

  //町村（日本語）
  initVals.spSupportOrganizationBaseJaTown = "海岸";

  //町村（英語）
  initVals.spSupportOrganizationBaseEnTown = "Kaigan";

  //番地（日本語）
  initVals.spSupportOrganizationBaseJaAddressNumber = "1丁目9番18号";

  //番地（英語）
  initVals.spSupportOrganizationBaseEnAddressNumber = "1-9-18";

  //建物名等（日本語）
  initVals.spSupportOrganizationBaseJaBuilding = "国際浜松町ビル2F";

  //建物名等（英語）
  initVals.spSupportOrganizationBaseEnBuilding = "International Hamamatsucho Building 2F";

  //電話番号
  initVals.spSupportOrganizationBasePhoneNumber = "03-5843-8925";

  //代表者の名字
  initVals.spSupportOrganizationBaseRepresentativeLastName = "杉原";

  //代表者の名前
  initVals.spSupportOrganizationBaseRepresentativeFirstName = "尚輔";

  //代表者の名字ふりがな
  initVals.spSupportOrganizationBaseRepresentativeLastNameFurigana = "すぎはら";

  //代表者の名前ふりがな
  initVals.spSupportOrganizationBaseRepresentativeFirstNameFurigana = "なおすけ";

  /*** Support Organization Support section (登録支援機関（支援情報）) ***/
  //役職
  initVals.spSupportOrganizationSupportManagerTitle = NON_TITLE;

  //支援を行っている1号特定技能外国人数
  initVals.spSupportOrganizationSupportSpecificSkilledWorkerCount = 0;

  //支援担当者数
  initVals.spSupportOrganizationSupportPersonCount = 0;

  //支援の適正性を担保していることの有無
  initVals.spSupportOrganizationSupportEnsureProperty = true;

  /***Support Signature section (署名欄と共通情報) ***/
  //特定技能所属機関の氏名又は名称（日本語）
  initVals.spSupportSignatureJaAffiliationName = company.jaName ?? "";

  //特定技能所属機関の氏名又は名称（英語）
  initVals.spSupportSignatureEnAffiliationName = company.enName ?? "";

  //「Ⅳ. 支援内容」の実施言語と担当者情報に、全て以下の情報を使用する
  initVals.spSupportSignatureUseCommonInformation = true;

  /***Guidance section (Ⅳ. 支援内容（1. 事前ガイダンスの提供）) ***/
  //ア. 情報提供内容等
  //a. 従事する業務の内容、報酬の額その他の労働条件に関する事項
  //実施予定の有無
  initVals.spGuidanceWorkingConditionsSchedule = true;

  //委託の有無
  initVals.spGuidanceWorkingConditionsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceWorkingConditionsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceWorkingConditionsEntrustingMethodVideoCall = true;

  //b. 本邦において行うことができる活動の内容
  //実施予定の有無
  initVals.spGuidanceActivitySchedule = true;

  //委託の有無
  initVals.spGuidanceActivityEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceActivityEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceActivityEntrustingMethodVideoCall = true;

  //c. 入国に当たっての手続きに関する事項
  //実施予定の有無
  initVals.spGuidanceProceduresSchedule = true;

  //委託の有無
  initVals.spGuidanceProceduresEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceProceduresEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceProceduresEntrustingMethodVideoCall = true;

  //d. 保証金の徴収、契約の不履行についての違約金契約などの締結の禁止
  //実施予定の有無
  initVals.spGuidanceProhibitionsSchedule = true;

  //委託の有無
  initVals.spGuidanceProhibitionsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceProhibitionsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceProhibitionsEntrustingMethodVideoCall = true;

  //e. 入国の準備に関し外国の機関に支払った費用について、当該費用の額及び内訳を十分に理解して支払わせなければならないこと
  //実施予定の有無
  initVals.spGuidanceUnderstandingCostsSchedule = true;

  //委託の有無
  initVals.spGuidanceUnderstandingCostsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceUnderstandingCostsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceUnderstandingCostsEntrustingMethodVideoCall = true;

  //f. 支援に関する費用を負担させないこととしていること
  //実施予定の有無
  initVals.spGuidanceNoBearingCostsSchedule = true;

  //委託の有無
  initVals.spGuidanceNoBearingCostsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceNoBearingCostsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceNoBearingCostsEntrustingMethodVideoCall = true;

  //g. 入国する際の送迎に関する支援の内容
  //実施予定の有無
  initVals.spGuidancePickUpDropOffSchedule = true;

  //委託の有無
  initVals.spGuidancePickUpDropOffEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidancePickUpDropOffEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidancePickUpDropOffEntrustingMethodVideoCall = true;

  //h. 住居の確保に関する支援の内容
  //実施予定の有無
  initVals.spGuidanceHousingSchedule = true;

  //委託の有無
  initVals.spGuidanceHousingEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceHousingEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceHousingEntrustingMethodVideoCall = true;

  //i. 相談・苦情の対応に関する内容
  //実施予定の有無
  initVals.spGuidanceRequestsSchedule = true;

  //委託の有無
  initVals.spGuidanceRequestsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidanceRequestsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidanceRequestsEntrustingMethodVideoCall = true;

  //j. 特定技能所属機関等の支援担当者指名及び連絡先
  //実施予定の有無
  initVals.spGuidancePersonContactSchedule = true;

  //委託の有無
  initVals.spGuidancePersonContactEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spGuidancePersonContactEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spGuidancePersonContactEntrustingMethodVideoCall = true;

  //イ. 実施言語
  initVals.spGuidanceScheduleTime = 3;

  /***Pick Up and Drop Off section section (Ⅳ. 支援内容（2. 出入国する際の送迎）) ***/
  //a. 到着空港等での出迎え及び特定技能所属機関又は住居までの送迎
  //実施予定の有無
  initVals.spPickUpDropOffArrivalSchedule = true;

  //実施予定時期
  initVals.spPickUpDropOffArrivalScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spPickUpDropOffArrivalEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spPickUpDropOffArrivalEntrustingPersonTitle = NON_TITLE;

  //出迎え空港等
  initVals.spPickUpDropOffArrivalAirport = true;

  //送迎方法
  initVals.spPickUpDropOffArrivalMethod = true;

  //b. 出国予定空港等までの送迎及び保安検査場入場までの出国手続の補助
  //実施予定の有無
  initVals.spPickUpDropOffDepartureSchedule = true;

  //実施予定時期
  initVals.spPickUpDropOffDepartureScheduleDateContent = APPROPRIATE_AFTER_CONTRACT;

  //委託の有無
  initVals.spPickUpDropOffDepartureEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spPickUpDropOffDepartureEntrustingPersonTitle = NON_TITLE;

  //出迎え空港等
  initVals.spPickUpDropOffDepartureAirport = true;

  //未定
  initVals.spPickUpDropOffDepartureAirportNameUndecided = true;

  //送迎方法
  initVals.spPickUpDropOffDepartureMethod = true;

  /***Life section (Ⅳ. 支援内容（3. 適切な住居の確保に係る支援・生活に必要な契約に係る支援）) ***/
  //ア. 適切な住居の確保に係る支援
  //a. 特定技能外国人が賃貸借契約を行う場合
  //実施予定の有無
  initVals.spLifeForeignerHousingSchedule = true;

  //実施予定時期
  initVals.spLifeForeignerHousingScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spLifeForeignerHousingEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeForeignerHousingEntrustingPersonTitle = NON_TITLE;

  //b. 特定技能所属機関が物件を借り上げる場合
  //実施予定の有無
  initVals.spLifeRentalHousingSchedule = true;

  //実施予定時期
  initVals.spLifeRentalHousingScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spLifeRentalHousingEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeRentalHousingEntrustingPersonTitle = NON_TITLE;

  //c. 特定技能所属機関の自己所有物件の場合
  //実施予定の有無
  initVals.spLifeOwnHousingSchedule = false;

  //実施予定時期
  initVals.spLifeOwnHousingScheduleDateContent = NOT_OWN_HOUSING;

  //委託の有無
  initVals.spLifeOwnHousingEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeOwnHousingEntrustingPersonTitle = NON_TITLE;

  //d. 情報提供する又は住居とし て提供する住居の概要（確保予定の場合を含む）
  //住居の確保状況
  initVals.spLifeHousingOverviewEnsureSituation = HousingEnsureSituation.Already;

  //同居人数計
  initVals.spLifeHousingOverviewLivingTogetherNumberOfPeople = 0;

  //居間の広さ
  initVals.spLifeHousingOverviewLivingRoomSize_7_5OrMore = true;

  //寝室の広さ
  initVals.spLifeHousingOverviewBedroomSize_4_5OrMore = true;

  //イ. 生活に必要な契約に係る支援
  //a. 銀行その他の金融機関における預金口座又は貯金口座の開設の手続の補助
  //実施予定の有無
  initVals.spLifeAccountOpeningSchedule = true;

  //実施予定時期
  initVals.spLifeAccountOpeningScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spLifeAccountOpeningEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeAccountOpeningEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spLifeAccountOpeningEntrustingMethodProviding = true;
  initVals.spLifeAccountOpeningEntrustingMethodAccompanying = true;

  //b. 携帯電話の利用に関する契約の手続の補助
  //実施予定の有無
  initVals.spLifeMobilePhoneSchedule = true;

  //実施予定時期
  initVals.spLifeMobilePhoneScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spLifeMobilePhoneEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeMobilePhoneEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spLifeMobilePhoneEntrustingMethodProviding = true;
  initVals.spLifeMobilePhoneEntrustingMethodAccompanying = true;

  //c. 電気・水道・ガス等のライフラインに関する手続の補助
  //実施予定の有無
  initVals.spLifeLifelineSchedule = true;

  //実施予定時期
  initVals.spLifeLifelineScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spLifeLifelineEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spLifeLifelineEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spLifeLifelineEntrustingMethodProviding = true;
  initVals.spLifeLifelineEntrustingMethodAccompanying = true;

  /***Orientation section (Ⅳ. 支援内容（4. 生活オリエンテーションの実施）) ***/
  //ア. 情報提供内容等
  //a. 本邦での生活一般に関する事項
  //実施予定の有無
  initVals.spOrientationGeneralLifeSchedule = true;

  //委託の有無
  initVals.spOrientationGeneralLifeEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationGeneralLifeEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationGeneralLifeEntrustingMethodInPerson = true;
  initVals.spOrientationGeneralLifeEntrustingMethodVideoCall = true;

  //b. 法令の規定により外国人が履行しなければならない国又は地方公共団体の機関に対する届出その他の手続に関する事項及び必要に応じて同行し手続を補助すること
  //実施予定の有無
  initVals.spOrientationProcedureSchedule = true;

  //委託の有無
  initVals.spOrientationProcedureEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationProcedureEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationProcedureEntrustingMethodInPerson = true;
  initVals.spOrientationProcedureEntrustingMethodVideoCall = true;

  //c. 相談・苦情の連絡先、申出をすべき国又は地方公共団体の機関の連絡先
  //実施予定の有無
  initVals.spOrientationRequestsSchedule = true;

  //委託の有無
  initVals.spOrientationRequestsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationRequestsEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationRequestsEntrustingMethodInPerson = true;
  initVals.spOrientationRequestsEntrustingMethodVideoCall = true;

  //d. 十分に理解することができる言語により医療を受けることができる医療機関に関する事項
  //実施予定の有無
  initVals.spOrientationMedicalSchedule = true;

  //委託の有無
  initVals.spOrientationMedicalEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationMedicalEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationMedicalEntrustingMethodInPerson = true;
  initVals.spOrientationMedicalEntrustingMethodVideoCall = true;

  //e. 防災・防犯に関する事項、急病その他の緊急時における対応に必要な事項
  //実施予定の有無
  initVals.spOrientationEmergencySchedule = true;

  //委託の有無
  initVals.spOrientationEmergencyEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationEmergencyEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationEmergencyEntrustingMethodInPerson = true;
  initVals.spOrientationEmergencyEntrustingMethodVideoCall = true;

  //f. 出入国又は労働に関する法令規定の違反を知ったときの対応方法その他当該外国人の法的保護に必要な事項
  //実施予定の有無
  initVals.spOrientationViolationSchedule = true;

  //委託の有無
  initVals.spOrientationViolationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOrientationViolationEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spOrientationViolationEntrustingMethodInPerson = true;
  initVals.spOrientationViolationEntrustingMethodVideoCall = true;

  //ウ. 実施予定時間
  initVals.spOrientationScheduleTime = 8;

  /***Learning Opportunities section (Ⅳ. 支援内容（5. 日本語学習機会の提供）) ***/
  //a. 日本語教室や日本語教育機関に関する入学案内の情報を提供し、必要に応じて同行して入学の手続の補助を行う
  //実施予定の有無
  initVals.spOpportunitiesAdmissionSchedule = true;

  //実施予定時期
  initVals.spOpportunitiesAdmissionScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spOpportunitiesAdmissionEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOpportunitiesAdmissionEntrustingPersonTitle = NON_TITLE;

  //b. 自主学習のための日本語学習教材やオンラインの日本語講座に関する情報の提供し、必要に応じて日本語学習教材の入手やオンラインの日本語講座の利用契約手続の補助を行う
  //実施予定の有無
  initVals.spOpportunitiesMaterialsSchedule = true;

  //実施予定時期
  initVals.spOpportunitiesMaterialsScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spOpportunitiesMaterialsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOpportunitiesMaterialsEntrustingPersonTitle = NON_TITLE;

  //c. １号特定技能外国人との合意の下、日本語教師と契約して１号特定技能外国人に日本語の講習の機会を提供する
  //実施予定の有無
  initVals.spOpportunitiesTeachersSchedule = true;

  //実施予定時期
  initVals.spOpportunitiesTeachersScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spOpportunitiesTeachersEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spOpportunitiesTeachersEntrustingPersonTitle = NON_TITLE;

  /***Requests section (Ⅳ. 支援内容（6. 相談又は苦情への対応））) ***/
  //ア. 対応内容等
  //a. 相談又は苦情に対し、遅滞なく十分に理解できる言語により適切に対応し、必要な助言及び指導を行う
  //実施予定の有無
  initVals.spRequestsProperlyResponsesSchedule = true;

  //実施予定時期
  initVals.spRequestsProperlyResponsesScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spRequestsProperlyResponsesEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spRequestsProperlyResponsesEntrustingPersonTitle = NON_TITLE;

  //b. 必要に応じ、相談内容に対応する関係行政機関を案内し、同行する等必要な手続の補助を行う
  initVals.spRequestsAdministrativeOrgansSchedule = true;

  //実施予定時期
  initVals.spRequestsAdministrativeOrgansScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spRequestsAdministrativeOrgansEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spRequestsAdministrativeOrgansEntrustingPersonTitle = NON_TITLE;

  //イ. 実施方法
  //対応時間
  initVals.spRequestsResponseHoursMonday = true;
  initVals.spRequestsResponseHoursMondayStartTime = "09:00";
  initVals.spRequestsResponseHoursMondayEndTime = "18:00";
  initVals.spRequestsResponseHoursTuesday = true;
  initVals.spRequestsResponseHoursTuesdayStartTime = "09:00";
  initVals.spRequestsResponseHoursTuesdayEndTime = "18:00";
  initVals.spRequestsResponseHoursWednesday = true;
  initVals.spRequestsResponseHoursWednesdayStartTime = "09:00";
  initVals.spRequestsResponseHoursWednesdayEndTime = "18:00";
  initVals.spRequestsResponseHoursThursday = true;
  initVals.spRequestsResponseHoursThursdayStartTime = "09:00";
  initVals.spRequestsResponseHoursThursdayEndTime = "18:00";
  initVals.spRequestsResponseHoursFriday = true;
  initVals.spRequestsResponseHoursFridayStartTime = "09:00";
  initVals.spRequestsResponseHoursFridayEndTime = "18:00";
  initVals.spRequestsResponseHoursSaturday = true;
  initVals.spRequestsResponseHoursSaturdayStartTime = "09:00";
  initVals.spRequestsResponseHoursSaturdayEndTime = "17:00";
  initVals.spRequestsResponseHoursSunday = true;
  initVals.spRequestsResponseHoursSundayStartTime = "09:00";
  initVals.spRequestsResponseHoursSundayEndTime = "17:00";
  initVals.spRequestsResponseHoursHoliday = true;
  initVals.spRequestsResponseHoursHolidayStartTime = "09:00";
  initVals.spRequestsResponseHoursHolidayEndTime = "17:00";

  //相談方法
  initVals.spRequestsMethodPhone = true;
  initVals.spRequestsMethodPhoneNumber = "03-5843-8925";
  initVals.spRequestsMethodEmail = true;
  initVals.spRequestsMethodEmailAddress = "candidates-team@tokuty.jp";

  //緊急対応時
  initVals.spRequestsEmergencyMethodPhone = true;
  initVals.spRequestsEmergencyMethodPhoneNumber = "080-7805-8010";
  initVals.spRequestsEmergencyMethodEmail = true;
  initVals.spRequestsEmergencyMethodEmailAddress = "tamura@tokuty.jp";

  /***Interactions section (Ⅳ. 支援内容（7. 日本人との交流促進に係る支援）) ***/
  //a. 必要に応じ、地方公共団体やボランティア団体等が主催する地域住民との交流の場に関する情報の提供や地域の自治会等の案内を行い、各行事等への参加の手続の補助を行うほか、必要に応じて同行して各行事の注意事項や実施方法を説明するなどの補助を行う
  //実施予定の有無
  initVals.spInteractionsProvidingSchedule = true;

  //実施予定時期
  initVals.spInteractionsProvidingScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spInteractionsProvidingEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInteractionsProvidingEntrustingPersonTitle = NON_TITLE;

  //b. 日本の文化を理解するために必要な情報として、就労又は生活する地域の行事に関する案内を行うほか、必要に応じて同行し現地で説明するなどの補助を行う
  //実施予定の有無
  initVals.spInteractionsCulturalSchedule = true;

  //実施予定時期
  initVals.spInteractionsCulturalScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spInteractionsCulturalEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInteractionsCulturalEntrustingPersonTitle = NON_TITLE;

  /***Job Change section (Ⅳ. 支援内容（8. 非自発的離職時の転職支援）) ***/
  //a. 所属する業界団体や関連企業等を通じて次の受入れ先に関する情報を入手し提供する
  //実施予定の有無
  initVals.spJobChangeAcceptanceSchedule = true;

  //実施予定時期
  initVals.spJobChangeAcceptanceScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeAcceptanceEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeAcceptanceEntrustingPersonTitle = NON_TITLE;

  //b. 公共職業安定所、その他の職業安定機関等を案内し、必要に応じて支援対象者に同行して次の受入れ先を探す補助を行う
  //実施予定の有無
  initVals.spJobChangeIntroductionSchedule = true;

  //実施予定時期
  initVals.spJobChangeIntroductionScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeIntroductionEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeIntroductionEntrustingPersonTitle = NON_TITLE;

  //c. １号特定技能外国人の希望条件、技能水準、日本語能力等を踏まえ、適切に職業相談・職業紹介が受けられるよう又は円滑に就職活動が行えるよう推薦状を作成する
  //実施予定の有無
  initVals.spJobChangeRecommendationSchedule = true;

  //実施予定時期
  initVals.spJobChangeRecommendationScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeRecommendationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeRecommendationEntrustingPersonTitle = NON_TITLE;

  //d. 職業紹介事業の許可又は届出を受けて職業紹介を行うことができる場合は、就職先の紹介あっせんを行う
  //実施予定の有無
  initVals.spJobChangeMediationSchedule = true;

  //実施予定時期
  initVals.spJobChangeMediationScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeMediationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeMediationEntrustingPersonTitle = NON_TITLE;

  //e. １号特定技能外国人が求職活動をするために必要な有給休暇を付与する
  //実施予定の有無
  initVals.spJobChangePaidHolidaysSchedule = true;

  //実施予定時期
  initVals.spJobChangePaidHolidaysScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //f. 離職時に必要な行政手続について情報を提供する
  //実施予定の有無
  initVals.spJobChangeProceduresSchedule = true;

  //実施予定時期
  initVals.spJobChangeProceduresScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeProceduresEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeProceduresEntrustingPersonTitle = NON_TITLE;

  //g. 倒産等により、転職のための支援が適切に実施できなくなることが見込まれるときは、それに備え、当該機関に代わって支援を行う者を確保する
  //実施予定の有無
  initVals.spJobChangeBankruptcySchedule = true;

  //実施予定時期
  initVals.spJobChangeBankruptcyScheduleDateContent = APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.spJobChangeBankruptcyEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spJobChangeBankruptcyEntrustingPersonTitle = NON_TITLE;

  /***Interviews section (Ⅳ. 支援内容（9. 定期的な面談の実施・行政機関への通報）) ***/
  //ア. 面談内容等
  //a. １号特定技能外国人の労働状況や生活状況を確認するため、当該外国人及びその監督をする立場にある者それぞれと定期的な面談（3か月に1回以上）を実施する
  //実施予定の有無
  initVals.spInterviewsLaborSituationSchedule = true;

  //実施予定時期
  initVals.spInterviewsLaborSituationScheduleDateContent = QUARTERLY;

  //委託の有無
  initVals.spInterviewsLaborSituationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInterviewsLaborSituationEntrustingPersonTitle = NON_TITLE;

  //実施方法
  initVals.spInterviewsLaborSituationEntrustingMethodInPerson = true;

  //b. 再確認のため、生活オリエンテーションにおいて提供した情報について、改めて提供する
  //実施予定の有無
  initVals.spInterviewsReconfirmationSchedule = true;

  //実施予定時期
  initVals.spInterviewsReconfirmationScheduleDateContent = QUARTERLY;

  //委託の有無
  initVals.spInterviewsReconfirmationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInterviewsReconfirmationEntrustingPersonTitle = NON_TITLE;

  //c. 労働基準法その他の労働に関する法令の規定に違反していることを知ったときは、労働基準監督署その他の関係行政機関へ通報する
  //実施予定の有無
  initVals.spInterviewsLegalViolationSchedule = true;

  //実施予定時期
  initVals.spInterviewsLegalViolationScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spInterviewsLegalViolationEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInterviewsLegalViolationEntrustingPersonTitle = NON_TITLE;

  //d. 資格外活動等の入管法違反又は旅券及び在留カードの取上げ等その他の問題の発生を知ったときは、その旨を地方出入国在留管理局に通報する
  //実施予定の有無
  initVals.spInterviewsOtherProblemsSchedule = true;

  //実施予定時期
  initVals.spInterviewsOtherProblemsScheduleDateContent = APPROPRIATE;

  //委託の有無
  initVals.spInterviewsOtherProblemsEntrusting = false;

  //委託を受けた実施担当者の役職名
  initVals.spInterviewsOtherProblemsEntrustingPersonTitle = NON_TITLE;

  return initVals;
};
