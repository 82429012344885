import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import JobListTable from './JobListTable';
import SearchPanel from './SearchPanel';
import ACTIONS from '../../../core/constants/actions';
import { createJob, getJobs } from '../../apis/job';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Job } from '../../types/api';
import { useNavigate } from 'react-router-dom';
import { JobState } from '../../enums/job';
import { prepareInitializedJobFormData } from '../JobFormPage/JobFormInitialization';

interface JobListPageProps {
}

const LocalPage = styled(Page)`
  padding-top: 0;
  
  > * {
    width: 100%;
  }
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TopRightContainer = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px 17px 133px 17px;
  overflow: auto;
`;

const JobListPage: FunctionComponent<JobListPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { company } = state;
    const [keyword, setKeyword] = useState('');
    const [jobs, setJobs] = useState<Job[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const searchJobs = async (keyword: string, parameters: { [key: string]: any } = {}, skip: number = 0) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('job.fetching_jobs'),
            },
        });
        try {
            const jobs = await getJobs({
                employerId: company?.employer?.id,
                searchString: keyword === ''? null : keyword
            });
            setJobs(jobs);
        } catch (e) {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: "warning",
                    message: t("core.error_occurred"),
                },
            });
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleCreate = async () => {
        try {
            dispatch({ type: ACTIONS.START_LOADING });
            const presetData = await prepareInitializedJobFormData({
                company: state.company,
            });
            const job = await createJob({
                state: JobState.Draft,
                ...presetData,
            });
            navigate(`/jobs/${job.id}/edit`);
        } catch {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: "warning",
                    message: t("core.error_occurred"),
                },
            });
        } finally {
            dispatch({ type: ACTIONS.STOP_LOADING });
        }
    }

    const handleSearch = () => {
        searchJobs(keyword);
    };

    const handleItemClick = (index: number) => {
        navigate(`/jobs/${jobs[index].id}`);
    };

    useEffect(() => {
        searchJobs('', { archived: false });
    }, []);

    return (
        <LocalPage>
            <TopContainer>
                <SearchPanel onKeywordChange={setKeyword} onSearch={handleSearch} />
                <TopRightContainer>
                    <Button
                        variant="quart-add"
                        onClick={handleCreate}
                    >
                        {t('job.create_job_post')}
                    </Button>
                </TopRightContainer>
            </TopContainer>
            <Content>
                <JobListTable jobs={jobs} onItemClick={handleItemClick} />
            </Content>
        </LocalPage>
    );
};

export default JobListPage;