import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChatMessageType } from '../types';
import ChatMessages from './ChatMessages';
import MultiTextInput from '../../core/components/MultiTextInput';
import Button from '../../core/components/Button';
import Text from '../../core/components/Text';

interface ChatSectionProps extends HTMLAttributes<HTMLDivElement> {
    messages: ChatMessageType[];
    onClickSubmit: (message: string) => void;
    onClickDelete: (index: number) => void;
}

const Container = styled.div`
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-height: 50px;
  min-height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #E5E5E5;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 0 20px 20px 20px;
`;

const ErrorText = styled(Text)`
  align-self: flex-start;
  margin-top: 2px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #E93232;
`;

const ChatSection: FunctionComponent<ChatSectionProps> = ({
    messages,
    onClickSubmit,
    onClickDelete,
    ...props
}) => {
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const handleSend = () => {
        onClickSubmit?.(message);
        setMessage('');
    };

    return (
        <Container {...props}>
            <TopContainer>
                {t('proposal.messages')}
            </TopContainer>
            <BottomContainer>
                <ChatMessages 
                    messages={messages} 
                    onClickDelete={onClickDelete}
                />
                <MultiTextInput
                    style={{
                        width: '100%',
                        marginTop: 20,
                        resize: 'vertical',
                    }}
                    invalid={message.length > 500}
                    placeholder={t('proposal.send_a_message')}
                    value={message}
                    onTextChange={setMessage}
                />
                {message.length > 500 && (
                    <ErrorText>{t('proposal.chat_text_restriction')}</ErrorText>
                )}
                <Button
                    style={{ marginTop: 5, minWidth: 100 }}
                    disabled={!message || message.length > 500}
                    variant="quart"
                    onClick={handleSend}
                >
                    {t('core.send')}
                </Button>
            </BottomContainer>
        </Container>
    );
};

export default ChatSection;