import styled from "styled-components";
import { EmploymentConditionEditableTranslation } from "../../../types";
import { FunctionComponent } from "react";
import Text from "../../../../core/components/Text";
import { TransActionButton } from "../commonStyles";

interface MultilingualInputProps {
  translation?: Partial<EmploymentConditionEditableTranslation> | null;
  onClickInput?: () => void;
  onClickReflectOrgInfo?: () => void;
  showReflectOrgInfo?: boolean;
  disabled?: boolean;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const TransText = styled(Text)`
  color: #999;
`;

const MultiLangInput: FunctionComponent<MultilingualInputProps> = ({
  translation,
  onClickInput,
  onClickReflectOrgInfo,
  showReflectOrgInfo = true,
  disabled
}) => {
  return (
    <Container>
      <ButtonsContainer>
        <TransActionButton
          disabled={disabled}
          onClick={onClickInput}
        >
          入力
        </TransActionButton>
        { showReflectOrgInfo &&
          <TransActionButton
            disabled={disabled}
            onClick={onClickReflectOrgInfo}
          >
            組織情報を反映
          </TransActionButton>
        }
      </ButtonsContainer>
      <div>
        <TransText>{translation?.jaManual || "説明テキストを入力"}</TransText>
        <TransText>
          {translation?.enManual || translation?.enAuto || "Enter descriptive text"}
        </TransText>
        <TransText>
          {translation?.idManual || translation?.idAuto || "Masukkan teks deskriptif"}
        </TransText>
        <TransText>
          {translation?.neManual || translation?.neAuto || "वर्णनात्मक पाठ प्रविष्ट गर्नुहोस्"}
        </TransText>
        <TransText>
          {translation?.viManual || translation?.viAuto || "Nhập văn bản mô tả"}
        </TransText>
        <TransText>
          {translation?.myManual || translation?.myAuto || "ဖော်ပြချက်စာသားကို ရိုက်ထည့်ပါ။"}
        </TransText>
      </div>
    </Container>
  );
};

export default MultiLangInput;
