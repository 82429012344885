import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import {
  AmountContainer,
  Section,
  SectionContent,
  SectionIndex,
} from "./styles";
import {
  Column5,
  InputValue,
  RightAlignedContainer,
  Row,
  Item10,
  Segment,
} from "../../commonStyles";
import { TemplateSectionProps } from "../../types";
import { formatAmount } from "../../util";
import { Trans, useTranslation } from "react-i18next";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface DeductionsSectionProps extends TemplateSectionProps {}


const DeductionsSection: FunctionComponent<DeductionsSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang };
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;

  const [taxes, setTaxes] = useState<string>("");
  const [socialInsurancePremiums, setSocialInsurancePremiums] = useState<string>("");
  const [employmentInsurancePremiums, setEmploymentInsurancePremiums] = useState<string>("");
  const [foodExpenses, setFoodExpenses] = useState<string>("");
  const [housingExpenses, setHousingExpenses] = useState<string>("");
  const [otherDeductions1Name, setOtherDeductions1Name] = useState<string>("");
  const [otherDeductions1NameTrans, setOtherDeductions1NameTrans] = useState<string>("");
  const [otherDeductions1Amount, setOtherDeductions1Amount] = useState<string>("");
  const [otherDeductions2Name, setOtherDeductions2Name] = useState<string>("");
  const [otherDeductions2NameTrans, setOtherDeductions2NameTrans] = useState<string>("");
  const [otherDeductions2Amount, setOtherDeductions2Amount] = useState<string>("");
  const [otherDeductions3Name, setOtherDeductions3Name] = useState<string>("");
  const [otherDeductions3NameTrans, setOtherDeductions3NameTrans] = useState<string>("");
  const [otherDeductions3Amount, setOtherDeductions3Amount] = useState<string>("");
  const [otherDeductions4Name, setOtherDeductions4Name] = useState<string>("");
  const [otherDeductions4NameTrans, setOtherDeductions4NameTrans] = useState<string>("");
  const [otherDeductions4Amount, setOtherDeductions4Amount] = useState<string>("");
  const [totalDeductions, setTotalDeductions] = useState<string>("");

  const DeductionAmount = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;
  
  const TotalDeducions = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;


  useEffect(() => {
    if (!ec) 
      return;

    setTaxes(formatAmount(ec.deductionsTaxes));
    setSocialInsurancePremiums(formatAmount(ec.deductionsSocialInsurancePremiums));
    setEmploymentInsurancePremiums(formatAmount(ec.deductionsEmploymentInsurancePremiums));
    setFoodExpenses(formatAmount(ec.deductionsFoodExpenses));
    setHousingExpenses(formatAmount(ec.deductionsHousingExpenses));
    setOtherDeductions1Name(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.DeductionsOtherDeductions1Name)
    );
    setOtherDeductions1NameTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.DeductionsOtherDeductions1Name)
    );
    setOtherDeductions1Amount(formatAmount(ec.deductionsOtherDeductions_1Amount));
    setOtherDeductions2Name(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.DeductionsOtherDeductions2Name)
    );
    setOtherDeductions2NameTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.DeductionsOtherDeductions2Name)
    );
    setOtherDeductions2Amount(formatAmount(ec.deductionsOtherDeductions_2Amount));
    setOtherDeductions3Name(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.DeductionsOtherDeductions3Name)
    );
    setOtherDeductions3NameTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.DeductionsOtherDeductions3Name)
    );
    setOtherDeductions3Amount(formatAmount(ec.deductionsOtherDeductions_3Amount));
    setOtherDeductions4Name(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.DeductionsOtherDeductions4Name)
    );
    setOtherDeductions4NameTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.DeductionsOtherDeductions4Name)
    );
    setOtherDeductions4Amount(formatAmount(ec.deductionsOtherDeductions_4Amount));
    setTotalDeductions(formatAmount(ec.deductionsTotalDeductions));
  }, [ec]);

  return (
    <Section>
      <SectionIndex>4.</SectionIndex>
      <SectionContent>
        <Segment>
          <span>賃金支払時に控除する項目</span>
          <br />
          <span>{tFixed("deduction_items")}</span>
        </Segment>
        <Segment>
          <Column5>
            <Row>
              <Item10>(a)</Item10>
              <Item10>税金</Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{taxes}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(b)</Item10>
              <Item10>社会保険料</Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{socialInsurancePremiums}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(c)</Item10>
              <Item10>雇用保険料</Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{employmentInsurancePremiums}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(d)</Item10>
              <Item10>食費</Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{foodExpenses}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(e)</Item10>
              <Item10>居住費</Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{housingExpenses}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(f)</Item10>
              <Item10>
                <InputValue>{otherDeductions1Name}</InputValue>
              </Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{otherDeductions1Amount}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(g)</Item10>
              <Item10>
                <InputValue>{otherDeductions2Name}</InputValue>
              </Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{otherDeductions2Amount}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(h)</Item10>
              <Item10>
                <InputValue>{otherDeductions3Name}</InputValue>
              </Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{otherDeductions3Amount}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <Row>
              <Item10>(i)</Item10>
              <Item10>
                <InputValue>{otherDeductions4Name}</InputValue>
              </Item10>
              <Item10>（約</Item10>
              <Item10>
                <InputValue>{otherDeductions4Amount}</InputValue>
              </Item10>
              <Item10>円）</Item10>
            </Row>
            <RightAlignedContainer>
              <Item10>控除する金額</Item10>
              <AmountContainer>
                <Row>
                  <Item10>約</Item10>
                  <Item10>
                    <InputValue>{totalDeductions}</InputValue>
                  </Item10>
                  <Item10> 円（合計）</Item10>
                </Row>
              </AmountContainer>
            
            </RightAlignedContainer>
          </Column5>
        </Segment>
        <Segment>
          <Column5>
            <Row>
              <Item10>(a)</Item10>
              <Item10>{tFixed("tax")}</Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: taxes,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(b)</Item10>
              <Item10>{tFixed("social_insurance")}</Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: socialInsurancePremiums,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(c)</Item10>
              <Item10>{tFixed("employment_insurance")}</Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: employmentInsurancePremiums,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(d)</Item10>
              <Item10>{tFixed("food_expenses")}</Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: foodExpenses,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(e)</Item10>
              <Item10>{tFixed("housing_expenses")}</Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: housingExpenses,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(f)</Item10>
              <Item10>
                <InputValue>{otherDeductions1NameTrans}</InputValue>
              </Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: otherDeductions1Amount,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(g)</Item10>
              <Item10>
                <InputValue>{otherDeductions2NameTrans}</InputValue>
              </Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: otherDeductions2Amount,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(h)</Item10>
              <Item10>
                <InputValue>{otherDeductions3NameTrans}</InputValue>
              </Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: otherDeductions3Amount,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <Row>
              <Item10>(i)</Item10>
              <Item10>
                <InputValue>{otherDeductions4NameTrans}</InputValue>
              </Item10>
              <Trans
                i18nKey={getTKey("deduction_amount")}
                tOptions={tOptions}
                values={{
                  amount: otherDeductions4Amount,
                }}
                components={{
                  seg: <Item10/>,
                  amount: <DeductionAmount />
                }}
              />
            </Row>
            <RightAlignedContainer>
              <Item10>{tFixed("total_deductions")}</Item10>
              <AmountContainer>
                <Row>
                  <Trans
                    i18nKey={getTKey("total_deductions_amount")}
                    tOptions={tOptions}
                    values={{
                      amount: totalDeductions,
                    }}
                    components={{
                      seg: <Item10/>,
                      amount: <TotalDeducions />
                    }}
                  />
                </Row>
              </AmountContainer>
            </RightAlignedContainer>
          </Column5>
        </Segment>
      </SectionContent>
    </Section>
  );
};

export default DeductionsSection;
