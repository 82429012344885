import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import CheckMark from "../../components/CheckMark";
import { Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import { MOBILE_DEVICE } from "../../../../../core/constants/styles";
import {  EcPaymentMethod, EcWagePaymentType, } from "../../../../../core/enums/employmentConditions";
import { Trans, useTranslation } from "react-i18next";
import { DAY_OF_MONTH_ITEMS } from "../../../../../job/constants/options";
import { TemplateSectionProps } from "../../types";
import { LanguageCode } from "../../../../../core/enums/language";
import { Row, Segment, FlexRowColumn, Item10, Item5, Column5, InputValue, Grid5, Item20 } from "../../commonStyles";
import { isEmpty } from "../../../../../core/utils";
import { EditableTranslationKind } from "../../../../../job/enums/translation";
import { PAYROLL_MONTH_OPTIONS } from "../../../../../core/options/payment";
import { PayrollMonth } from "../../../../../core/enums/payment";


interface CompensationSectionProps extends TemplateSectionProps {
}

const GroupContainer = styled.div`
  width: 100%;
  border: 1px solid black;
  padding: 10px 10px 10px 0;
  margin-bottom: 10px;
`;

const HoursInfoContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;

  @media ${MOBILE_DEVICE} {
    display: flex;
    flex-direction: column;
    gap: 0;
  
  }
`;

const LocalRowWithMargin = styled(Row)`
  width: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
`;

const InputValueFieldInJa = styled.div`
  word-break: break-all;
  min-width: 47px;
`;

const InputValueField = styled.div`
  word-break: break-all;
  min-width: 43px;
`;

const CompensationSection: FunctionComponent<CompensationSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToJa = i18n.getFixedT(LanguageCode.Japanese);
  const tFixedToTransLang = i18n.getFixedT(transLang);
  const tFixedToTransLangWithPrefix = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLangWithPrefix(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const defaultMonthOptionLabel = PAYROLL_MONTH_OPTIONS.find(i => i.value === PayrollMonth.Current)?.label || '';
  const [baseWagePaymentType, setBaseWagePaymentType] = useState<string>("");
  const [baseWageAmount, setBaseWageAmount] = useState<number>();
  const [benefits, setBenefits] = useState<string>("");
  const [benefitsTrans, setBenefitsTrans] = useState<string>("");
  const [overtimeWithin60hours, setOvertimeWithin60hours] = useState<number | null>();
  const [overtimeOver60hours, setOvertimeOver60hours] = useState<number | null>();
  const [overtimeOverNormalHours, setOvertimeOverNormalHours] = useState<number | null>();
  const [holidayLegal, setHolidayLegal] = useState<number | null>();
  const [holidayOtherThanLegal, setHolidayOtherThanLegal] = useState<number | null>();
  const [midnight, setMidnight] = useState<number | null>();
  const [payrollCutoffDate1, setPayrollCutoffDate1] = useState<string>("");
  const [payrollCutoffDateLabelInJa1, setPayrollCutoffDateLabelInJa1] = useState<string>("");
  const [payrollCutoffDateLabelInTransLang1, setPayrollCutoffDateLabelInTransLang1] = useState<string>("");
  const [payrollDate1, setPayrollDate1] = useState<string>("");
  const [payrollMonthLabelInJa1, setPayrollMonthLabelInJa1] = useState<string>("");
  const [payrollMonthLabelInTransLang1, setPayrollMonthLabelInTransLang1] = useState<string>("");
  const [payrollDateLabelInJa1, setPayrollDateLabelInJa1] = useState<string>("");
  const [payrollDateLabelInTransLang1, setPayrollDateLabelInTransLang1] = useState<string>("");
  const [payrollCutoffDate2, setPayrollCutoffDate2] = useState<string>("");
  const [payrollCutoffDateLabelInJa2, setPayrollCutoffDateLabelInJa2] = useState<string>("");
  const [payrollCutoffDateLabelInTransLang2, setPayrollCutoffDateLabelInTransLang2] = useState<string>("");
  const [payrollDate2, setPayrollDate2] = useState<string>("");
  const [payrollMonthLabelInJa2, setPayrollMonthLabelInJa2] = useState<string>("");
  const [payrollMonthLabelInTransLang2, setPayrollMonthLabelInTransLang2] = useState<string>("");
  const [payrollDateLabelInJa2, setPayrollDateLabelInJa2] = useState<string>("");
  const [payrollDateLabelInTransLang2, setPayrollDateLabelInTransLang2] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [wageRaiseDetails, setWageRaiseDetails] = useState<string>("");
  const [wageRaiseDetailsTrans, setWageRaiseDetailsTrans] = useState<string>("");
  const [bonusDetails, setBonusDetails] = useState<string>("");
  const [bonusDetailsTrans, setBonusDetailsTrans] = useState<string>("");
  const [severancePaymentDetail, setSeverancePaymentDetail] = useState<string>("");
  const [severancePaymentDetailsTrans, setSeverancePaymentDetailsTrans] = useState<string>("");
  const [leaveAllowanceAmount, setLeaveAllowanceAmount] = useState<string>("");
  const [leaveAllowanceAmountTrans, setLeaveAllowanceAmountTrans] = useState<string>("");

  const BaseWageAmount = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValueField>{children}</InputValueField></Item5>;

   const OvertimeOver60Hours = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  const OvertimeOverNormalHours = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  const HolidayLegal = ({ children } : { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const HolidayOtherThanLegal = ({ children } : { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const Midnight = ({ children } : { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const PayrollCutoffDate1 = ({ children } : { children?: ReactNode }) =>
    payrollCutoffDate1 ? <Item10><InputValue>{children}</InputValue></Item10> : <></>;

  const PayrollCutoffDate2 = ({ children } : { children?: ReactNode }) =>
    payrollCutoffDate2 ? <Item10><InputValue>{children}</InputValue></Item10> : <></>;

  const PayrollDateLabel1 = ({ children } : { children?: ReactNode }) =>
    payrollDate1 ? <Item10><InputValue>{children}</InputValue></Item10> : <></>;

  const PayrollDateLabel2 = ({ children } : { children?: ReactNode }) =>
    payrollDate2 ? <Item10><InputValue>{children}</InputValue></Item10> : <></>;

  const WageRaiseDetails = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  const BonusDetail = ({ children } : { children?: ReactNode }) => {
    return (
      <Item5>
        {
          ec?.compensationBonus === true &&
          <InputValue>{children}</InputValue>
        }
      </Item5>
    )
  }

  const SeverancePaymentDetail = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  const LeaveAllowanceAmount = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;


  useEffect(() => {
    if (!ec)
      return;

    setBaseWagePaymentType(ec.baseWageType);
    setBaseWageAmount(ec.baseWageAmount);
    setBenefits(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.CompensationBenefits)
    );
    setBenefitsTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.CompensationBenefits)
    );
    setOvertimeWithin60hours(ec.compensationExtraWageRatesOvertimeAboveLegalLte_60Hs);
    setOvertimeOver60hours(ec.compensationExtraWageRatesOvertimeAboveLegalGt_60Hs);
    setOvertimeOverNormalHours(ec.compensationExtraWageRatesOvertimeAboveNormal);
    setHolidayLegal(ec.compensationExtraWageRatesHolidayLegal);
    setHolidayOtherThanLegal(ec.compensationExtraWageRatesHolidayOtherThanLegal);
    setMidnight(ec.compensationExtraWageRatesMidnight);
    setPayrollCutoffDate1(ec.compensationPayrollCutOffDate_1 ?? "");
    setPayrollCutoffDateLabelInJa1(tFixedToJa(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollCutOffDate_1)?.label || ''
    ));
    setPayrollCutoffDateLabelInTransLang1(tFixedToTransLang(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollCutOffDate_1)?.label || ''
    ));
    setPayrollCutoffDate2(ec.compensationPayrollCutOffDate_2 ?? "");
    setPayrollCutoffDateLabelInJa2(tFixedToJa(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollCutOffDate_2)?.label || ''
    ));
    setPayrollCutoffDateLabelInTransLang2(tFixedToTransLang(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollCutOffDate_2)?.label || ''
    ));
    setPayrollDate1(ec.compensationPayrollDate_1 ?? "");
    setPayrollMonthLabelInJa1(tFixedToJa(
      PAYROLL_MONTH_OPTIONS.find(i => i.value === ec.compensationPayrollMonth_1)?.label || defaultMonthOptionLabel
    ));
    setPayrollMonthLabelInTransLang1(tFixedToTransLang(
      PAYROLL_MONTH_OPTIONS.find(i => i.value === ec.compensationPayrollMonth_1)?.label || defaultMonthOptionLabel
    ));
    setPayrollDateLabelInJa1(tFixedToJa(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollDate_1)?.label || ''
    ));
    setPayrollDateLabelInTransLang1(tFixedToTransLang(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollDate_1)?.label || ''
    ));
    setPayrollDate2(ec.compensationPayrollDate_2 ?? "");
    setPayrollMonthLabelInJa2(tFixedToJa(
      PAYROLL_MONTH_OPTIONS.find(i => i.value === ec.compensationPayrollMonth_2)?.label || defaultMonthOptionLabel
    ));
    setPayrollMonthLabelInTransLang2(tFixedToTransLang(
      PAYROLL_MONTH_OPTIONS.find(i => i.value === ec.compensationPayrollMonth_2)?.label || defaultMonthOptionLabel
    ));
    setPayrollDateLabelInJa2(tFixedToJa(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollDate_2)?.label || ''
    ));
    setPayrollDateLabelInTransLang2(tFixedToTransLang(
      DAY_OF_MONTH_ITEMS.find(i => i.value === ec.compensationPayrollDate_2)?.label || ''
    ));
    setPaymentMethod(ec.compensationPaymentMethod);
    setWageRaiseDetails(ec.compensationWageRaiseTimeAmountEtc);
    setWageRaiseDetailsTrans(transHandler.getTranslation("compensationWageRaiseTimeAmountEtc"));


    //Bonus details are a combination of several values
    //e.g. "2回, 2月,6月, 3~5万円程度"
    
    const monthsInJa = ec.bonusMonths.map(bm => 
      tFixedToJa(`core.months.${bm.tkey}`)).join('、');

    //This might fail depending on the target language
    //TODO: Confirm if this works when we add more languages
    const monthsInTrans = ec.bonusMonths.map(bm => 
      tFixedToTransLang(`core.months.${bm.tkey}`)).join(', ');

    setBonusDetails([
      ec.compensationBonusNumberOfPaymentsPerYear 
        ? `${ec.compensationBonusNumberOfPaymentsPerYear}回`
        : '',
      monthsInJa,
      ec.compensationBonusAmountEtc,
    ].filter(v => v).join('、'));

    setBonusDetailsTrans([
      ec.compensationBonusNumberOfPaymentsPerYear 
        ? tFixedToTransLangWithPrefix(
            "bonus_times_year", 
            { 
              times: ec.compensationBonusNumberOfPaymentsPerYear,
            }
          )
        : '',
      monthsInTrans,
      transHandler.getTranslation("compensationBonusAmountEtc"),
    ].filter(v => v).join(', '));


    setSeverancePaymentDetail(ec.compensationSeverancePaymentTimeAmountEtc);
    setSeverancePaymentDetailsTrans(transHandler.getTranslation("compensationSeverancePaymentTimeAmountEtc"));
    setLeaveAllowanceAmount(ec.compensationLeaveAllowanceRate);
    setLeaveAllowanceAmountTrans(transHandler.getTranslation("compensationLeaveAllowanceRate"));
    
  }, [ec])

  return (
    <Section>
      <SectionHeadingContainer>
        <span>VII.</span> 
        <Segment>
          <span>賃金</span><br/>
          <span>{tFixed("wage")}</span>
        </Segment>
      </SectionHeadingContainer>
      <GroupContainer>
        <Indent>
          <ListBlocksContainer>
            <ListBlock>
              <ListBlockIndex>1.</ListBlockIndex>
              <ListBlockContent>
                <Segment>
                  <FlexRowColumn>
                    <Item10>基本賃金</Item10>
                    <LocalRowWithMargin>
                      <CheckMark
                        checked={baseWagePaymentType === EcWagePaymentType.Monthly}
                      >
                        月給
                      </CheckMark>
                      <span>
                        <Row>
                          <Item5>（</Item5>
                          <Item5>
                            <InputValueFieldInJa>
                              {
                                baseWagePaymentType === EcWagePaymentType.Monthly 
                                ? baseWageAmount?.toLocaleString()
                                : ''
                              }
                            </InputValueFieldInJa>
                          </Item5>
                          <Item5>円）</Item5>
                        </Row>
                      </span>
                    </LocalRowWithMargin>
                    <LocalRowWithMargin>
                      <CheckMark
                        checked={baseWagePaymentType === EcWagePaymentType.Daily}
                      >
                        日給
                      </CheckMark>
                      <span>
                        <Row>
                          <Item5>（</Item5>
                          <Item5>
                          <InputValueFieldInJa>
                              {
                                baseWagePaymentType === EcWagePaymentType.Daily 
                                ? baseWageAmount?.toLocaleString()
                                : ''
                              }
                            </InputValueFieldInJa>
                          </Item5>
                          <Item5>円）</Item5>
                        </Row>
                      </span>
                    </LocalRowWithMargin>
                    <LocalRowWithMargin>
                      <CheckMark
                        checked={baseWagePaymentType === EcWagePaymentType.Hourly}
                      >
                        時間給
                      </CheckMark>
                      <span>
                        <Row>
                          <Item5>（</Item5>
                          <Item5>
                          <InputValueFieldInJa>
                              {
                                baseWagePaymentType === EcWagePaymentType.Hourly 
                                ? baseWageAmount?.toLocaleString()
                                : ''
                              }
                            </InputValueFieldInJa>
                          </Item5>
                          <Item5>円）</Item5>
                        </Row>
                      </span>
                    </LocalRowWithMargin>
                  </FlexRowColumn>
                  <span>※詳細は別紙のとおり</span><br/>
                  <FlexRowColumn>
                    <Item10>{tFixed("basic_salary")}</Item10>
                    <LocalRowWithMargin>
                      <Item5>
                        <CheckMark
                          checked={baseWagePaymentType === EcWagePaymentType.Monthly}
                        >
                          {tFixed("basic_salary_monthly")}
                        </CheckMark>
                      </Item5>
                      <span>
                        <Row>
                          <Trans
                            i18nKey={getTKey("basic_salary_amount")}
                            tOptions={tOptions}
                            values={{
                              amount: baseWagePaymentType === EcWagePaymentType.Monthly
                                ? baseWageAmount?.toLocaleString()
                                : ''
                            }}
                            components={{
                              seg: <Item5 />,
                              amount: <BaseWageAmount />
                            }}
                          />
                        </Row>
                      </span> 
                    </LocalRowWithMargin>
                    <LocalRowWithMargin>
                      <Item5>
                        <CheckMark
                          checked={baseWagePaymentType === EcWagePaymentType.Daily}
                        >
                          {tFixed("basic_salary_daily")}
                        </CheckMark>
                      </Item5>
                      <span>
                        <Row>
                          <Trans
                            i18nKey={getTKey("basic_salary_amount")}
                            tOptions={tOptions}
                            values={{
                              amount: baseWagePaymentType === EcWagePaymentType.Daily
                                ? baseWageAmount?.toLocaleString()
                                : ''
                            }}
                            components={{
                              seg: <Item5 />,
                              amount: <BaseWageAmount />
                            }}
                          />
                        </Row>
                      </span>
                    </LocalRowWithMargin>
                    <LocalRowWithMargin>
                      <Item5>
                        <CheckMark
                          checked={baseWagePaymentType === EcWagePaymentType.Hourly}
                        >
                          <Item10>{tFixed("basic_salary_hourly")}</Item10>
                        </CheckMark>
                      </Item5>
                      <span>
                        <Row>
                          <Trans
                            i18nKey={getTKey("basic_salary_amount")}
                            tOptions={tOptions}
                            values={{
                              amount: baseWagePaymentType === EcWagePaymentType.Hourly
                                ? baseWageAmount?.toLocaleString()
                                : ''
                            }}
                            components={{
                              seg: <Item5 />,
                              amount: <BaseWageAmount />
                            }}
                          />
                        </Row>
                      </span>
                    </LocalRowWithMargin>
                  </FlexRowColumn>
                  <span>{tFixed("attached_details")}</span>
                </Segment>
              </ListBlockContent>
            </ListBlock>        
            <ListBlock>
              <ListBlockIndex>2.</ListBlockIndex>
              <ListBlockContent>
                <Column5>
                  <Segment>
                    <span>諸手当（時間外労働の割増賃金は除く）</span><br/>
                    <Row>
                      <Item5>（</Item5>
                      <Item5>
                        <InputValue>
                          {benefits}
                        </InputValue>
                      </Item5>
                      <Item5>）</Item5>
                    </Row>
                    <span>※詳細は別紙のとおり</span>
                  </Segment>
                  <Segment>
                    <span>{tFixed("allowances")}</span><br/>
                    <Row>
                      <Item5>(</Item5>
                      <Item5><InputValue>{benefitsTrans}</InputValue></Item5>
                      <Item5>)</Item5>
                    </Row>
                    <span>{tFixed("attached_details")}</span>
                  </Segment>
                </Column5>
              </ListBlockContent>
            </ListBlock>        
          </ListBlocksContainer>
        </Indent>
      </GroupContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>3.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <Segment>
                  <span>所定時間外、休日又は深夜労働に対して支払われる割増賃金率</span><br/>
                  <span>{tFixed("overtime_holiday_night_pay_rate")}</span>
                </Segment>
                <Grid5>
                  <span>(1)</span>
                  <Column5>
                    <HoursInfoContainer>
                      <span>所定時間外</span>
                      <div>
                        <Row>
                          <Item10>法定超月60時間以内</Item10>
                          <Item5>（</Item5>
                          <Item5><InputValue>{overtimeWithin60hours}</InputValue></Item5>
                          <Item5>）</Item5>
                          <Item5>％</Item5>
                        </Row>
                        <Row>
                          <Item10>法定超月60時間超</Item10>
                          <Item5>（</Item5>
                          <Item5><InputValue>{overtimeOver60hours}</InputValue></Item5>
                          <Item5>）</Item5>
                          <Item5>％</Item5>
                        </Row>
                        <Row>
                          <Item10>所定超</Item10>
                          <Item5>（</Item5>
                          <Item5><InputValue>{overtimeOverNormalHours}</InputValue></Item5>
                          <Item5>）</Item5>
                          <Item5>％</Item5>
                        </Row>
                      </div>
                    </HoursInfoContainer>
                    <HoursInfoContainer>
                      <span>{tFixed("overtime")}</span>
                      <div>
                        <Row>
                          <Trans
                            i18nKey={getTKey("within_legal_60hrs_overtime_rate")}
                            tOptions={tOptions}
                            values={{
                              rate: overtimeWithin60hours
                            }}
                            components={{
                              title: <Item10 />,
                              seg: <Item5 />,
                              rate: <OvertimeOver60Hours />
                            }}
                          />
                        </Row>
                        <Row>
                          <Trans
                            i18nKey={getTKey("beyond_legal_60hrs_overtime_rate")}
                            tOptions={tOptions}
                            values={{
                              rate: overtimeOver60hours
                            }}
                            components={{
                              title: <Item10 />,
                              seg: <Item5 />,
                              rate: <OvertimeOver60Hours />
                            }}
                          />
                        </Row>
                        <Row>
                          <Trans
                            i18nKey={getTKey("prescribed_overtime_rate")}
                            tOptions={tOptions}
                            values={{
                              rate: overtimeOverNormalHours
                            }}
                            components={{
                              title: <Item10 />,
                              seg: <Item5 />,
                              rate: <OvertimeOverNormalHours />
                            }}
                          />
                        </Row>
                      </div>
                    </HoursInfoContainer>
                  </Column5>
                </Grid5>

                <Grid5>
                  <span>(2)</span>
                  <div>
                    <FlexRowColumn>
                      <Item20>休日</Item20>
                      <Item20>
                        <Row>
                          <Item10>法定休日（</Item10>
                          <Item10><InputValue>{holidayLegal}</InputValue></Item10>
                          <Item10>）％</Item10>
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Item10>法定外休日（</Item10>
                          <Item10><InputValue>{holidayOtherThanLegal}</InputValue></Item10>
                          <Item10>）％</Item10>
                        </Row>
                      </Item20>
                    </FlexRowColumn>
                    <FlexRowColumn>
                      <Item20>{tFixed("holiday")}</Item20>
                      <Item20>
                        <Row>
                          <Trans
                            i18nKey={getTKey("legal_holiday_rate")}
                            tOptions={tOptions}
                            values={{
                              rate: holidayLegal
                            }}
                            components={{
                              seg: <Item10 />,
                              rate: <HolidayLegal />
                            }}
                          />
                        </Row>
                      </Item20>
                      <Item20>
                        <Row>
                          <Trans
                            i18nKey={getTKey("non_legal_holiday_rate")}
                            tOptions={tOptions}
                            values={{
                              rate: holidayOtherThanLegal
                            }}
                            components={{
                              seg: <Item10 />,
                              rate: <HolidayOtherThanLegal />
                            }}
                          />
                        </Row>
                      </Item20>
                    </FlexRowColumn>
                  </div>
                </Grid5>
                <Grid5>
                  <span>(3)</span>
                  <div>
                    <Row>
                      <Item10>深夜（</Item10>
                      <Item10><InputValue>{midnight}</InputValue></Item10>
                      <Item10>）％</Item10>
                    </Row>
                    <Row>
                      <Trans
                        i18nKey={getTKey("midnight_rate")}
                        tOptions={tOptions}
                        values={{
                          rate: midnight
                        }}
                        components={{
                          seg: <Item10 />,
                          rate: <Midnight />
                        }}
                      />
                    </Row>
                  </div>
                </Grid5>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>4.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>賃金締切日</Item20>

                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollCutoffDate1)}
                    >
                      <Item5>毎月</Item5>
                      <Item10>（</Item10>
                      { payrollCutoffDate1 &&
                        <Item10>
                          <InputValue>
                            {payrollCutoffDateLabelInJa1}
                          </InputValue>
                        </Item10>
                      }
                      <Item10>）</Item10>
                    </CheckMark>
                  </Item20>

                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollCutoffDate2)}
                    >
                      <Item5>毎月</Item5>
                      <Item10>（</Item10>
                      { payrollCutoffDate2 &&
                        <Item10>
                          <InputValue>
                            {payrollCutoffDateLabelInJa2}
                          </InputValue>
                        </Item10>
                      }
                      <Item10>）</Item10>
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>

                <FlexRowColumn>
                  <Item20>{tFixed("wage_cutoff_date")}</Item20>
                  
                  <Item20>
                    <CheckMark 
                      checked={!isEmpty(payrollCutoffDate1)}
                    >
                      <Trans
                        i18nKey={getTKey("wage_cutoff_date_detail")}
                        tOptions={tOptions}
                        values={{
                          date: payrollCutoffDateLabelInTransLang1
                        }}
                        components={{
                          seg: <Item10 />,
                          date: <PayrollCutoffDate1 />
                        }}
                      />
                    </CheckMark>
                  </Item20>

                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollCutoffDate2)}
                    >
                      <Trans
                        i18nKey={getTKey("wage_cutoff_date_detail")}
                        tOptions={tOptions}
                        values={{
                          date: payrollCutoffDateLabelInTransLang2
                        }}
                        components={{
                          seg: <Item10 />,
                          date: <PayrollCutoffDate2 />
                        }}
                      />
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>5.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>賃金支払日</Item20>
                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollDate1)}
                    >
                      <Item5>{payrollMonthLabelInJa1}</Item5>
                      <Item10>（</Item10>
                      { payrollDate1 &&
                        <Item10>
                          <InputValue>
                            {payrollDateLabelInJa1}
                          </InputValue>
                        </Item10>
                      }
                      <Item10>）</Item10>
                    </CheckMark>
                  </Item20>
                  
                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollDate2)}
                    >
                      <Item5>{payrollMonthLabelInJa2}</Item5>
                      <Item10>（</Item10>
                      { payrollDate2 &&
                        <Item10>
                          <InputValue>
                            {payrollDateLabelInJa2}
                          </InputValue>
                        </Item10>
                      }
                      <Item10>）</Item10>
                    </CheckMark>
                  </Item20>

                </FlexRowColumn>

                <FlexRowColumn>
                  <Item20>{tFixed("wage_payment_date")}</Item20>

                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollDate1)}
                    >
                      <Trans
                        i18nKey={getTKey("wage_payment_date_detail")}
                        tOptions={tOptions}
                        values={{
                          date: payrollDateLabelInTransLang1,
                          month: payrollMonthLabelInTransLang1
                        }}
                        components={{
                          seg: <Item10 />,
                          date: <PayrollDateLabel1 />,
                        }}
                      />
                    </CheckMark>
                  </Item20>

                  <Item20>
                    <CheckMark
                      checked={!isEmpty(payrollDate2)}
                    >
                      <Trans
                        i18nKey={getTKey("wage_payment_date_detail")}
                        tOptions={tOptions}
                        values={{
                          date: payrollDateLabelInTransLang2,
                          month: payrollMonthLabelInTransLang2
                        }}
                        components={{
                          seg: <Item10 />,
                          date: <PayrollDateLabel2 />
                        }}
                      />
                    </CheckMark>
                  </Item20>

                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>6.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>賃金支払方法</Item20>
                  <Item20>
                    <CheckMark
                      checked={paymentMethod === EcPaymentMethod.BankTransfer}
                    >
                      口座振込
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={paymentMethod === EcPaymentMethod.Cash}
                    >
                      通貨払い
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("wage_payment_method")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={paymentMethod === EcPaymentMethod.BankTransfer}
                    >
                      {tFixed("bank_transfer")}
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={paymentMethod === EcPaymentMethod.Cash}
                    >
                      {tFixed("cash_payment")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>7.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>労使協定に基づく賃金支払時の控除</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationDeductions === true}
                    >
                      有
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationDeductions === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <span>※詳細は別紙のとおり</span>
                <FlexRowColumn>
                  <Item20>{tFixed("wage_deduction_based_on_labor_agreement")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationDeductions === true}
                    >
                      {tFixed("yes")}
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationDeductions === false}
                    >
                      {tFixed("no")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <span>{tFixed("attached_details")}</span>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>8.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>昇給</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationWageRaise === true}
                    >
                      <Row>
                        <Item5>有（</Item5>
                        <Item5><InputValue>{wageRaiseDetails}</InputValue></Item5>
                        <Item5>）</Item5>
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationWageRaise === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("wage_raise")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationWageRaise === true}
                    >
                      <Row>
                        <Trans
                          i18nKey={getTKey("wage_raise_detail")}
                          tOptions={tOptions}
                          values={{
                            detail: wageRaiseDetailsTrans
                          }}
                          components={{
                            seg: <Item5 />,
                            detail: <WageRaiseDetails />
                          }}
                        />
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationWageRaise === false}
                    >
                      {tFixed("no")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>9.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>賞与</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationBonus === true}
                    >
                      <Row>
                        <Item5>有（</Item5>
                        <Item5>
                          { ec?.compensationBonus === true &&
                            <InputValue>{bonusDetails}</InputValue>
                          }
                        </Item5>
                        <Item5>）</Item5>
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationBonus === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("bonus")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationBonus === true}
                    >
                      <Row>
                        <Trans
                          i18nKey={getTKey("bonus_detail")}
                          tOptions={tOptions}
                          values={{
                            detail: bonusDetailsTrans
                          }}
                          components={{
                            seg: <Item5 />,
                            detail: <BonusDetail />
                          }}
                        />
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationBonus === false}
                    >
                      {tFixed("no")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>10.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>退職金</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationSeverancePayment === true}
                    >
                      <Row>
                        <Item5>有（</Item5>
                        <Item5><InputValue>{severancePaymentDetail}</InputValue></Item5>
                        <Item5>）</Item5>
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationSeverancePayment === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("severance_benefits")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationSeverancePayment === true}
                    >
                      <Row>
                        <Trans
                          i18nKey={getTKey("severance_benefits_detail")}
                          tOptions={tOptions}
                          values={{
                            detail: severancePaymentDetailsTrans
                          }}
                          components={{
                            seg: <Item5 />,
                            detail: <SeverancePaymentDetail />
                          }}
                        />
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationSeverancePayment === false}
                    >
                      {tFixed("no")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>11.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item20>休業手当</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationLeaveAllowance === true}
                    >
                      <Row>
                        <Item5>有（</Item5>
                        <Item5><InputValue>{leaveAllowanceAmount}</InputValue></Item5>
                        <Item5>）</Item5>
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationLeaveAllowance === false}
                    >
                      無
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>{tFixed("leave_allowance")}</Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationLeaveAllowance === true}
                    >
                      <Row>
                        <Trans
                          i18nKey={getTKey("leave_allowance_detail")}
                          tOptions={tOptions}
                          values={{
                            detail: leaveAllowanceAmountTrans
                          }}
                          components={{
                            seg: <Item5 />,
                            detail: <LeaveAllowanceAmount />
                          }}
                        />
                      </Row>
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={ec?.compensationLeaveAllowance === false}
                    >
                      {tFixed("no")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
        </ListBlocksContainer>
      </Indent>
    </Section>
  )
}

export default CompensationSection;