//These prefectures are sorted based on the locations (i.e. from north to south).
//This order is very common in Japan.
//The values are same as ones stored in PostalCodeAddress table in the database
export const PREFECTURES_JA = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export const PREFECTURES_EN = [
  "HOKKAIDO",
  "AOMORI KEN",
  "IWATE KEN",
  "MIYAGI KEN",
  "AKITA KEN",
  "YAMAGATA KEN",
  "FUKUSHIMA KEN",
  "IBARAKI KEN",
  "TOCHIGI KEN",
  "GUMMA KEN",
  "SAITAMA KEN",
  "CHIBA KEN",
  "TOKYO TO",
  "KANAGAWA KEN",
  "NIIGATA KEN",
  "TOYAMA KEN",
  "ISHIKAWA KEN",
  "FUKUI KEN",
  "YAMANASHI KEN",
  "NAGANO KEN",
  "GIFU KEN",
  "SHIZUOKA KEN",
  "AICHI KEN",
  "MIE KEN",
  "SHIGA KEN",
  "KYOTO FU",
  "OSAKA FU",
  "HYOGO KEN",
  "NARA KEN",
  "WAKAYAMA KEN",
  "TOTTORI KEN",
  "SHIMANE KEN",
  "OKAYAMA KEN",
  "HIROSHIMA KEN",
  "YAMAGUCHI KEN",
  "TOKUSHIMA KEN",
  "KAGAWA KEN",
  "EHIME KEN",
  "KOCHI KEN",
  "FUKUOKA KEN",
  "SAGA KEN",
  "NAGASAKI KEN",
  "KUMAMOTO KEN",
  "OITA KEN",
  "MIYAZAKI KEN",
  "KAGOSHIMA KEN",
  "OKINAWA KEN",
];
