import { TranslationThroughAPI } from "../types/api";
import { apiUtil } from "../utils";

export const getTranslation = async (value: string) => {
  const response = await apiUtil.get(
    `/translations?text=${value}`,    
  );

  if (response.status >= 200 && response.status < 300) {
      return response.data as TranslationThroughAPI;
  }
  throw new Error('API error');
}
