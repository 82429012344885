import { FunctionComponent } from "react";
import {
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  LabelCell,
  LocalMultiTextInputMiddle,
  LocalMultiTextInputSmall,
  LocalPostCodeInput,
  LocalSelectExtraSmall,
  LocalSelectSmall,
  LocalTextInput,
  LocalTextInputGrow,
  RadioButtonsWrapper,
  Row5,
  TransValFieldGroup,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Text from "../../../../core/components/Text";
import { isEmpty } from "../../../../core/utils";
import { EmployerOrgSettingsSectionProps } from "../type";
import styled from "styled-components";
import Select from "../../../components/Select";
import { DAY_OF_MONTH_ITEMS } from "../../../../job/constants/options";
import { useTranslation } from "react-i18next";
import Radio from "../../../components/Radio";
import { PAYROLL_MONTH_OPTIONS } from "../../../options/payment";

interface CompanyInfoSectionProps extends EmployerOrgSettingsSectionProps {}

const CompanyInfoSection: FunctionComponent<CompanyInfoSectionProps> = ({
  company,
  employer,
  onChangeCompany,
  onChangeEmployer,
}) => {
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });  

  return (
    <>
      <GridRowsContainer>
        {/* 会社/機関名） */}
        <GridRow>
          <LabelCell>
            <Label required>会社/機関名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalMultiTextInputSmall
                readOnly
                disabled
                placeholder="日本工業株式会社"
                value={company?.jaName ?? ""}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputSmall 
                placeholder="Nippon Kohgyo Co, Ltd." 
                value={company?.enName ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enName: val })}
                invalid={isEmpty(company?.enName)}  
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <Label required>郵便番号</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalPostCodeInput
                suppressErrorMessage
                value={company?.postalCode ?? ""}
                onTextChange={(val) => onChangeCompany?.({ postalCode: val})}
                onPostalCodeAddressIdChange={(val) => onChangeCompany?.({ postalCodeAddressId: val })}
                onPrefectureChange={(val) => onChangeCompany?.({ 
                  jaPrefecture: val.jaPrefecture,
                  enPrefecture: val.enPrefecture
                })}
                onCityChange={(val) => onChangeCompany?.({
                  jaCityWard: val.jaCity,
                  enCityWard: val.enCity
                })}
                onTownChange={(val) => onChangeCompany?.({
                  jaTown: val.jaTown,
                  enTown: val.enTown
                })}
                error={isEmpty(company?.postalCode)}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <Label required>都道府県</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="東京都"
                value={company?.jaPrefecture ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaPrefecture: val })}
                error={isEmpty(company?.jaPrefecture)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Tokyo"
                value={company?.enPrefecture ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enPrefecture: val })}
                error={isEmpty(company?.enPrefecture)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <Label required>市区</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="港区"
                value={company?.jaCityWard ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaCityWard: val })}
                error={isEmpty(company?.jaCityWard)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Minato-ku"
                value={company?.enCityWard ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enCityWard: val })}
                error={isEmpty(company?.enCityWard)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <Label required>町村</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="海岸"
                value={company?.jaTown ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaTown: val })}
                error={isEmpty(company?.jaTown)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Kaigan"
                value={company?.enTown ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enTown: val })}
                error={isEmpty(company?.enTown)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <Label required>番地</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="１丁目９−１８"
                value={company?.jaAddressNumber ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaAddressNumber: val })}
                error={isEmpty(company?.jaAddressNumber)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="1-9-18"
                value={company?.enAddressNumber ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enAddressNumber: val })}
                error={isEmpty(company?.enAddressNumber)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <Label>建物名等</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={company?.jaBuildingName ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaBuildingName: val})}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={company?.enBuildingName ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enBuildingName: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <Label required>電話番号</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="03-1234-5678"
              value={company?.phoneNumber ?? ""}
              onTextChange={(val) => onChangeCompany?.({ phoneNumber: val })}
              error={isEmpty(company?.phoneNumber)}
            />
          </FieldCell>
        </GridRow>

        {/* 代表者名 */}
        <GridRow>
          <LabelCell>
            <Label>代表者名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="特定太郎"
                value={company?.jaRepresentativeName ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaRepresentativeName: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Taro Tokutei"
                value={company?.enRepresentativeName ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enRepresentativeName: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 代表者役職 */}
        <GridRow>
          <LabelCell>
            <Label>代表者役職</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="代表取締役"
                value={company?.jaRepresentativeTitle ?? ""}
                onTextChange={(val) => onChangeCompany?.({ jaRepresentativeTitle: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="CEO"
                value={company?.enRepresentativeTitle ?? ""}
                onTextChange={(val) => onChangeCompany?.({ enRepresentativeTitle: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 締切日/支払日1 */}
        <GridRow>
          <LabelCell>
            <Label>締切日/支払日1</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <Text>締切日</Text>
              <LocalSelectExtraSmall 
                value={employer?.compensationPayrollCutOffDate_1}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollCutOffDate_1: val || null })}
                options={DAY_OF_MONTH_ITEMS}
              />
              <Text>日</Text>
              <Text>/</Text>
              <Text>支払日</Text>
              <LocalSelectSmall
                value={employer?.compensationPayrollMonth_1}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollMonth_1: val || null })}
                options={PAYROLL_MONTH_OPTIONS}
              />
              <LocalSelectExtraSmall 
                value={employer?.compensationPayrollDate_1}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollDate_1: val || null })}
                options={DAY_OF_MONTH_ITEMS}
              />
              <Text>日</Text>
            </Row5>  
          </FieldCell>
        </GridRow>

        {/* 締切日/支払日2 */}
        <GridRow>
          <LabelCell>
            <Label>締切日/支払日2</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <Text>締切日</Text>
              <LocalSelectExtraSmall 
                value={employer?.compensationPayrollCutOffDate_2}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollCutOffDate_2: val || null })}
                options={DAY_OF_MONTH_ITEMS}
              />
              <Text>日</Text>
              <Text>/</Text>
              <Text>支払日</Text>
              <LocalSelectSmall
                value={employer?.compensationPayrollMonth_2}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollMonth_2: val || null })}
                options={PAYROLL_MONTH_OPTIONS}
              />
              <LocalSelectExtraSmall 
                value={employer?.compensationPayrollDate_2}
                placeholder={tCore('select')}
                onValueChange={(val) => onChangeEmployer?.({ compensationPayrollDate_2: val || null })}
                options={DAY_OF_MONTH_ITEMS}
              />
              <Text>日</Text>
            </Row5>  
          </FieldCell>
        </GridRow>

        {/* 賞与 */}
        <GridRow>
          <LabelCell>
            <Label>賞与</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={employer?.bonus === true}
                onValueChange={() => onChangeEmployer?.({ bonus: true })}
              >
                あり
              </Radio>
              <Radio
                checked={employer?.bonus === false}
                onValueChange={() => onChangeEmployer?.({ bonus: false })}
              >
                なし
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default CompanyInfoSection;
