import styled from "styled-components";
import Text from "../../../core/components/Text";
import TextInput from "../../../core/components/TextInput";
import Row from "../../../core/components/Row";
import Column from "../../../core/components/Column";
import { MOBILE_DEVICE } from "../../../core/constants/styles";
import MultiTextInput from "../../../core/components/MultiTextInput";
import Button from "../../../core/components/Button";

export const FormSection = styled.section`
    width: 100%;
`;

export const SectionHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 230px) 1fr;
    min-height: 26px;
`;

export const FormRow = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 230px) 1fr;
    min-height: 26px;

    &:nth-child(even) {
        background-color: #F6F6F6;
    }
`;

export const TallerFormRow = styled(FormRow)`
    padding-bottom: 26px;
`;

export const LabelContainer = styled(Column)`
    width: 100%;
    padding: 10px 0 10px 30px;
    justify-content: center;
`;

export const NoteInLabel = styled(Text)`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
`;

export const NoteOnLabel = styled(Text)`
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #999999;
`;

export const Description = styled(Text)`
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #444444;
`;

export const NoteOnSection = styled(Text)`
    font-size: 11px;
    color: #999999;
    line-height: normal;
`;

export const ItemLabel = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
`;

export const EmphasizedItemLabel = styled(ItemLabel)`
    font-weight: 500;
`;

export const InputContainer = styled(Row)`
  width: 100%;
  padding: 8px 30px 8px 20px;  
  align-items: center;
  gap: 5px;
`;

export const TextContainer = styled.div`
    padding: 8px 30px 8px 20px;
`;

export const ItemTextInput = styled(TextInput)`
    width: 100%;
    padding-left: 10px;
`;

export const ItemTextInputCompact = styled(ItemTextInput)`
    max-width: 180px;
`;

export const ItemTextArea = styled(MultiTextInput)`
    width: 100%;
    min-height: auto;
    height: 30px;
`;

export const SectionHeading = styled.h3`
    font-size: 16px;
    font-weight: 500;
    min-height: 58px;
    line-height: 23px;
    padding: 30px 0 5px 30px;
    margin: 0;
`;

export const SectionHeadingLabel = styled(SectionHeading)`
    padding: 0;
    margin-top: 30px;
    min-height: auto;
`;

export const SectionSubHeading = styled(Text)`
    font-size: 14px;
    font-weight: 500;
    min-height: 48px;
    line-height: 20px;
    padding: 5px 0 5px 30px;
    margin: 0;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;;
  gap: 20px;

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TextAndInputContainer = styled(InputContainer)`
    font-size: 14px;
    flex-wrap: wrap;
    word-break: break-word;
`;

export const SectionNoteContainer = styled(InputContainer)`
    margin-top: 30px;
`;

export const ItemsGap5 = styled.div`
    display: flex;
    gap: 5px;
`;

export const TransActionButton =  styled(Button).attrs({ variant: 'secondary' })`
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    border-radius: 5px;
    border: 1px solid #ACACAC;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 116px;
    width: auto;
`;

export const ContentAndButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
`;

export const OrgInfoReflectButton = styled(Button).attrs({ variant: 'secondary' })`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #ACACAC;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
`;