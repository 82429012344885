import { SelectOption } from "../components/Select";
import { PayrollMonth } from "../enums/payment";

export const PAYROLL_MONTH_OPTIONS: SelectOption [] = [
  {
    label: '',
    value: ''
  },
  {
    label: 'core.month_options.current_month',
    value: PayrollMonth.Current
  },
  {
    label: 'core.month_options.next_month',
    value: PayrollMonth.Next
  },
  {
    label: 'core.month_options.month_after_next',
    value: PayrollMonth.AfterNext
  },
]