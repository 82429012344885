import styled, { css } from "styled-components";
import Text from "../../../core/components/Text";
import TextInput from "../../../core/components/TextInput";
import Select from "../../../core/components/Select";
import PostalCodeInput from "../../../core/components/PostalCodeInput";
import NumberField from "../../../core/components/NumberField";
import MultiTextInput from "../../../core/components/MultiTextInput";
import CurrencyField from "../../../core/components/CurrencyField";
import MultiSelect from "../../../core/components/MultiSelect";
import Button from "../../../core/components/Button";

export const HeadingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  padding: 30px;
  border-bottom: 1px solid #C2C2C2;
  align-items: center;
`;

const Row = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Column = css`
  display: flex;
  flex-direction: column;
`;

const SelectStyle = css`
  padding: 0 10px;
  flex-grow: 0;
  border-radius: 4px;
`;

export const Row5 = styled.div`
  ${Row}
  gap: 5px;
`;

export const Row10 = styled.div`
  ${Row}
  gap: 10px;
`;

export const Row20 = styled.div`
  ${Row}
  gap: 20px;
`;

export const Column5 = styled.div`
  ${Column}
  gap: 5px;
`;

export const Column10 = styled.div`
  ${Column}
  gap: 10px;
`;

export const Column20 = styled.div`
  ${Column}
  gap: 20px;
`;

export const SectionHeadingLarge = styled.h3`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SectionHeading = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  align-items: center;
`;

export const GridRowsContainer = styled.div`
`;

export const GridRow = styled(SectionGrid)`
  min-height: 56px;

  &:not(:last-child) {
    border-bottom: 1px solid #C2C2C2;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const GridRowBorderless = styled(SectionGrid)`
  min-height: 56px;

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const LabelCell = styled.div`
  padding: 10px 0 10px 30px;
  word-break: break-all;
`;

export const FieldCell = styled.div`
  padding: 10px 20px;
`;

export const FullRowCell = styled.div`
  grid-column: 1 / -1; 
  padding: 10px 20px 10px 30px;
`;

export const NoteInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InputLetterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Note = styled(Text)`
  font-size: 12px;
  color: #626161;
`;

export const WarningNote = styled(Text)`
  font-size: 13px;
  color: #E93232;
`;

export const UnitText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LocalTextInput = styled(TextInput)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalTextInputSmall = styled(TextInput)`
  width: 80px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalTextInputGrow = styled(TextInput)`
  padding: 0 10px;
  flex-grow: 1;
`;

export const LocalTextInputExtend = styled(TextInput)`
  width: 100%;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalNumberField = styled(NumberField)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalNumberFieldSmall = styled(NumberField)`
  width: 50px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalNumberFieldExtraSmall = styled(NumberField)`
  width: 36px;
  padding: 0 5px;
  text-align: center;
  flex-grow: 0;
  &::placeholder {
    text-align: center;
  }
`;

export const CurrencyInput = styled(CurrencyField)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
  border-radius: 4px;
  `;

export const CurrencyInputSmall = styled(CurrencyField)`
  width: 120px;
  padding: 0 10px;
  flex-grow: 0;
  border-radius: 4px;
`;

export const DayInput = styled(NumberField)`
  width: 32px;
  flex-grow: 0;
  border-radius: 4px;
`;

export const LocalSelect = styled(Select)`
  width: 180px;
  ${SelectStyle}
`;

export const LocalSelectExtraSmall = styled(Select)`
  width: 80px;
  ${SelectStyle}
`;

export const LocalSelectSmall = styled(Select)`
  width: 100px;
  ${SelectStyle}
`;

export const LocalSelectExtend = styled(Select)`
  width: 100%;
  ${SelectStyle}
`;

export const LocalMultiSelect = styled(MultiSelect)`
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
  border-radius: 4px;
`;

export const LocalPostalCodeInput = styled(PostalCodeInput)`  
  width: 180px;
  padding: 0 10px;
  flex-grow: 0;
`;

export const LocalMultiTextInput = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
`;

export const LocalMultiTextInputSmall = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
  height: 40px;
`;

export const LocalMultiTextInputMiddle = styled(MultiTextInput)`
  width: 100%;
  resize: vertical;
  height: 60px;
`;

export const DatePickerWrapper = styled.div`
  //This width adjusts the location of the date picker triangle
  width: 60px;
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TransValFieldGroup = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto 1fr;
  gap: 10px;
`;

export const TransValFieldGroupStartAligned = styled(TransValFieldGroup)`
  align-items: start;
`;

export const PostalCodeAndNoteWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
`;

export const ContentAndButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 40px;
`;

export const OrgInfoReflectButton = styled(Button).attrs({ variant: 'secondary' })`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #ACACAC;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
`;