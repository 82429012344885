export enum EditableTranslationKind {
  //業務区分
  JobClassification = 'job_classification',

  //業務区分 (※雇入後に変更がある場合)
  JobClassificationAfterEmployment = 'job_classification_after_employment',

  //労働時間の備考
  WorkHoursNote = 'work_hours_note',

  //その他の定例休日
  HolidaysOtherFixedHolidays = 'holidays_other_fixed_holidays',

  //その他の非定例休日
  HolidaysOtherIrregularHolidays = 'holidays_other_irregular_holidays',

  //休日の備考
  HolidaysNote = 'holidays_note',

  //その他の休暇（有給）
  LeavesOtherPaidLeaves = 'leaves_other_paid_leaves',

  //その他の休暇（無休）
  LeavesOtherUnpaidLeaves = 'leaves_other_unpaid_leaves',

  //休暇の備考
  LeavesNote = 'leaves_note',

  //諸手当（時間外労働の割増賃金を除く）
  CompensationBenefits = 'compensation_benefits',

  //退職の備考
  ResignationNote = 'resignation_note',

  //社会保険等の加入状況（その他）
  OtherSocialInsuranceCoverageOtherDetail = 'other_social_insurance_coverage_other_detail',

  //就業規則確認
  OtherWorkRegulationsCheckMethod = 'other_work_regulations_check_method',

  //手当1
  BenefitsBenefit1Name = 'benefits_benefit_1_name',

  //手当1の計算方法
  BenefitsBenefit1CalculationMethod = 'benefits_benefit_1_calculation_method',

  //手当2
  BenefitsBenefit2Name = 'benefits_benefit_2_name',

  //手当2の計算方法
  BenefitsBenefit2CalculationMethod = 'benefits_benefit_2_calculation_method',

  //手当3
  BenefitsBenefit3Name = 'benefits_benefit_3_name',

  //手当3の計算方法
  BenefitsBenefit3CalculationMethod = 'benefits_benefit_3_calculation_method',

  //手当4
  BenefitsBenefit4Name = 'benefits_benefit_4_name',

  //手当4の計算方法
  BenefitsBenefit4CalculationMethod = 'benefits_benefit_4_calculation_method',

  //固定残業代
  BenefitsFixedOvertimePayName = 'benefits_fixed_overtime_pay_name',

  //その他控除1
  DeductionsOtherDeductions1Name = 'deductions_other_deductions_1_name',

  //その他控除2
  DeductionsOtherDeductions2Name = 'deductions_other_deductions_2_name',

  //その他控除3
  DeductionsOtherDeductions3Name = 'deductions_other_deductions_3_name',

  //その他控除4
  DeductionsOtherDeductions4Name = 'deductions_other_deductions_4_name',
}