import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CategoryContainer, Column5,
  CustomLabel, FieldCell, GridRow, GridRowsContainer,
  LabelCell, LocalSelect, LocalTextInputExtend,
  SectionHeadingLarge
} from "../commonStyle";
import {SUPPORTED_LANGUAGE_OPTIONS} from "../../../../core/options/language";
import {getLanguageCodeFromId, getLanguageIdFromCode, isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";

interface LanguageCategoryProps extends SupportPlanFormProps {
  categoryName: string;
  languageIdPropertyName: SupportPlanExtractType<number>;
  languageOtherLastNamePropertyName: SupportPlanExtractType<string | null>;
  languageOtherFirstNamePropertyName: SupportPlanExtractType<string | null>;
  languageOtherAffiliationPropertyName: SupportPlanExtractType<string | null>;
}

const LanguageCategory: FunctionComponent<LanguageCategoryProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    categoryName,
    languageIdPropertyName,
    languageOtherLastNamePropertyName,
    languageOtherFirstNamePropertyName,
    languageOtherAffiliationPropertyName,
}) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>{categoryName}</SectionHeadingLarge>
      </CategoryContainer>

      <GridRowsContainer>
        {/* 実施言語 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>実施言語</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalSelect
                placeholder="選択"
                options={SUPPORTED_LANGUAGE_OPTIONS}
                value={getLanguageCodeFromId(supportPlan?.[languageIdPropertyName]) ?? ""}
                onValueChange={val => onChangeSupportPlan?.({[languageIdPropertyName]: getLanguageIdFromCode(val) })}
                error={isEmpty(supportPlan?.[languageIdPropertyName])}
              />
              {isEmpty(supportPlan?.[languageIdPropertyName]) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援担当者以外の通訳者の名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援担当者以外の通訳者の名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="山田"
                value={supportPlan?.[languageOtherLastNamePropertyName] ?? ""}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({[languageOtherLastNamePropertyName]: val});
                }}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援担当者以外の通訳者の名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援担当者以外の通訳者の名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="太郎"
                value={supportPlan?.[languageOtherFirstNamePropertyName] ?? ""}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({[languageOtherFirstNamePropertyName]: val});
                }}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援担当者以外の通訳者の所属名 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援担当者以外の通訳者の所属名</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="所属名"
                value={supportPlan?.[languageOtherAffiliationPropertyName] ?? ""}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({[languageOtherAffiliationPropertyName]: val});
                }}
              />
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default LanguageCategory;