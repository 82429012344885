import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { FlexRowColumn, InputValue, Item10, Item5, Row, Segment } from "../../commonStyles";
import { BlockNoteContainer, Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import styled from "styled-components";
import { MOBILE_DEVICE } from "../../../../../core/constants/styles";
import CheckMark from "../../components/CheckMark";
import { TemplateSectionProps } from "../../types";
import { Trans, useTranslation } from "react-i18next";
import { isEmpty } from "../../../../../core/utils";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface VacationSectionProps extends TemplateSectionProps {
}

const AnnualPaidLeaveContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;

  @media ${MOBILE_DEVICE} {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;

const VacationSection: FunctionComponent<VacationSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToTransLangWithPrefix = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLangWithPrefix(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;

  const [annualPaidLeavesAfter6monthsDays, setAnnualPaidLeavesAfter6monthsDays] = useState<number | null>();
  const [annualPaidLeavesBefore6monthsMonths, setAnnualPaidLeavesBefore6monthsMonths] = useState<string>();
  const [annualPaidLeavesBefore6monthsDays, setAnnualPaidLeavesBefore6monthsDays] = useState<string>();
  const [otherLeavesPaid, setOtherLeavesPaid] = useState<string>("");
  const [otherLeavesPaidTrans, setOtherLeavesPaidTrans] = useState<string>("");
  const [otherLeavesUnpaid, setOtherLeavesUnpaid] = useState<string>("");
  const [otherLeavesUnpaidTrans, setOtherLeavesUnpaidTrans] = useState<string>("");
  const [notesOnVacation, setNotesOnVacation] = useState<string>("");
  const [notesOnVacationTrans, setNotesOnVacationTrans] = useState<string>("");

  const AnnualPaidLeaveAfter6Months = ({ children } : { children?: ReactNode }) => 
    isEmpty(children) ? <></> : <Item5><InputValue>{children}</InputValue></Item5>;

  const AnnualPaidLeaveBefore6MonthsMonths = ({ children } : { children?: ReactNode }) => 
    <Item5><InputValue>{children}</InputValue></Item5>

  const AnnualPaidLeaveBefore6MonthsDays = ({ children } : { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>

  const OtherLeavesPaid = ({ children } : { children?: ReactNode }) =>
    <InputValue>{children}</InputValue>;
  
  const OtherLeavesUnpaid = ({ children } : { children?: ReactNode }) =>
    <InputValue>{children}</InputValue>;

  useEffect(() => {
    if (!ec) 
      return;

    setAnnualPaidLeavesAfter6monthsDays(ec.leavesAnnualPaidLeavesAfter_6MonthsDays);


    setAnnualPaidLeavesBefore6monthsMonths(
      ec?.leavesAnnualPaidLeavesBefore_6Months === true
        ? ec?.leavesAnnualPaidLeavesBefore_6MonthsMonths?.toString() ?? ""
        : ""
    );
    setAnnualPaidLeavesBefore6monthsDays(
      ec?.leavesAnnualPaidLeavesBefore_6Months === true
      ? ec?.leavesAnnualPaidLeavesBefore_6MonthsDays?.toString() ?? ""
      : ""
    );
    setOtherLeavesPaid(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.LeavesOtherPaidLeaves)
    );
    setOtherLeavesPaidTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.LeavesOtherPaidLeaves)
    );
    setOtherLeavesUnpaid(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.LeavesOtherUnpaidLeaves)
    );
    setOtherLeavesUnpaidTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.LeavesOtherUnpaidLeaves)
    );
    setNotesOnVacation(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.LeavesNote)
    );
    setNotesOnVacationTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.LeavesNote)
    );

  }, [ec]);

  return (
    <Section>
      <SectionHeadingContainer>
        <span>VI.</span> 
        <Segment>
          <span>休暇</span><br/>
          <span>{tFixed("vacation")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <AnnualPaidLeaveContainer>
                  <span>年次有給休暇</span>
                  <div>
                    <span>6ヶ月継続勤務した場合
                      <span>
                        <Item5>→</Item5> 
                        <Item5><InputValue>{annualPaidLeavesAfter6monthsDays}</InputValue></Item5>
                        <Item5>日</Item5>
                      </span>
                    </span><br/>
                    <span>
                      <Item5>継続勤務６か月未満の年次有給休暇</Item5>
                      <Item5>（</Item5>
                      <Item5>
                        <Item5>
                          <CheckMark
                            checked={ec?.leavesAnnualPaidLeavesBefore_6Months === true}
                          >
                            有
                          </CheckMark>
                        </Item5>     
                        <Item5>
                          <CheckMark
                            checked={ec?.leavesAnnualPaidLeavesBefore_6Months === false}
                          >
                            無
                          </CheckMark>
                        </Item5>
                      </Item5>
                      <Item5>）→</Item5>
                      
                      <Item5><InputValue>{annualPaidLeavesBefore6monthsMonths}</InputValue></Item5>
                      <Item5>ヶ月経過で</Item5>
                      <Item5><InputValue>{annualPaidLeavesBefore6monthsDays}</InputValue></Item5>
                      <Item5>日</Item5>
                      
                    </span><br/>
                  </div>    
                </AnnualPaidLeaveContainer>
                <div>
                  <Item10>{tFixed("annual_paid_leave")}</Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("annual_paid_leave_after_6_months")}
                      tOptions={tOptions}
                      values={{
                        days: annualPaidLeavesAfter6monthsDays
                      }}
                      components={{
                        seg: <Item5/>,
                        anu_leave: <AnnualPaidLeaveAfter6Months />
                      }}
                    />
                  </Item10>
                </div>
                <span>
                  <Item5>{tFixed("annual_paid_leave_before_6_months")}</Item5>
                  <Item5>
                    <Item5>
                      <CheckMark
                        checked={ec?.leavesAnnualPaidLeavesBefore_6Months === true}
                      >
                        {tFixed("yes")}
                      </CheckMark>
                    </Item5>     
                    <Item5>
                      <CheckMark
                        checked={ec?.leavesAnnualPaidLeavesBefore_6Months === false}
                      >
                        {tFixed("no")}
                      </CheckMark>
                    </Item5>
                  </Item5>
                  <Trans
                    i18nKey={getTKey("annual_paid_leave_before_6_months_num")}
                    tOptions={tOptions}
                    values={{
                      months: annualPaidLeavesBefore6monthsMonths,
                      days: annualPaidLeavesBefore6monthsDays
                    }}
                    components={{
                      seg: <Item5/>,
                      months: <AnnualPaidLeaveBefore6MonthsMonths />,
                      days: <AnnualPaidLeaveBefore6MonthsDays />
                    }}
                  />
                </span>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <FlexRowColumn>
                  <Item10>その他の休暇</Item10>
                  <Item10> 有給（<InputValue>{otherLeavesPaid}</InputValue>）</Item10>
                  <Item10>無給（<InputValue>{otherLeavesUnpaid}</InputValue>）</Item10>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item10>{tFixed("other_vacation")}</Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("other_vacation_paid")}
                      tOptions={tOptions}
                      values={{
                        other: otherLeavesPaidTrans
                      }}
                      components={{
                        other: <OtherLeavesPaid />,
                      }}
                    />
                  </Item10>
                  <Item10>
                    <Trans
                      i18nKey={getTKey("other_vacation_unpaid")}
                      tOptions={tOptions}
                      values={{
                        other: otherLeavesUnpaidTrans
                      }}
                      components={{
                        other: <OtherLeavesUnpaid />,
                      }}
                    />
                  </Item10>
                </FlexRowColumn>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>3.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <Row>
                  <Item10>一時帰国休暇</Item10>
                  <Item10>乙が一時帰国を希望した場合は，上記１及び２の範囲内で必要な休暇を取得させることとする。</Item10>
                </Row>
                <Row>
                  <Item10>{tFixed("temporary_return_leave")}</Item10>
                  <Item10>{tFixed("temporary_return_leave_policy")}</Item10>
                </Row>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <BlockNoteContainer>
            <span>{notesOnVacation}</span><br/>
            <span>{notesOnVacationTrans}</span>
          </BlockNoteContainer>
        </ListBlocksContainer>
      </Indent>
    </Section>
  )
}

export default VacationSection;