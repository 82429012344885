import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  CheckboxesContainer,
  CheckboxContainer,
  RadioButtonsContainer,
} from "../../commonStyles";
import Checkbox from "../../../../../core/components/Checkbox";
import Text from "../../../../../core/components/Text";
import NumberField from "../../../../../core/components/NumberField";
import Radio from "../../../../../core/components/Radio";
import { EcIrregularHolidayUnit } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";
import MultiLangInput from "../../components/MultilingualInput";
import { canAssignEditableTrans, findItemByKind, prepareNewEditableTranslations } from "../../../../../core/utils";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface HolidaysSectionProps extends FormBlockSectionProps {}

const HolidaysSection: FunctionComponent<HolidaysSectionProps> = ({
  employmentConditions: ec,
  company,
  onChange,
  onClickLangInputButton
}) => {
  const ecEditableTrans = ec?.editableTranslations ?? [];
  const empEditableTrans = company?.employer?.editableTranslations ?? [];

  return (
    <FormSection>
      <SectionHeading>休日</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>定例日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxesContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysMonday}
                onValueChange={val => onChange?.({ holidaysFixedDaysMonday: val })}
              />
              <Text>月</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysTuesday}
                onValueChange={val => onChange?.({ holidaysFixedDaysTuesday: val })}
              />
              <Text>火</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysWednesday}
                onValueChange={val => onChange?.({ holidaysFixedDaysWednesday: val })}
              />
              <Text>水</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysThursday}
                onValueChange={val => onChange?.({ holidaysFixedDaysThursday: val })}
              />
              <Text>木</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysFriday}
                onValueChange={val => onChange?.({ holidaysFixedDaysFriday: val })}
              />
              <Text>金</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysSaturday}
                onValueChange={val => onChange?.({ holidaysFixedDaysSaturday: val })}
              />
              <Text>土</Text>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox 
                checked={!!ec?.holidaysFixedDaysSunday}
                onValueChange={val => onChange?.({ holidaysFixedDaysSunday: val })}
              />
              <Text>日</Text>
            </CheckboxContainer>
          </CheckboxesContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>日本の国民の祝日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CheckboxContainer>
            <Checkbox 
              checked={!!ec?.holidaysNationalHolidaysOfJapan}
              onValueChange={val => onChange?.({ holidaysNationalHolidaysOfJapan: val })}
            />
            <Text>あり</Text>
          </CheckboxContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他定例休日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.HolidaysOtherFixedHolidays)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "その他定例休日",
              kind: EditableTranslationKind.HolidaysOtherFixedHolidays
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.HolidaysOtherFixedHolidays
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.HolidaysOtherFixedHolidays
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>年間合計休日日数</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            placeholder="125"
            value={ec?.holidaysAnnualNumberOfHolidays ?? ""}
            onValueChange={val => onChange?.({ holidaysAnnualNumberOfHolidays: val })}
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>非定例日（単位）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={
                ec?.holidaysIrregularDaysUnit === EcIrregularHolidayUnit.Week
              }
              value={EcIrregularHolidayUnit.Week}
              onValueChange={val => onChange?.({ holidaysIrregularDaysUnit: val })}
            >
              週当たり
            </Radio>
            <Radio
              checked={
                ec?.holidaysIrregularDaysUnit === EcIrregularHolidayUnit.Month
              }
              value={EcIrregularHolidayUnit.Month}
              onValueChange={val => onChange?.({ holidaysIrregularDaysUnit: val })}
            >
              月当たり
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>非定例日（日数）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField
            disabled={!!!EcIrregularHolidayUnit}
            placeholder={EcIrregularHolidayUnit ? "2" : "" }
            value={ec?.holidaysIrregularDaysNumberOfDays ?? ""}
            onValueChange={val => onChange?.({ holidaysIrregularDaysNumberOfDays: val })}
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他非定例休日</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.HolidaysOtherIrregularHolidays)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "その他非定例休日",
              kind: EditableTranslationKind.HolidaysOtherIrregularHolidays
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.HolidaysOtherIrregularHolidays
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.HolidaysOtherIrregularHolidays
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>備考</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.HolidaysNote)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "備考",
              kind: EditableTranslationKind.HolidaysNote
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.HolidaysNote
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.HolidaysNote
              )
            }
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default HolidaysSection;
