import { FunctionComponent, useEffect, useState } from "react";
import { GridRowsContainer } from "../commonStyle";
import Text from "../../../../core/components/Text";
import { findItemByKind, isEmpty, isNotEmpty } from "../../../../core/utils";
import styled from "styled-components";
import { EditableTranslationKind } from "../../../enums/translation";
import { JobFormSectionProps } from "../type";
import LanguageInputModal from "../../../../core/components/MultiLanguageEditorModal";
import { JobEditableTranslation } from "../../../types/api";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";

interface MultilingualDisplaySectionProps extends JobFormSectionProps {
  reflectOrgInfo?: boolean;
}

type TranslationItem = {
  label: string;
  note?: string;
  translation: Partial<JobEditableTranslation> | null;
  onClickInputButton: () => void;
};

type TransModalSettings = {
  heading?: string;
  translation: Partial<JobEditableTranslation> | null;
  setTranslation: (translation: Partial<JobEditableTranslation>) => void;
};

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  border-bottom: 1px solid #d5d5d5;

  &:last-child {
    border-bottom: none;
  }
`;

const LabelField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 30px;
  justify-content: center;
  gap: 6px;
`;

const LabelText = styled(Text)`
  font-weight: 700;
`;

const ContentField = styled.div`
  display: flex;
  padding: 10px 30px 10px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

const InputButton = styled.button`
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #acacac;
  background: #fff;
  min-height: 30px;
  padding: 0 15px;
  cursor: pointer;
`;

const TransTextsContainer = styled.div``;

const TransText = styled(Text)`
  color: #999;
`;

const MultilingualDisplaySection: FunctionComponent<
  MultilingualDisplaySectionProps
> = ({ job, onChange, reflectOrgInfo }) => {
  const { state } = useReducerContext();
  const [showLangInputModal, setShowLangInputModal] = useState(false);
  const [transModalSettings, setTransModalSettings] =
    useState<TransModalSettings>();
  const jobEditableTrans = job?.editableTranslations ?? [];
  const empEditableTrans = state?.company?.employer?.editableTranslations ?? [];
  const labelDic = {
    [EditableTranslationKind.JobClassification]: "業務区分",
    [EditableTranslationKind.JobClassificationAfterEmployment]: "業務区分",
    [EditableTranslationKind.WorkHoursNote]: "労働時間の備考",
    [EditableTranslationKind.HolidaysOtherFixedHolidays]: "その他の定例休日",
    [EditableTranslationKind.HolidaysOtherIrregularHolidays]: "その他の非定例休日",
    [EditableTranslationKind.HolidaysNote]: "休日の備考",
    [EditableTranslationKind.LeavesOtherPaidLeaves]: "その他の休暇（有給）",
    [EditableTranslationKind.LeavesOtherUnpaidLeaves]: "その他の休暇（無休）",
    [EditableTranslationKind.LeavesNote]: "休暇の備考",
    [EditableTranslationKind.CompensationBenefits]: "諸手当（時間外労働の割増賃金を除く）",
    [EditableTranslationKind.ResignationNote]: "退職の備考",
    [EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail]: "社会保険等の加入状況（その他）",
    [EditableTranslationKind.OtherWorkRegulationsCheckMethod]: "就業規則確認方法",
    [EditableTranslationKind.BenefitsBenefit1Name]: "手当1",
    [EditableTranslationKind.BenefitsBenefit1CalculationMethod]: "手当1の計算方法",
    [EditableTranslationKind.BenefitsBenefit2Name]: "手当2",
    [EditableTranslationKind.BenefitsBenefit2CalculationMethod]: "手当2の計算方法",
    [EditableTranslationKind.BenefitsBenefit3Name]: "手当3",
    [EditableTranslationKind.BenefitsBenefit3CalculationMethod]: "手当3の計算方法",
    [EditableTranslationKind.BenefitsBenefit4Name]: "手当4",
    [EditableTranslationKind.BenefitsBenefit4CalculationMethod]: "手当4の計算方法",
    [EditableTranslationKind.BenefitsFixedOvertimePayName]: "固定残業代",
    [EditableTranslationKind.DeductionsOtherDeductions1Name]: "その他控除1",
    [EditableTranslationKind.DeductionsOtherDeductions2Name]: "その他控除2",
    [EditableTranslationKind.DeductionsOtherDeductions3Name]: "その他控除3",
    [EditableTranslationKind.DeductionsOtherDeductions4Name]: "その他控除4",
  }

  const findTranslation = (kind: EditableTranslationKind) =>
    findItemByKind(jobEditableTrans, kind);

  const getSetTranslationMethod = (kind: EditableTranslationKind) => {
    return (val: Partial<JobEditableTranslation>) => {
      const newTrans = jobEditableTrans.map((item: JobEditableTranslation) => {
        if (item.kind === kind) {
          return {
            ...item,
            ...val,
          };
        } else {
          return item;
        }
      });

      onChange?.({ editableTranslations: newTrans });
    };
  };

  const getOnClickInputButton = (kind: EditableTranslationKind) => {
    return () => {
      setTransModalSettings({
        heading: labelDic[kind],
        translation: findItemByKind(jobEditableTrans, kind),
        setTranslation: getSetTranslationMethod(kind),
      });
      setShowLangInputModal(true);
    };
  };

  const translationItems: TranslationItem[] = [
    {
      label: labelDic[EditableTranslationKind.JobClassification],
      translation: findTranslation(
        EditableTranslationKind.JobClassification
      ),
      onClickInputButton: getOnClickInputButton(
        EditableTranslationKind.JobClassification,
      ),
    },
    {
      label: labelDic[EditableTranslationKind.JobClassificationAfterEmployment],
      note: "※雇入後に変更がある場合",
      translation: findTranslation(
        EditableTranslationKind.JobClassificationAfterEmployment
      ),
      onClickInputButton: getOnClickInputButton(
        EditableTranslationKind.JobClassificationAfterEmployment
      ),
    },
    {
      label: labelDic[EditableTranslationKind.WorkHoursNote],
      translation: findTranslation(EditableTranslationKind.WorkHoursNote),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.WorkHoursNote),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysOtherFixedHolidays],
      translation: findTranslation(EditableTranslationKind.HolidaysOtherFixedHolidays),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.HolidaysOtherFixedHolidays),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysOtherIrregularHolidays],
      translation: findTranslation(EditableTranslationKind.HolidaysOtherIrregularHolidays),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.HolidaysOtherIrregularHolidays),
    },
    {
      label: labelDic[EditableTranslationKind.HolidaysNote],
      translation: findTranslation(EditableTranslationKind.HolidaysNote),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.HolidaysNote),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesOtherPaidLeaves],
      translation: findTranslation(EditableTranslationKind.LeavesOtherPaidLeaves),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.LeavesOtherPaidLeaves),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesOtherUnpaidLeaves],
      translation: findTranslation(EditableTranslationKind.LeavesOtherUnpaidLeaves),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.LeavesOtherUnpaidLeaves),
    },
    {
      label: labelDic[EditableTranslationKind.LeavesNote],
      translation: findTranslation(EditableTranslationKind.LeavesNote),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.LeavesNote),
    },
    {
      label: labelDic[EditableTranslationKind.CompensationBenefits],
      translation: findTranslation(EditableTranslationKind.CompensationBenefits),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.CompensationBenefits),
    },
    {
      label: labelDic[EditableTranslationKind.ResignationNote],
      translation: findTranslation(EditableTranslationKind.ResignationNote),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.ResignationNote),
    },
    {
      label: labelDic[EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail],
      translation: findTranslation(EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.OtherSocialInsuranceCoverageOtherDetail),
    },
    {
      label: labelDic[EditableTranslationKind.OtherWorkRegulationsCheckMethod],
      translation: findTranslation(EditableTranslationKind.OtherWorkRegulationsCheckMethod),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.OtherWorkRegulationsCheckMethod),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit1Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit1Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit1Name),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit1CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit1CalculationMethod),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit1CalculationMethod),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit2Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit2Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit2Name),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit2CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit2CalculationMethod),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit2CalculationMethod),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit3Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit3Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit3Name),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit3CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit3CalculationMethod),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit3CalculationMethod),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit4Name],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit4Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit4Name),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsBenefit4CalculationMethod],
      translation: findTranslation(EditableTranslationKind.BenefitsBenefit4CalculationMethod),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsBenefit4CalculationMethod),
    },
    {
      label: labelDic[EditableTranslationKind.BenefitsFixedOvertimePayName],
      translation: findTranslation(EditableTranslationKind.BenefitsFixedOvertimePayName),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.BenefitsFixedOvertimePayName),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions1Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions1Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.DeductionsOtherDeductions1Name),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions2Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions2Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.DeductionsOtherDeductions2Name),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions3Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions3Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.DeductionsOtherDeductions3Name),
    },
    {
      label: labelDic[EditableTranslationKind.DeductionsOtherDeductions4Name],
      translation: findTranslation(EditableTranslationKind.DeductionsOtherDeductions4Name),
      onClickInputButton: getOnClickInputButton(EditableTranslationKind.DeductionsOtherDeductions4Name),
    },
  ];

  useEffect(() => {
    if (!reflectOrgInfo) 
      return;

    const newJobTrans: JobEditableTranslation[] = jobEditableTrans.map(et => {
      const kind = et.kind;
      const empTrans = findItemByKind(empEditableTrans, kind);
      const jobTrans = findItemByKind(jobEditableTrans, kind);

      if (isEmpty(jobTrans?.jaManual) && isNotEmpty(empTrans?.jaManual)) {
        return {
          jaManual: empTrans?.jaManual || null,
          enManual: empTrans?.enManual || null,
          idManual: empTrans?.idManual || null,
          neManual: empTrans?.neManual || null,
          viManual: empTrans?.viManual || null,
          myManual: empTrans?.myManual || null,
          enAuto: empTrans?.enAuto || null,
          idAuto: empTrans?.idAuto || null,
          neAuto: empTrans?.neAuto || null,
          viAuto: empTrans?.viAuto || null,
          myAuto: empTrans?.myAuto || null,
          kind,
        }
      } else {
        return et;
      }
    });

    onChange?.({ editableTranslations: newJobTrans });
  }, [reflectOrgInfo]);

  return (
    <>
      <GridRowsContainer>
        {translationItems.map((item, index) => (
          <ItemContainer key={`multi-lang-item-${index}`}>
            <LabelField>
              <LabelText>{item.label}</LabelText>
              {item.note && <Text>{item.note}</Text>}
            </LabelField>
            <ContentField>
              <InputButton onClick={item.onClickInputButton}>
                <Text>入力</Text>
              </InputButton>
              <TransTextsContainer>
                <TransText>
                  {item.translation?.jaManual || "説明テキストを入力"}
                </TransText>
                <TransText>
                  {item.translation?.enManual ||
                    item.translation?.enAuto ||
                    "Enter descriptive text"}
                </TransText>
                <TransText>
                  {item.translation?.idManual ||
                    item.translation?.idAuto ||
                    "Masukkan teks deskriptif"}
                </TransText>
                <TransText>
                  {item.translation?.neManual ||
                    item.translation?.neAuto ||
                    "वर्णनात्मक पाठ प्रविष्ट गर्नुहोस्"}
                </TransText>
                <TransText>
                  {item.translation?.viManual ||
                    item.translation?.viAuto ||
                    "Nhập văn bản mô tả"}
                </TransText>
                <TransText>
                  {item.translation?.myManual ||
                    item.translation?.myAuto ||
                    "ဖော်ပြချက်စာသားကို ရိုက်ထည့်ပါ။"}
                </TransText>
              </TransTextsContainer>
            </ContentField>
          </ItemContainer>
        ))}
      </GridRowsContainer>

      <LanguageInputModal
        open={showLangInputModal}
        onCancel={() => setShowLangInputModal(false)}
        onClose={() => setShowLangInputModal(false)}
        onSave={() => setShowLangInputModal(false)}
        heading={transModalSettings?.heading}
        translation={transModalSettings?.translation}
        setTranslation={transModalSettings?.setTranslation}
      />
    </>
  );
};

export default MultilingualDisplaySection;
