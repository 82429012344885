import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Column5,  Segment } from "../../commonStyles";
import { BlockNoteContainer, Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import styled from "styled-components";
import { TemplateSectionProps } from "../../types";
import { Trans, useTranslation } from "react-i18next";
import { EditableTranslationKind } from "../../../../../job/enums/translation";


interface ResignationSectionProps extends TemplateSectionProps {}

const DayWrapper = styled.span`
  display: inline-block;
  min-width: 39px;
  border-bottom: 0.5px solid black;
  text-align: center;
  white-space: pre;
`;

const ResignationSection: FunctionComponent<ResignationSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {

  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [noticeDayNum, setNoticeDayNum] = useState<string>();
  const [notesOnResignation, setNotesOnResignation] = useState<string>("");
  const [NotesOnResignationTrans, setNotesOnResignationTrans] = useState<string>("");

  const ResignationNoticeDayNum = ({ children } : { children?: ReactNode }) => 
    <DayWrapper>{children}</DayWrapper>;
  

  useEffect(() => {
    if (!ec) 
      return;

    setNoticeDayNum(
      ec.resignationProcedureForResignationForPersonalReasons == null
        ? ''
        : ec.resignationProcedureForResignationForPersonalReasons.toString()
    );
    setNotesOnResignation(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.ResignationNote)
    );
    setNotesOnResignationTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.ResignationNote)
    );
  }, [ec]);

  return (
    <Section>
      <SectionHeadingContainer>
        <span>VIII.</span>
        <Segment>
          <span>退職に関する事項</span><br/>
          <span>{tFixed("resignation_procedures")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>
                  自己都合退職の手続（退職する <DayWrapper>{noticeDayNum}</DayWrapper> 日前に社長・工場長等に届けること）
                </span>
                <br/>
                <span>
                  <Trans
                    i18nKey={getTKey("voluntary_resignation_procedure")}
                    tOptions={{ lng: transLang }}
                    values={{
                      date: noticeDayNum
                    }}
                    components={{
                      date: <ResignationNoticeDayNum />
                    }}
                  />
                </span>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <div>
                  <span>解雇の事由及び手続</span><br/>
                  <span>解雇は，やむを得ない事由がある場合に限り少なくとも30日前に予告をするか，又は30日分以上の平均賃金を支払って解雇する。特定技能外国人の責めに帰すべき事由に基づいて解雇する場合には，所轄労働基準監督署長の認を受けることにより予告も平均賃金の支払も行わず即時解雇されることもあり得る。</span>
                </div>
                <div>
                  <span>{tFixed("resign_reasons_and_procedures")}</span>
                  <span>{tFixed("resignation_policy")}</span>
                </div>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <BlockNoteContainer>
            <span>{notesOnResignation}</span><br/>
            <span>{NotesOnResignationTrans}</span>
          </BlockNoteContainer>
        </ListBlocksContainer>
      </Indent>
    </Section>
  )
}

export default ResignationSection;