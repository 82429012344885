import { FunctionComponent, useEffect, useState } from "react";
import { InputValue, Row, Item5, Segment } from "../../commonStyles";
import { Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import { useJobPostCategory } from "../../../../../core/hooks";
import { TemplateSectionProps } from "../../types";
import { LanguageCode } from "../../../../../core/enums/language";
import { useReducerContext } from "../../../../../core/contexts/ReducerContext";
import { useTranslation } from "react-i18next";
import CheckMark from "../../components/CheckMark";
import { EditableTranslationKind } from "../../../../../job/enums/translation";


interface TypeOfWorkSectionProps extends TemplateSectionProps {}


const TypeOfWorkSection: FunctionComponent<TypeOfWorkSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { state } = useReducerContext();
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const [industryNameInJa, setIndustryNameInJa] = useState<string>("");
  const [industryNameInTransLang, setIndustryNameInTransLang] = useState<string>("");
  const [jobClassification, setJobClassification] = useState<string>("");
  const [jobClassificationInForeignLang, setJobClassificationInForeignLang] = useState<string>("");
  const [jobChangesAfterEmployment, setJobChangesAfterEmployment] = useState<boolean>();
  const [industryNameAfterChangeInJa, setIndustryNameAfterChangeInJa] = useState<string>("");
  const [industryNameAfterChangeInTransLang, setIndustryNameAfterChangeInTransLang] = useState<string>("");
  const [jobClassificationAfterChange, setJobClassificationAfterChange] = useState<string>("");
  const [jobClassificationAfterChangeInForeignLang, setJobClassificationAfterChangeInForeignLang] = useState<string>("");
  const jobPostCategory = useJobPostCategory();

  useEffect(() => {
    if (!ec) 
      return;

    const industryId = jobPostCategory.getIndustryIdByJobTypeId(ec.jobTypeId);
    const changedIndustryId = jobPostCategory.getIndustryIdByJobTypeId(ec.changedJobTypeId);
    setIndustryNameInJa(jobPostCategory.getIndustryName(industryId, LanguageCode.Japanese));
    setIndustryNameInTransLang(jobPostCategory.getIndustryName(industryId, transLang));
    setJobClassification(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.JobClassification)
    );
    setJobClassificationInForeignLang(
      transHandler.getEditableTranslation(EditableTranslationKind.JobClassification)
    );
    setJobChangesAfterEmployment(ec.jobChangesAfterEmployment);

    if (ec?.jobChangesAfterEmployment === true) {
      setIndustryNameAfterChangeInJa(jobPostCategory.getIndustryName(changedIndustryId, LanguageCode.Japanese));
      setIndustryNameAfterChangeInTransLang(jobPostCategory.getIndustryName(changedIndustryId, transLang));
      setJobClassificationAfterChange(
        transHandler.getEditableTranslationInJa(EditableTranslationKind.JobClassificationAfterEmployment)
      );
      setJobClassificationAfterChangeInForeignLang(
        transHandler.getEditableTranslation(EditableTranslationKind.JobClassificationAfterEmployment)
      );
    }
  }, [ec, state.industries, state.jobTypes])

  return (
    <Section>
      <SectionHeadingContainer>
        <span>III.</span> 
        <Segment>
          <span>従事すべき業務の内容</span><br/>
          <span>{tFixed("work_content")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>分野（　
                  <InputValue>{industryNameInJa}</InputValue>
                  　）
                </span><br/>
                <Row>
                  <Item5>{tFixed("field")}</Item5>
                  <Item5>(</Item5>
                  <Item5>
                    <InputValue>{industryNameInTransLang}</InputValue>
                  </Item5>
                  <Item5>)</Item5>
                </Row>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>業務区分（　<InputValue>{jobClassification}</InputValue>　）</span><br/>
                <Row>
                  <Item5>{tFixed("job_classification")}</Item5>
                  <Item5>（</Item5>
                  <Item5>
                    <InputValue>{jobClassificationInForeignLang}</InputValue>
                  </Item5>
                  <Item5>）</Item5>
                </Row>
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockContent>
              <Segment>
                <span>（雇入れ直後）</span>
                <br/>
                <span>{tFixed("immediately_after_hiring")}</span>
                <br/>
                <span>（変更の範囲）</span>
                <br/>
                <span>{tFixed("scope_of_changes")}</span>
                <br/>
                <CheckMark checked={jobChangesAfterEmployment === false}>
                  変更の可能性なし（変更ある場合は以下に記入）
                </CheckMark>
                <br/>
                <CheckMark checked={jobChangesAfterEmployment === false}>
                  {tFixed("no_change_possible")}
                </CheckMark>
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>分野（　
                  <InputValue>{industryNameAfterChangeInJa}</InputValue>
                  　）
                </span><br/>
                <Row>
                  <Item5>{tFixed("field")}</Item5>
                  <Item5>(</Item5>
                  <Item5>
                    <InputValue>{industryNameAfterChangeInTransLang}</InputValue>
                  </Item5>
                  <Item5>)</Item5>
                </Row>
              </Segment>
            </ListBlockContent>
          </ListBlock>

          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>業務区分（　<InputValue>{jobClassificationAfterChange}</InputValue>　）</span><br/>
                <Row>
                  <Item5>{tFixed("job_classification")}</Item5>
                  <Item5>（</Item5>
                  <Item5>
                    <InputValue>{jobClassificationAfterChangeInForeignLang}</InputValue>
                  </Item5>
                  <Item5>）</Item5>
                </Row>
              </Segment>
            </ListBlockContent>
          </ListBlock>

        </ListBlocksContainer>
      </Indent>
    </Section>
  )
}

export default TypeOfWorkSection;