import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import Label from "./Label";
import MultiTextInput from "./MultiTextInput";
import Text from "./Text";
import { ReactComponent as CrossIcon } from "../../assets/icon-close.svg"; 
import { ReactComponent as WarningIcon } from "../../assets/icon-warning-banner.svg";
import Button from "./Button";
import { EditableTranslation } from "../types/api";
import { isEmpty } from "../utils";
import { getTranslation } from "../apis/translation";
import { useReducerContext } from "../contexts/ReducerContext";
import ACTIONS from "../constants/actions";
import { useTranslation } from "react-i18next";


interface MultiLanguageEditorModalProps <T extends EditableTranslation> {
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  heading?: string;
  placeholderInJa?: string;
  translation?: Partial<T> | null | undefined;
  setTranslation?: (translation: Partial<T>) => void | null | undefined;
}

const Content = styled.div`
  max-height: 100%;
  background-color: #ffffff;
  width: 100%;
  max-width: 768px;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 40px;
  width: 100%;
  gap: 16px;
`;

const HeadingContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 16px;
`;

const Heading = styled(Text)`
  font-size: 16px;
  font-weight: 700;
`;

const CloseIcon = styled(CrossIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  & * {
    fill: #00000099;
  }
`;

const WarningMessageContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #C40003;
  background: #FFE9ED;
  width: 100%;
`;

const RedWarningIcon = styled(WarningIcon)`
  * {
    fill: #C40003;
  }
`;

const WarningMessage = styled(Text)`
  color: #C40003;
`;

const LanguageInputGroup = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const LangInputRow = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  min-height: 30px;

  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
`;

const LangLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  justify-content: center;
  gap: 5px;
`;

const LangInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  justify-content: center;
  gap: 5px;
`;

const LocalMultiTextInput = styled(MultiTextInput)`
  width: 100%;
  height: 40px;
  resize: vertical;
  max-height: 100px;
`;

const AutoTransText = styled(Text)`
  color: #999999;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  flex-grow: 1;
  border: 1px solid #C40003;
  color: #C40003;
  background-color: #ffffff;

  &:active, &:focus {
    background-color: #ffffff;
    color: #C40003;
  }
`;

const SaveButton = styled(Button)`
  flex-grow: 1;
  color: #ffffff;
  background-color: #064AAC;
  border: 1px solid #064AAC;

  &:hover {
    background-color: #ffffff;
    color: #064AAC;
  }

  &:active, &:focus {
    background-color: #ffffff;
    color: #064AAC;
  }

  &:disabled {
    color: #ffffff;
    background: rgb(0 0 0 / .40);
    border: none;
  }
`;



const LanguageInputModal = <T extends EditableTranslation>({
  open,
  onClose,
  onCancel,
  onSave,
  heading,
  placeholderInJa,
  translation,
  setTranslation,
}: MultiLanguageEditorModalProps<T>) => {
  const { dispatch } = useReducerContext();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const prevJaUserInput = useRef<string | null>(null);
  const [isInputtingJa, setIsInputtingJa] = useState(false);

  const [jaUserInput, setJaUserInput] = useState<string | null>(null);
  const [enUserInput, setEnUserInput] = useState<string | null>(null);
  const [idUserInput, setIdUserInput] = useState<string | null>(null);
  const [neUserInput, setNeUserInput] = useState<string | null>(null);
  const [viUserInput, setViUserInput] = useState<string | null>(null);
  const [myUserInput, setMyUserInput] = useState<string | null>(null);

  const [enAutoTrans, setEnAutoTrans] = useState<string | null>(null);
  const [idAutoTrans, setIdAutoTrans] = useState<string | null>(null);
  const [neAutoTrans, setNeAutoTrans] = useState<string | null>(null);
  const [viAutoTrans, setViAutoTrans] = useState<string | null>(null);
  const [myAutoTrans, setMyAutoTrans] = useState<string | null>(null);

  const disableSaveButton = [
    translation,
    setTranslation
  ].some(isEmpty) || isInputtingJa;

  const onBlurJaInput = async () => {
    try {
      if (!jaUserInput) {
        setEnAutoTrans(null);
        setIdAutoTrans(null);
        setNeAutoTrans(null);
        setViAutoTrans(null);
        setMyAutoTrans(null);
        return;
      }

      dispatch({ type: ACTIONS.START_LOADING });
      const translation = await getTranslation(jaUserInput)
      setEnAutoTrans(translation.en);
      setIdAutoTrans(translation.id);
      setNeAutoTrans(translation.ne);
      setViAutoTrans(translation.vi);
      setMyAutoTrans(translation.my);
    } catch {
      setJaUserInput(prevJaUserInput.current);
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: tCommon('error_occurred'),
        },
      })
    } finally {
      prevJaUserInput.current = null;
      setIsInputtingJa(false);
      dispatch({ type: ACTIONS.STOP_LOADING });
    }
  };

  const onClickSaveButton = () => {
    if (!translation || !setTranslation) 
      return;

    setTranslation?.({
      ...translation,
      jaManual: jaUserInput,
      enManual: enUserInput,
      idManual: idUserInput,
      neManual: neUserInput,
      viManual: viUserInput,
      myManual: myUserInput,
      enAuto: enAutoTrans,
      idAuto: idAutoTrans,
      neAuto: neAutoTrans,
      viAuto: viAutoTrans,
      myAuto: myAutoTrans,
    });

    onSave?.();
  }

  useEffect(() => {
    if (!translation) 
      return;

    setJaUserInput(translation.jaManual ?? '');
    setEnUserInput(translation.enManual ?? '');
    setIdUserInput(translation.idManual ?? '');
    setNeUserInput(translation.neManual ?? '');
    setViUserInput(translation.viManual ?? '');
    setMyUserInput(translation.myManual ?? '');

    setEnAutoTrans(translation.enAuto ?? '');
    setIdAutoTrans(translation.idAuto ?? '');
    setNeAutoTrans(translation.neAuto ?? '');
    setViAutoTrans(translation.viAuto ?? '');
    setMyAutoTrans(translation.myAuto ?? '');

  }, [translation]);

  return (
    <Modal open={open} onClose={onClose}>
      <Content>
        <HeaderContainer>
          <HeadingContainer>
            <CloseIcon onClick={onClose} />
            <Heading>{heading}</Heading>          
          </HeadingContainer>
          <WarningMessageContainer>
            <RedWarningIcon/>
            <WarningMessage>
              日本語を入力するとGoogle自動翻訳されます。<br/>
              内容を編集したい場合はフォームにテキストを入力してください。
            </WarningMessage>
          </WarningMessageContainer>
        </HeaderContainer>

        <LanguageInputGroup>
          <LangInputRow>
            <LangLabelContainer>
              <Label required>
                日本語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <LocalMultiTextInput
                placeholder={placeholderInJa}
                value={jaUserInput ?? ''}
                onTextChange={setJaUserInput}
                onFocus={() => {
                  setIsInputtingJa(true);
                  prevJaUserInput.current = jaUserInput;
                }}
                onBlur={onBlurJaInput}
              />
            </LangInputContainer>
          </LangInputRow>

          <LangInputRow>
            <LangLabelContainer>
              <Label>
                英語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <AutoTransText>
                自動翻訳：{enAutoTrans}
              </AutoTransText>
              <LocalMultiTextInput
                value={enUserInput ?? ''}
                onTextChange={setEnUserInput}
              />
            </LangInputContainer>
          </LangInputRow>

          <LangInputRow>
            <LangLabelContainer>
              <Label>
                インドネシア語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <AutoTransText>
                自動翻訳：{idAutoTrans}
              </AutoTransText>
              <LocalMultiTextInput
                value={idUserInput ?? ''}
                onTextChange={setIdUserInput}
              />
            </LangInputContainer>
          </LangInputRow>

          <LangInputRow>
            <LangLabelContainer>
              <Label>
                ネパール語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <AutoTransText>
                自動翻訳：{neAutoTrans}
              </AutoTransText>
              <LocalMultiTextInput
                value={neUserInput ?? ''}
                onTextChange={setNeUserInput}
              />
            </LangInputContainer>
          </LangInputRow>

          <LangInputRow>
            <LangLabelContainer>
              <Label>
                ベトナム語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <AutoTransText>
                自動翻訳：{viAutoTrans}
              </AutoTransText>
              <LocalMultiTextInput
                value={viUserInput ?? ''}
                onTextChange={setViUserInput}
              />
            </LangInputContainer>
          </LangInputRow>

          <LangInputRow>
            <LangLabelContainer>
              <Label>
                ミャンマー語
              </Label>
            </LangLabelContainer>
            <LangInputContainer>
              <AutoTransText>
                自動翻訳：{myAutoTrans}
              </AutoTransText>
              <LocalMultiTextInput
                value={myUserInput ?? ''}
                onTextChange={setMyUserInput}
              />
            </LangInputContainer>
          </LangInputRow>
        </LanguageInputGroup> 

        <ActionButtonsContainer>
          <CancelButton onClick={onCancel}>
            キャンセル
          </CancelButton>
          <SaveButton 
            disabled={disableSaveButton}
            onClick={onClickSaveButton} 
          >
            一時保存
          </SaveButton>
        </ActionButtonsContainer>
      </Content>
    </Modal>
  );
};

export default LanguageInputModal;
