import { FunctionComponent } from "react";
import {
  Column5, CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalMultiTextInputMiddle, LocalNumberField,
  LocalPostCodeInput, LocalSelectExtend, LocalTextInput, LocalTextInputExtend, LocalTextInputGrow,
  RadioButtonsWrapper, Row10,
  SectionHeadingLarge, TransValFieldGroup, UnitText, WordBreakLabel
} from "../commonStyle"
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import Radio from "../../../../core/components/Radio";
import Text from "../../../../core/components/Text";
import {usePrefectures} from "../../../../core/hooks";
interface SupportOrganizationSupportSectionProps extends SupportPlanFormProps {
}

export const SupportOrganizationSupportSection: FunctionComponent<SupportOrganizationSupportSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  const {prefectureWithJaValOptions, findPrefEnValByJaVal} = usePrefectures();
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅲ. 登録支援機関（支援情報）</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 支援先事務所の郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalPostCodeInput
                value={supportPlan?.supportOrganizationSupportOfficePostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ supportOrganizationSupportOfficePostalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChangeSupportPlan?.({ supportOrganizationSupportOfficePostalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationSupportOfficeJaPrefecture: val.jaPrefecture,
                    supportOrganizationSupportOfficeEnPrefecture: val.enPrefecture
                  });
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationSupportOfficeJaCityWard: val.jaCity,
                    supportOrganizationSupportOfficeEnCityWard: val.enCity
                  });
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationSupportOfficeJaTown: val.jaTown,
                    supportOrganizationSupportOfficeEnTown: val.enTown
                  });
                }}
                error={isEmpty(supportPlan?.organizationOfAffiliationPostalCode)}
                suppressErrorMessage
                isDisplayNote
              />
              {isEmpty(supportPlan?.supportOrganizationSupportOfficePostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalSelectExtend
                  placeholder="東京都"
                  options={prefectureWithJaValOptions}
                  value={supportPlan?.supportOrganizationSupportOfficeJaPrefecture}
                  onValueChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeJaPrefecture: val,
                      supportOrganizationSupportOfficeEnPrefecture: findPrefEnValByJaVal(val)
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeJaPrefecture)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  disabled
                  value={supportPlan?.supportOrganizationSupportOfficeEnPrefecture ?? ""}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationSupportOfficeJaPrefecture)
                  || isEmpty(supportPlan?.supportOrganizationSupportOfficeEnPrefecture))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="港区"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeJaCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeJaCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeJaCityWard)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Minato-ku"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeEnCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeEnCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeEnCityWard)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationSupportOfficeJaCityWard)
                  || isEmpty(supportPlan?.supportOrganizationSupportOfficeEnCityWard))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="海岸"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeJaTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeJaTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeJaTown)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Kaigan"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeEnTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeEnTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeEnTown)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationSupportOfficeJaTown)
                  || isEmpty(supportPlan?.supportOrganizationSupportOfficeEnTown))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="１丁目９−１８"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeJaAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeJaAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeJaAddressNumber)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="1-9-18"
                  value={
                    supportPlan?.supportOrganizationSupportOfficeEnAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationSupportOfficeEnAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationSupportOfficeEnAddressNumber)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationSupportOfficeJaAddressNumber)
                  || isEmpty(supportPlan?.supportOrganizationSupportOfficeEnAddressNumber))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援先事務所の建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={
                  supportPlan?.supportOrganizationSupportOfficeJaBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationSupportOfficeJaBuilding: val,
                  });
                }}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={
                  supportPlan?.supportOrganizationSupportOfficeEnBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationSupportOfficeEnBuilding: val,
                  });
                }}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.supportOrganizationSupportOfficePhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationSupportOfficePhoneNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportOfficePhoneNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationSupportOfficePhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援責任者の名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="田村"
                value={supportPlan?.supportOrganizationSupportManagerLastName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerLastName: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerLastName)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援責任者の名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="秀介"
                value={supportPlan?.supportOrganizationSupportManagerFirstName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerFirstName: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerFirstName)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名字ふりがな */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援責任者の名字<br/>ふりがな</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="たむら"
                value={supportPlan?.supportOrganizationSupportManagerLastNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerLastNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerLastNameFurigana)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerLastNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前ふりがな */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援責任者の名前<br/>ふりがな</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="しゅうすけ"
                value={supportPlan?.supportOrganizationSupportManagerFirstNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerFirstNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerFirstNameFurigana)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerFirstNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 役職 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>役職</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="（役職なし）"
                value={supportPlan?.supportOrganizationSupportManagerTitle ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerTitle: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerTitle)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerTitle) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援を行っている1号特定技能外国人数 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援を行っている1号特定技能外国人数</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Row10>
                <LocalNumberField
                  allowZero
                  placeholder="0"
                  step="1"
                  min="0"
                  value={supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount ?? 0}
                  error={isEmpty(supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount)}
                  onValueChange={(val: number | null) => {
                    onChangeSupportPlan?.({supportOrganizationSupportSpecificSkilledWorkerCount: Number(val)});
                  }}
                />
                <UnitText>人</UnitText>
              </Row10>
              {isEmpty(supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援担当者数 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援担当者数</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Row10>
                <LocalNumberField
                  allowZero
                  placeholder="0"
                  step="1"
                  min="0"
                  value={supportPlan?.supportOrganizationSupportPersonCount ?? 0}
                  error={isEmpty(supportPlan?.supportOrganizationSupportPersonCount)}
                  onValueChange={(val: number | null) => {
                    onChangeSupportPlan?.({supportOrganizationSupportPersonCount: Number(val)});
                  }}
                />
                <UnitText>人</UnitText>
              </Row10>
              {isEmpty(supportPlan?.supportOrganizationSupportPersonCount) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援の適正性を担保していることの有無 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援の適正性を担保していることの有無</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={
                  supportPlan?.supportOrganizationSupportEnsureProperty === true
                }
                onValueChange={() => {
                  onChangeSupportPlan?.({supportOrganizationSupportEnsureProperty: true});
                }}
                error={supportPlan?.supportOrganizationSupportEnsureProperty == null}
              >
                有
              </Radio>
              <Radio
                checked={
                  supportPlan?.supportOrganizationSupportEnsureProperty === false
                }
                onValueChange={() => {
                  onChangeSupportPlan?.({supportOrganizationSupportEnsureProperty: false});
                }}
                error={supportPlan?.supportOrganizationSupportEnsureProperty == null}
              >
                無
              </Radio>
            </RadioButtonsWrapper>
            {supportPlan?.supportOrganizationSupportEnsureProperty == null &&
                <ErrorMessage>選択してください</ErrorMessage>
            }
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default SupportOrganizationSupportSection;