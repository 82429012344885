import { apiUtil } from "../../core/utils";
import { ChatMessageType } from "../types";

export const getChatMessages = async (proposalId: number | string) => {
  const response = await apiUtil.get(`/proposal_chat_messages?proposal_id=${proposalId}`);
  if (response.status >= 200 && response.status < 300) {
      return (response.data as ChatMessageType[]).reverse();
  }
  throw new Error('API error');
};

export const sendChatMessage = async (proposalId: number | string, text: string) => {
  const response = await apiUtil.post(
      '/proposal_chat_messages',
      {
          proposalId: Number(proposalId),
          originalText: text,
      },
  )
  if (response.status >= 200 && response.status < 300) {
      return response.data as ChatMessageType;
  }
  throw new Error('API error');
};

export const deleteChatMessage = async (messageId: number | string) => {
  const response = await apiUtil.delete(`/proposal_chat_messages/${messageId}`);
  if (response.status >= 200 && response.status < 300) {
      return response.data;
  }
  throw new Error('API error');
}