import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  LabelContainer,
  NoteInLabel,
  EmphasizedItemLabel,
  NoteOnSection,
  SectionNoteContainer,
  SectionHeadingLabel,
} from "../../commonStyles";
import CurrencyField from "../../../../../core/components/CurrencyField";
import { FormBlockSectionProps } from "../../types";
import Text from "../../../../../core/components/Text";
import NumberField from "../../../../../core/components/NumberField";
import styled from "styled-components";
import MultiLangInput from "../../components/MultilingualInput";
import { canAssignEditableTrans, findItemByKind, prepareNewEditableTranslations } from "../../../../../core/utils";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface BenefitsSectionProps extends FormBlockSectionProps {}

const LocalInputContainer = styled(InputContainer)`
  flex-direction: column;
  gap: 5px;
`;

const Paragraph = styled.p`
  width: 100%;
  margin: 0;
`;

const LocalText = styled(Text)`
  display: inline;
`;

const LocalNumberField = styled(NumberField)`
  text-align: right;
`;

const BenefitsSection: FunctionComponent<BenefitsSectionProps> = ({
  employmentConditions: ec,
  company,
  onChange,
  onClickLangInputButton
}) => {
  const ecEditableTrans = ec?.editableTranslations ?? [];
  const empEditableTrans = company?.employer?.editableTranslations ?? [];

  return (
    <FormSection>
      <FormRow>   
        <LabelContainer>
          <SectionHeadingLabel>手当</SectionHeadingLabel>
        </LabelContainer>  
        <SectionNoteContainer>
          <NoteOnSection>※時間外労働の割増賃金は除く</NoteOnSection>
        </SectionNoteContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（名称）
          </ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit1Name)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当1（名称）",
              kind: EditableTranslationKind.BenefitsBenefit1Name
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit1Name
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit1Name
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_1Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_1Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当1（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit1CalculationMethod)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当1（計算方法）",
              kind: EditableTranslationKind.BenefitsBenefit1CalculationMethod
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit1CalculationMethod
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit1CalculationMethod
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit2Name)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当2（名称）",
              kind: EditableTranslationKind.BenefitsBenefit2Name
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit2Name
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit2Name
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_2Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_2Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当2（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit2CalculationMethod)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当2（計算方法）",
              kind: EditableTranslationKind.BenefitsBenefit2CalculationMethod
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit2CalculationMethod
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit2CalculationMethod
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit3Name)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当3（名称）",
              kind: EditableTranslationKind.BenefitsBenefit3Name
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit3Name
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit3Name
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_3Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_3Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当3（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit3CalculationMethod)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当3（計算方法）",
              kind: EditableTranslationKind.BenefitsBenefit3CalculationMethod
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit3CalculationMethod
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit3CalculationMethod
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit4Name)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当4（名称）",
              kind: EditableTranslationKind.BenefitsBenefit4Name
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit4Name
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit4Name
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsBenefit_4Amount}
            onValueChange={value => onChange?.({ benefitsBenefit_4Amount: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>手当4（計算方法）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsBenefit4CalculationMethod)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "手当4（計算方法）",
              kind: EditableTranslationKind.BenefitsBenefit4CalculationMethod
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsBenefit4CalculationMethod
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsBenefit4CalculationMethod
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.BenefitsFixedOvertimePayName)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "固定残業代（名称）",
              kind: EditableTranslationKind.BenefitsFixedOvertimePayName
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.BenefitsFixedOvertimePayName
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans,
                empEditableTrans,
                EditableTranslationKind.BenefitsFixedOvertimePayName
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="4000"
            value={ec?.benefitsFixedOvertimePayAmount}
            onValueChange={value => onChange?.({ benefitsFixedOvertimePayAmount: value })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>固定残業代（支給要件）</ItemLabel>
        </LabelContainer>
        <LocalInputContainer>
          <Paragraph>
            <LocalText>時間外労働の有無にかかわらず、 </LocalText>
            <LocalNumberField
              placeholder="5"
              value={ec?.benefitsFixedOvertimePayPaymentRequirement_1 ?? ""}
              onValueChange={value => onChange?.({ benefitsFixedOvertimePayPaymentRequirement_1: value })}
            />
            <LocalText> 時間分の時間外手当として支給。</LocalText>
          </Paragraph>
          <Paragraph>
            <LocalNumberField
              placeholder="5"
              value={ec?.benefitsFixedOvertimePayPaymentRequirement_2 ?? ""}
              onValueChange={value => onChange?.({ benefitsFixedOvertimePayPaymentRequirement_2: value })}
            />
            <LocalText> 時間を超える時間外労働分についての割増賃金は追加で支給。</LocalText>
          </Paragraph>
        </LocalInputContainer>
      </FormRow>
      <FormRow />
      <FormRow>
        <LabelContainer>
          <EmphasizedItemLabel>
            1か月当たりの支払概算額
          </EmphasizedItemLabel>
          <NoteInLabel style={{ marginTop: 5 }}>
            基本賃金+時間外割増を除く諸手当
          </NoteInLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="189000"
            value={ec?.estimatedPaymentPerMonth}
            onValueChange={value => onChange?.({ estimatedPaymentPerMonth: value })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default BenefitsSection;
