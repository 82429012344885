import { FunctionComponent } from "react";
import styled from "styled-components";
import Column from "../../../../../core/components/Column";
import Text from "../../../../../core/components/Text";
import { ReactComponent as CreationIcon } from '../../../../../assets/icon-creation.svg';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icon-forward.svg';
import { useReducerContext } from "../../../../../core/contexts/ReducerContext";
import { Proposal } from "../../../../types";
import { useNavigate } from "react-router-dom";
import { createProposalEmploymentConditions } from "../../../../apis/employmentCondition";
import ACTIONS from "../../../../../core/constants/actions";
import { getJob } from "../../../../../job/apis/job";
import { getCandidate } from "../../../../../candidate/apis/candidate";
import { prepareInitializedEcFormData } from "../ecFormInitialization";


interface EmploymentConditionsStatusSegmentProps {
  proposal: Proposal;
}

enum EmploymentConditionsStatus {
  NOT_CREATED = 'NOT_CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  ALREADY_SENT = 'ALREADY_SENT'
}

const EmploymentConditionsStatusBanner = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr 18px;
  width: 100%;
  border-radius: 5px;
  padding: 20px 10px;
  gap: 14px;
  cursor: pointer;
  border: 1px solid #ACACAC;
  background-color: #F7F7F7;
  align-items: center;
`;

const NoteOnEmpConditionsStatus = styled(Text)`
  font-size: 13px;
  line-height: 19px;
  color: #626161;
`;

const EmpConditionsStatusTextContainer = styled(Column)`
  gap: 5px;
`;

const LocalForwardIcon = styled(ForwardIcon)`
  & path {
    fill: #5F6368;
  }
`;

const EmploymentConditionsStatusSegment: FunctionComponent<EmploymentConditionsStatusSegmentProps> = ({
  proposal
}) => {
  const { dispatch, state } = useReducerContext();
  const navigate = useNavigate();
  const employmentConditionsStatus = (() => {
    if (!proposal.employmentCondition) {
      return EmploymentConditionsStatus.NOT_CREATED;
    } else if (proposal.employmentCondition.emailSentAt) {
      return EmploymentConditionsStatus.ALREADY_SENT;
    } else {
      return EmploymentConditionsStatus.IN_PROGRESS;
    } 
  })();

  const onClickECStatusBanner = async () => {
    if (!proposal)
      return;

    const moveToFormPage = () => 
      navigate(`/applications/${proposal.id}/documents/employment_conditions/edit`);

    if (employmentConditionsStatus !== EmploymentConditionsStatus.NOT_CREATED) {
      moveToFormPage();
      return;
    } 
    
    dispatch({ type: ACTIONS.START_LOADING });

    try {
      const job = await getJob(proposal.jobPostId);
      const candidate = await getCandidate(proposal.candidateId);
      const initData = await prepareInitializedEcFormData({
        job,
        candidate,
        company: state.company!
      });
      await createProposalEmploymentConditions(proposal.id, initData);
      moveToFormPage();
      dispatch({ 
        type: ACTIONS.SET_PROMPT, 
        payload: { 
          type: 'success',
          message: "雇用条件書を作成しました" 
        }
      });
    } catch {
      dispatch({ 
        type: ACTIONS.SET_PROMPT, 
        payload: { 
          type: 'warning',
          message: "雇用条件書の作成に失敗しました" 
        }
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING });
    }
      
  }

  return (
    <EmploymentConditionsStatusBanner onClick={onClickECStatusBanner}>
        <CreationIcon/>
        <EmpConditionsStatusTextContainer>
          {/* Message to create a new employment conditions letter */}
          { employmentConditionsStatus === EmploymentConditionsStatus.NOT_CREATED &&
            <>
              <Text>雇用条件書をtokutyで作成する</Text>
              <NoteOnEmpConditionsStatus>候補者や求人の情報を自動的に転記することで、雇用条件書の作成にかかる手間を削減することができます</NoteOnEmpConditionsStatus>
            </>
          }

          {/* Message to show that the letter is in draft */}
          { employmentConditionsStatus === EmploymentConditionsStatus.IN_PROGRESS &&
            <>
              <Text>作成中の雇用条件書があります</Text>
              <NoteOnEmpConditionsStatus>入力完了後、リンクを候補者のメールへ送付することができます</NoteOnEmpConditionsStatus>
            </>
          }

          {/* Message to show that the letter is sent */}
          { employmentConditionsStatus === EmploymentConditionsStatus.ALREADY_SENT &&
            <>
              <Text>候補者へ雇用条件書を送付済みです</Text>
              <NoteOnEmpConditionsStatus>送付後も内容の編集が可能です</NoteOnEmpConditionsStatus>
            </>
          }
        </EmpConditionsStatusTextContainer>
        <LocalForwardIcon/>   
    </EmploymentConditionsStatusBanner>
  )
}

export default EmploymentConditionsStatusSegment;