import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  NoteInLabel,
  RadioButtonsContainer,
} from "../../commonStyles";
import Radio from "../../../../../core/components/Radio";
import CurrencyField from "../../../../../core/components/CurrencyField";
import { EcWagePaymentType } from "../../../../../core/enums/employmentConditions";
import Text from "../../../../../core/components/Text";
import { FormBlockSectionProps } from "../../types";

interface BaseWageSectionProps extends FormBlockSectionProps {}

const BaseWageSection: FunctionComponent<BaseWageSectionProps> = ({
  employmentConditions: ec,
  onChange,
  jobPost
}) => {

  return (
    <FormSection>
      <SectionHeading>基本賃金</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>支給形態</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={ec?.baseWageType === EcWagePaymentType.Monthly}
              value={EcWagePaymentType.Monthly}
              onValueChange={val => onChange?.({ baseWageType: val })}
            >
              月給
            </Radio>
            <Radio
              checked={ec?.baseWageType === EcWagePaymentType.Daily}
              value={EcWagePaymentType.Daily}
              onValueChange={val => onChange?.({ baseWageType: val })}
            >
              日給
            </Radio>
            <Radio
              checked={ec?.baseWageType === EcWagePaymentType.Hourly}
              value={EcWagePaymentType.Hourly}
              onValueChange={val => onChange?.({ baseWageType: val })}
            >
              時給
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>金額</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            value={ec?.baseWageAmount}
            onValueChange={val => onChange?.({ baseWageAmount: val })}
            placeholder="180000"
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>1時間当たりの金額</ItemLabel>
          <NoteInLabel>※月給・日給の場合</NoteInLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            value={ec?.baseWageAmountPerHour}
            onValueChange={val => onChange?.({ baseWageAmountPerHour: val })}
            placeholder="1125"
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>1ヶ月当たりの金額</ItemLabel>
          <NoteInLabel>※日給・時給の場合</NoteInLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            value={ec?.baseWageAmountPerMonth}
            onValueChange={val => onChange?.({ baseWageAmountPerMonth: val })}
            placeholder="184000"
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default BaseWageSection;
