import styled from "styled-components";
import Text from "../../core/components/Text";
import { MOBILE_DEVICE } from "../../core/constants/styles";

export const Section = styled.section`
  width: 100%;
  padding: 0px 0px 30px 0px;
  border-radius: 12px;
  border: 1px solid #c2c2c2;
  background-color: #ffffff;
`;

export const SectionHeadingContainer = styled.div`
  padding: 30px 0 30px 30px;
`;

export const SectionHeading = styled.h3`
  margin: 0;
  font-weight: 500px;
  font-size: 20px;
  line-height: 29px;;
`;

export const SectionRow = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  border-top: 1px solid #c2c2c2;

  @media ${MOBILE_DEVICE} {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    gap: 10px;
  }

  div[data-is-displayed-mobile = "true"] & {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    gap: 10px;
  }
`;

export const SectionLabelCell = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 10px 30px 10px 30px;

  @media ${MOBILE_DEVICE} {
    min-height: unset;
    padding: 0;
  }

  div[data-is-displayed-mobile = "true"] & {
    min-height: unset;
    padding: 0;
  }

`;

export const SectionLabel = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  word-break: break-all;
`;

export const SectionValueCell = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;

  @media ${MOBILE_DEVICE} {
    padding: 0;
  }

  div[data-is-displayed-mobile = "true"] & {
    padding: 0;
  }
`;

export const SectionValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SectionValue = styled(Text)`
  font-size: 14px;
  color: #444444;
  word-break: break-all;
  white-space: pre-wrap;
`;