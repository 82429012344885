import { FunctionComponent, HTMLAttributes, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChatMessageType } from '../types';
import ChatMessage from './ChatMessage';
import Text from '../../core/components/Text';

interface ChatMessagesProps extends HTMLAttributes<HTMLDivElement> {
    messages: ChatMessageType[];
    onClickDelete: (id: number) => void;
}

const Container = styled.div`
  width: 100%;
  max-height: 250px;
  padding-top: 10px;
  overflow-y: auto;
  
  > * {
    margin-top: 10px;
  }
`;

const ChatMessages: FunctionComponent<ChatMessagesProps> = ({
    messages,
    onClickDelete,
}) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Container ref={ref}>
            {messages.map((message) => (
                <ChatMessage 
                    key={message.id} 
                    message={message} 
                    onClickDelete={onClickDelete}
                />
            ))}
            {messages.length < 1 && (
                <Text>{t('proposal.no_message_yet')}</Text>
            )}
        </Container>
    );
};

export default ChatMessages;