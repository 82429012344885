import { FunctionComponent, useEffect, useState } from "react";
import { TemplateSectionProps } from "../../types";
import { useTranslation } from "react-i18next";
import { Section } from "./styles";
import styled from "styled-components";
import { EditableTranslationKind } from "../../../../../job/enums/translation";


interface ContractConclusionSectionProps extends TemplateSectionProps {}

const ConclusionSections = styled.div`
  @media print {
    page-break-after: always;
  }
`;

const SignatureSection = styled(Section)`
  padding: 10px 10px 20px 10px;
  border: 3px solid black;

`;


const ContractConclusionSection: FunctionComponent<ContractConclusionSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const [placeOrMethodToConfirm, setPlaceOrMethodToConfirm] = useState<string>("");
  const [placeOrMethodToConfirmTrans, setPlaceOrMethodToConfirmTrans] = useState<string>("");

  useEffect(() => {
    if (!ec)
      return;

    setPlaceOrMethodToConfirm(
      transHandler.getEditableTranslationInJa(EditableTranslationKind.OtherWorkRegulationsCheckMethod)
    );
    setPlaceOrMethodToConfirmTrans(
      transHandler.getEditableTranslation(EditableTranslationKind.OtherWorkRegulationsCheckMethod)
    );
  }, [ec]);

  return (
    <ConclusionSections>
      <SignatureSection>
        <span>受取人（署名）</span>
        <br />
        <span>{tFixed("recipient_signature")}</span>
      </SignatureSection>
      <Section>
        <span>以上のほかは、当社就業規則による。就業規則を確認できる場所や方法（{placeOrMethodToConfirm}）</span>
        <br />
        <span>{tFixed("company_work_rules_location")} ({placeOrMethodToConfirmTrans})</span>
      </Section>
    </ConclusionSections>
  );};

export default ContractConclusionSection;
