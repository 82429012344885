import { FunctionComponent, HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { LANGUAGE_LABELS } from '../constants/labels';
import { SUPPORTED_LANGUAGE_OPTIONS } from '../options/language'
import { useTranslation } from 'react-i18next';
import LanguageSwitchModal from './LanguageSwitchModal';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

const Text = styled.span`
  font: normal normal normal 14px/24px Hiragino Kaku Gothic ProN;
  color: #2C2C2C;
`

interface LanguageSelectorProps extends HTMLAttributes<HTMLDivElement> {
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
    ...props
}) => {
    const { i18n } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const handleLanguageChange = (language: string) => {
        setIsOpened(false)
        i18n.changeLanguage(language);
        localStorage.setItem('lng', language);
    }

    return (
        <>
          <LanguageSwitchModal 
            open={isOpened} 
            options={SUPPORTED_LANGUAGE_OPTIONS} 
            currentLanguage={i18n.language}
            onLanguageChange={handleLanguageChange}
            onClose={() => setIsOpened(false)} 
          />
          <Container onClick={() => setIsOpened(true)} {...props}>
              <img alt="globe icon" src="/images/icon-globe.svg"/>
              <Text>{LANGUAGE_LABELS[i18n.language]}</Text>
          </Container>
        </>
    )
}

export default LanguageSelector