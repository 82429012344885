import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../../core/components/Page";
import Button from "../../../core/components/Button";
import Column from "../../../core/components/Column";
import Text from "../../../core/components/Text";
import { ReactComponent as BackIcon } from "../../../assets/icon-back.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/icon-forward.svg";
import { ReactComponent as PrintIcon } from '../../../assets/icon-print.svg';
import { ReactComponent as SubmitIcon } from '../../../assets/icon-submit.svg';
import { ReactComponent as BulbIcon } from "../../../assets/icon-lightbulb.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import ACTIONS from "../../../core/constants/actions";
import { getProposal } from "../../apis/proposals";
import { sendEmploymentConditionsEmail, updateProposalEmploymentConditions } from "../../apis/employmentCondition";
import { EmploymentConditionEditableTranslation, EmploymentConditionExtended } from "../../types";
import { Job } from "../../../job/types/api";
import { getJob } from "../../../job/apis/job";
import { getCandidate } from "../../../candidate/apis/candidate";
import EmploymentConditionsForm from "./EmploymentConditionsForm";
import EmailSubmissionModal from "./EmailSubmissionModal";
import EmploymentConditionsTemplate from "../../components/EmploymentConditionsTemplate/EmploymentConditionsTemplate";
import LoadingPrompt from "./components/LoadingPrompt";
import { LanguageCode } from "../../../core/enums/language";
import { useNationalities } from "../../../core/hooks";
import LanguageInputModal from "../../../core/components/MultiLanguageEditorModal";
import { MultiLangInputSettings } from "./types";
import { findItemByKind } from "../../../core/utils";

interface EmploymentConditionsFormPageProps {}

enum ContentToDisplay {
  Form,
  Preview,
  PreviewLoading
}

const LocalPage = styled(Page)`
  padding: 40px 0 0 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const LocalColumn = styled(Column)`
  gap: 10px;
`;

const PageHeaderContainer = styled(Column)`
  width: 100%;
  margin-bottom: 20px;
  gap: 40px;
`;

const InteractionContainer = styled(Column)`
  width: 100%;
  gap: 20px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;

const SaveButton = styled(Button).attrs({
  variant: "secondary",
})`
  min-width: 160px;
  height: 40px;
`;

const PreviewButton = styled(Button)`
  gap: 10px;
`;

const PageHeading = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #444444;
`;

const AnnoucementContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  gap: 20px;
  border: 1px solid #d5d5d5;
  padding: 15px;
  background-color: white;
`;

const AnnoucementTextContainer = styled(Column)`
  gap: 10px;
`;

const PrintButton = styled(Button).attrs({
  variant: 'secondary'
  })`
  width: 120px;
  font-weight: bold;
  gap: 10px;

  &:hover {
    path {
      fill: #444444; 
    }
  }
`;

const SubmitButton = styled(Button)`
  width: 200px;
  gap: 10px;
`;

const NoteOnForm = styled(Text)`
  color: #999999;
`;

const EmploymentConditionsFormPage: FunctionComponent<
  EmploymentConditionsFormPageProps
> = () => {
  const { state } = useReducerContext();
  const { proposalId } = useParams();
  const { dispatch } = useReducerContext();
  const { getSupportedLangCodeByNationalityId } = useNationalities();
  const navigate = useNavigate();
  const defaultLangCode = LanguageCode.English;
  const company = state.company;
  const [contentToDisplay, setContentToDisplay] = useState<ContentToDisplay>(ContentToDisplay.Form);
  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateLangCode, setCandidateLangCode] = useState<LanguageCode>(defaultLangCode);
  const [employmentCondition, setEmploymentCondition] = useState<EmploymentConditionExtended>();
  const [jobPost, setJobPost] = useState<Job>();
  const [emailSubmissionModalOpen, setEmailSubmissionModalOpen] = useState(false);
  const [MultilangInputModalOpen, setMultiLangInputModalOpen] = useState(false);
  const [languageInputModalSettings, setLanguageInputModalSettings] = useState<MultiLangInputSettings>();

  const onClickBack = () => { 
    switch (contentToDisplay) {
      case ContentToDisplay.Form:
        navigate(-1);
        break;
      case ContentToDisplay.Preview:
        setContentToDisplay(ContentToDisplay.Form);
        break;
      default:
        break;
    }
  }
  const onChange = (val: Partial<EmploymentConditionExtended>) => {
    setEmploymentCondition((prevCondition) => {
      return prevCondition
        ? {
            ...prevCondition,
            ...val,
          }
        : prevCondition;
    });
  };

  const onClickSave = async () => {
    if (!employmentCondition) return;

    try {
      dispatch({ type: ACTIONS.START_LOADING });
      const updatedEc = await updateProposalEmploymentConditions(
        employmentCondition.id,
        employmentCondition
      );

      setEmploymentCondition(updatedEc);

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          message: "保存しました",
        },
      });
    } catch {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: "保存に失敗しました",
        },
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING });
    }
  };

  const onClickPreview = () => {
    if (!employmentCondition) return;

    setContentToDisplay(ContentToDisplay.PreviewLoading);

    (async () => {
      try {
        const updatedEc = await updateProposalEmploymentConditions(
          employmentCondition.id,
          employmentCondition
        );
  
        setEmploymentCondition(updatedEc);
        setContentToDisplay(ContentToDisplay.Preview);
  
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
            type: "success",
            message: "保存しました",
          },
        });
      } catch {
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
            type: "warning",
            message: "保存に失敗しました",
          },
        });

        setContentToDisplay(ContentToDisplay.Form);
      } 
    })();
  }

  const onClickSendEmail = async () => {
    if (!employmentCondition) return;

    try {
      dispatch({ type: ACTIONS.START_LOADING });

      const updatedEc = await updateProposalEmploymentConditions(
        employmentCondition.id,
        employmentCondition
      );

      await sendEmploymentConditionsEmail(employmentCondition.id);
      
      setEmploymentCondition(updatedEc);

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          message: "メールを送信しました",
        },
      });
    } catch {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: "メール送信に失敗しました",
        },
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING });
      setEmailSubmissionModalOpen(false);
    }
  }

  const onClickLangInputButton = (settings: MultiLangInputSettings) => {
    setLanguageInputModalSettings(settings);
    setMultiLangInputModalOpen(true);
  }

  const setEcEditableTranslation = (translation: Partial<EmploymentConditionEditableTranslation>) => {
    setEmploymentCondition((prev) => {
      if (!prev) 
        return prev;

      return {
        ...prev,
        editableTranslations: prev.editableTranslations.map(et => {
          if (et.kind === translation.kind) {
            return {
              ...et,
              ...translation,
            };
          }
  
          return et;
        })
      }
    });
  }

  useEffect(() => {
    if (!proposalId) {
      navigate("/applications");
      return;
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return "";
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    (async () => {
      try {
        dispatch({ type: ACTIONS.START_LOADING });
        const proposal = await getProposal(proposalId);
        const job = await getJob(proposal.jobPostId);
        const candidate = await getCandidate(proposal.candidateId);
        const ec = proposal.employmentCondition;
        
        const lastName = ec?.employeeEnFamilyName ?? candidate.enLastName ?? "";
        const firstName = ec?.employeeEnGivenName ?? candidate.enFirstName ?? "";
        const middleName = ec?.employeeEnMiddleName ?? candidate.enMiddleName ?? "";
        const candFullName = `${lastName} ${firstName} ${middleName}`.trim();

        setCandidateName(candFullName);
        setEmploymentCondition(ec);
        setJobPost(job);
        setCandidateLangCode(
          getSupportedLangCodeByNationalityId(candidate.nationalityId) as LanguageCode 
          || defaultLangCode 
        );

        if (ec)
          setEmploymentCondition(ec);

      } catch {
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
      }
    })();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <LocalPage>
      <ContentWrapper>
        <PageHeaderContainer>
          <InteractionContainer>
            <LocalColumn>
              <BackIcon 
                onClick={onClickBack}
                style={{ cursor: 'pointer' }}
              />
              { candidateName && (
                <PageHeading>{candidateName}さんの雇用条件書</PageHeading>
              )}
            </LocalColumn>
            <ActionButtonsContainer>
              { contentToDisplay === ContentToDisplay.Preview &&
                <>
                  <PrintButton onClick={() => window.print()}>
                    <PrintIcon/>
                    <span>印刷</span>
                  </PrintButton> 
                  <SubmitButton onClick={() => setEmailSubmissionModalOpen(true)}>
                    <SubmitIcon/>
                    <span>メール送信</span>
                  </SubmitButton>
                </>
              }
              
              { contentToDisplay === ContentToDisplay.Form && (
                <>
                  <SaveButton onClick={onClickSave}>保存</SaveButton>
                  <PreviewButton onClick={onClickPreview}>
                    プレビューへ進む
                    <ForwardIcon />
                  </PreviewButton>
                </>
              )}
            </ActionButtonsContainer>
          </InteractionContainer>
          
          { contentToDisplay === ContentToDisplay.Preview &&
            <AnnoucementContainer>
              <BulbIcon />
              <AnnoucementTextContainer>
                  <LocalColumn>
                    <Text>内容を確認し、「メール送信」ボタンで候補者のメールアドレスへ閲覧画面のリンクを送付してください</Text>
                    <NoteOnForm>※送信後に内容を編集した場合、編集内容は候補者の画面に反映されます</NoteOnForm>
                  </LocalColumn>
              </AnnoucementTextContainer>
            </AnnoucementContainer>
          }

          { contentToDisplay === ContentToDisplay.Form && 
            <AnnoucementContainer>
              <BulbIcon />
              <AnnoucementTextContainer>
                <Text>
                  必要な項目を入力し、プレビュー画面へ進んで内容を確認してください。
                </Text>
                <Text>
                  プレビュー画面にて候補者へのメール送信が行えます。
                </Text>
                <Text style={{ color: "#999999" }}>
                  ※入力途中でもプレビュー画面へ進んで確認し、こちらの編集画面へ戻ることができます
                </Text>
              </AnnoucementTextContainer>
            </AnnoucementContainer>
          }
        
        </PageHeaderContainer>

        {contentToDisplay === ContentToDisplay.PreviewLoading && <LoadingPrompt />}

        {contentToDisplay === ContentToDisplay.Preview &&
          <EmploymentConditionsTemplate 
            employmentConditions={employmentCondition}
            transLanguage={candidateLangCode}
          />
        }

        {contentToDisplay === ContentToDisplay.Form &&
          <>
            <EmploymentConditionsForm
              employmentCondition={employmentCondition}
              onChange={onChange}
              jobPost={jobPost}
              company={company}
              onClickLangInputButton={onClickLangInputButton}
              
            />

            <ActionButtonsContainer>
              <SaveButton onClick={onClickSave}>保存</SaveButton>
              <PreviewButton onClick={onClickPreview}>
                プレビューへ進む
                <ForwardIcon />
              </PreviewButton>
            </ActionButtonsContainer>
          </>
        }

      </ContentWrapper>

      <EmailSubmissionModal 
        open={emailSubmissionModalOpen}
        onSend={onClickSendEmail}
        onClose={() => setEmailSubmissionModalOpen(false)}
      />

      <LanguageInputModal
        open={MultilangInputModalOpen}
        translation={findItemByKind(
          employmentCondition?.editableTranslations, 
          languageInputModalSettings?.kind
        )}
        heading={languageInputModalSettings?.heading}
        setTranslation={setEcEditableTranslation}
        onCancel={() => setMultiLangInputModalOpen(false)}
        onClose={() => setMultiLangInputModalOpen(false)}
        onSave={() => setMultiLangInputModalOpen(false)}
      />
    </LocalPage>
  );
};

export default EmploymentConditionsFormPage;
