import {
    FunctionComponent,
    HTMLAttributes,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import styled from 'styled-components';

import InterviewParagraph from './InterviewParagraph';
import ResultParagraph from './ResultParagraph';
import {
    cancelInterview, completeInterview,
    createInterview,
    getMeetingInformation,
    getProposal,
    selectInterviewSlot,
    updateInterviewSlots,
    updateProposalState,
} from '../../apis/proposals';
import StepLabel from '../../components/StepLabel';
import StepContent from '../../components/StepContent';
import {
    EMPLOYER_INTERVIEW_CANCELLATION_REASON_OPTIONS,
    INTERVIEW_DECLINE_REASON_OPTIONS,
    PARTNER_INTERVIEW_CANCELLATION_REASON_OPTIONS, PROPOSAL_CANCELLATION_REASON_OPTIONS,
} from '../../constants';
import CandidateOverview from '../../../candidate/components/CandidateOverview';
import JobOverview from '../../../job/components/JobOverview';
import ActionBar from '../../../core/components/ActionBar';
import Button from '../../../core/components/Button';
import Card from '../../../core/components/Card';
import Column from '../../../core/components/Column';
import MenuItem from '../../../core/components/MenuItem';
import Menu from '../../../core/components/Menu';
import Message from '../../../core/components/Message';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Job } from '../../../job/types/api';
import { Candidate } from '../../../candidate/types/api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getJob } from '../../../job/apis/job';
import { getCandidate } from '../../../candidate/apis/candidate';
import { ChatMessageType, Interview, Proposal } from '../../types';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { decodeHTMLEntities } from '../../../core/utils';
import { getSalary } from '../../../core/utils';
import InformationSharingSection from './InformationSharingSection/InformationSharingSection';
import ContactInformationSection from './ContactInformationSection';
import ACTIONS from '../../../core/constants/actions';
import ChatSection from '../../components/ChatSection';
import CancelInterviewModal from '../../components/CancelInterviewModal';
import WithdrawProposalModal from '../../components/WithdrawProposalModal';
import RescheduleInterviewModal from '../../components/RescheduleInterviewModal';
import EditAvailableDatesModal from '../../components/EditAvailableDatesModal';
import DeclineProposalModal from "../../components/DeclineProposalModal";
import HiringConfirmationModal from './HiringConfirmationModal';
import { deleteChatMessage, getChatMessages, sendChatMessage } from '../../apis/chat';
import { deleteSharedDocuments, sendSharedDocuments, uploadSharedDocuments } from '../../apis/documents';
import { getCurrentUser } from '../../../core/apis/auth';
import { useJobLocation, useJobPostCategory, useNationalities } from '../../../core/hooks';

interface PartnerProposalPageProps extends HTMLAttributes<HTMLDivElement> {
}

const LocalPage = styled(Page)`
  > * {
    width: unset;
  }
  
  @media ${MOBILE_DEVICE} {
    padding-top: 20px;
    
    > * {
      width: 100%;
    }
  }
`;

const LocalChatSection = styled(ChatSection)`
  width: 730px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  };
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  };
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  > *:not(:first-child) {
    margin-top: 20px;
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  };
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  };
`;

const InterviewSection = styled(Section)`
  width: 730px;
  margin-top: 10px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  };
`;

const ProposalStatus = styled(Message)`
  margin-bottom: 20px;
  background: #FFDFD1;
  text-align: center;
`;

const TitleRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const TitleButton = styled(Button)`
  @media ${MOBILE_DEVICE} {
    width: unset;
  }
`;

const LocalCard = styled(Card)`
  margin-top: 10px;
  cursor: pointer;

  @media ${MOBILE_DEVICE} {
    margin-top: 5px;
  }
`;

const ProposalDetailsPage: FunctionComponent<PartnerProposalPageProps> = ({
    ...props
}) => {
    const { state, dispatch } = useReducerContext();
    const jobPostCategory = useJobPostCategory();
    const { getJobLocation } = useJobLocation();
    const { getNationalityName } = useNationalities();
    const [proposal, setProposal] = useState<Proposal|undefined>();
    const [job, setJob] = useState<Job|undefined>();
    const [candidate, setCandidate] = useState<Candidate|undefined>();
    const [messages, setMessages] = useState<ChatMessageType[]>([]);
    const [activeInterviewIndex, setActiveInterviewIndex] = useState(-1);
    const [activeInterview, setActiveInterview] = useState<Interview|undefined>();
    const [activeInterviewDate, setActiveInterviewDate] = useState<Date|undefined>();
    const [interviewSlotId, setInterviewSlotId] = useState(-1);
    const [isRescheduling, setIsRescheduling] = useState(false)
    const [suggestedInterviewTimes, setSuggestedInterviewTimes] = useState<Date[]>([]);
    const [skipped, setSkipped] = useState(false);

    const [reason, setReason] = useState<string|undefined>();
    const [comment, setComment] = useState<string|undefined>();
    const [meetingLink, setMeetingLink] = useState<string|undefined>();
    const [meetingId, setMeetingId] = useState<string|undefined>();
    const [meetingPassword, setMeetingPassword] = useState<string|undefined>();
    const [today, setToday] = useState(moment().subtract(1, 'hour').toDate())
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
    const [withdrawModalAnimated, setWithdrawModalAnimated] = useState(false);
    const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
    const [hiringConfirmationModalOpen, setHiringConfirmationModalOpen] = useState(false);
    const [declineModalOpen, setDeclineModalOpen] = useState(false);
    const [declineModalAnimated, setDeclineModalAnimated] = useState(false);
    const [editTimeModalOpen, setEditTimeModalOpen] = useState(false);
    const { proposalId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isAutoSendOn = !!state.company?.autoSendEmploymentOffer;
    const isEmployerAddressFullyAvailable = (
        !!state.company?.jaPrefecture &&
        !!state.company?.jaTown &&
        !!state.company?.jaCityWard &&
        !!state.company?.jaAddressNumber
    );

    const actionable = /^[a-z]*_interview$/.test(proposal?.state ?? '')
        && ((
            (state.isEmployer
                    ? !activeInterviewDate || activeInterviewDate.getTime() < today.getTime()
                    : !activeInterviewDate
            )
            && (
                (state.isEmployer && activeInterview?.slotsSetBy !== 'employer')
                || (state.isPartner && activeInterview?.slotsSetBy !== 'partner')
                || (state.isCandidate && activeInterview?.slotsSetBy !== 'candidate')
            )
        ) || !!activeInterview?.canceledAt || !!activeInterview?.manuallyCompletedAt);
    const effectiveInterviewCount = proposal?.interviews
        .filter((interview, index) => !interview.canceledAt || index === activeInterviewIndex)
        .length ?? 0;
    const cancelled = proposal?.state === 'canceled';
    const declined = proposal?.state === 'declined';
    const completed = effectiveInterviewCount > 2;
    const schedulingNewInterview = state.isEmployer
        && !cancelled
        && proposal?.state !== 'declined'
        && !skipped
        && ((activeInterviewDate && activeInterviewDate.getTime() < today.getTime()) || !!activeInterview?.manuallyCompletedAt)
        && !activeInterview?.canceledAt
        && !completed;

    const handleUpdateSlots = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_interview_slots'),
            },
        });
        try {
            if (activeInterview && !activeInterview.canceledAt && !activeInterview.manuallyCompletedAt) {
                const interview = await updateInterviewSlots(activeInterview.id, suggestedInterviewTimes, i18n.language);
                setProposal({
                    ...proposal!,
                    interviews: [
                        ...proposal!.interviews.filter((item, index) => index < proposal!.interviews.length - 1),
                        interview,
                    ],
                });
                setActiveInterview(interview);
            } else {
                const interview = await createInterview(proposalId!, suggestedInterviewTimes, i18n.language);
                if (!activeInterview?.canceledAt) {
                    await updateProposalState(proposalId!, effectiveInterviewCount < 2
                        ? 'second_interview'
                        : 'third_interview'
                    );
                }
                setProposal({
                    ...proposal!,
                    interviews: [
                        ...proposal!.interviews,
                        interview,
                    ],
                });
                setActiveInterviewIndex(activeInterviewIndex + 1)
                setActiveInterview(interview);
                setActiveInterviewDate(undefined);
            }
            setIsRescheduling(false);
            setSuggestedInterviewTimes([]);
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t('core.submitted'),
                },
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleSelectSlot = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.selecting_interview_slot'),
            },
        });
        try {
            const interview = await selectInterviewSlot(activeInterview!.id, interviewSlotId);
            setProposal({
                ...proposal!,
                interviews: [
                    ...proposal!.interviews.filter((item, index) => index < proposal!.interviews.length - 1),
                    interview,
                ],
            });
            const activeInterviewSlot = interview
                ?.slots
                .find((slot) => slot.id === interviewSlotId);
            const activeInterviewDate = activeInterviewSlot
                ? new Date(activeInterviewSlot.start)
                : undefined;
            setActiveInterview(interview);
            setActiveInterviewDate(activeInterviewDate);
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleInterviewEdit = () => {
        if (activeInterview) {
            const now = moment().toDate();
            const dates = activeInterview.slots
              .map((slot) => new Date(slot.start))
              .filter((date) => date.getTime() > now.getTime());
            setSuggestedInterviewTimes(dates);
            setEditTimeModalOpen(true);
        }
    };

    const handleInterviewComplete = async () => {
        if (activeInterview) {
            dispatch({
                type: ACTIONS.START_LOADING,
            });
            try {
                const interview = await completeInterview(activeInterview.id);
                setProposal({
                    ...proposal!,
                    interviews: [
                        ...proposal!.interviews.slice(0, -1),
                        interview,
                    ],
                });
                setActiveInterview(interview);
            } catch (e) {}
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        }
    };

    const handleMessageSubmit = async (message: string) => {
        dispatch({
            type: ACTIONS.START_LOADING,
        });
        try {
            const response = await sendChatMessage(proposalId!, message);
            setMessages([
                ...messages,
                response,
            ]);
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleMessageDelete = async (id: number) => {
        dispatch({
            type: ACTIONS.START_LOADING,
        });
        try {
            await deleteChatMessage(id);
            setMessages(messages.filter((message) => message.id !== id));
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    }

    const handleDeclineSubmit = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_proposal_status'),
            },
        });
        try {
            await updateProposalState(proposalId!, 'declined', reason, comment);
            setProposal({
                ...proposal!,
                state: 'declined',
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
        setDeclineModalOpen(false);
    };

    const handleCancelSubmit = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_proposal_status'),
            },
        });
        try {
            const interview = await cancelInterview(activeInterview!.id, reason!, comment!);
            setProposal({
                ...proposal!,
                interviews: [
                    ...proposal!.interviews.slice(0, -1),
                    interview,
                ],
            });
            setActiveInterview(interview);
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t('core.submitted'),
                },
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
        setCancelModalOpen(false);
        setRescheduleModalOpen(true);
    };

    const handleWithdrawSubmit = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_proposal_status'),
            },
        });
        try {
            await updateProposalState(proposalId!, 'canceled', reason, comment);
            setProposal({
                ...proposal!,
                state: 'canceled',
                cancelReason: reason,
                cancelComment: comment,
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
        setWithdrawModalOpen(false);
    };

    const handleHireClick = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_proposal_status'),
            },
        });
        try {
            await updateProposalState(proposalId!, 'result');
            const updatedProposal = await updateProposalState(proposalId!, 'hired');
            setProposal({
                ...updatedProposal,
                state: 'hired',
            });
            setHiringConfirmationModalOpen(false);

            const canAutoSendEmploymentOffer = (
                isAutoSendOn &&
                isEmployerAddressFullyAvailable
            );

            if (canAutoSendEmploymentOffer) {
                dispatch({
                    type: ACTIONS.SET_PROMPT,
                    payload: {
                        type: 'success',
                        message: "採用結果を先方へ送信しました",
                        timeout: 3
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: ACTIONS.SET_PROMPT,
                        payload: {
                            type: 'success',
                            message: "内容証明書を送付しました",
                            timeout: 3
                        },
                    }); 
                }, 3100);
            }
            else {
                dispatch({
                    type: ACTIONS.SET_PROMPT,
                    payload: {
                        type: 'success',
                        message: "採用結果を先方へ送信しました",
                    },
                });
            }
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleRejectClick = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.updating_proposal_status'),
            },
        });
        try {
            await updateProposalState(proposalId!, 'result');
            await updateProposalState(proposalId!, 'rejected');
            setProposal({
                ...proposal!,
                state: 'rejected',
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleDocumentUpload = async (kind: string, file: File) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Uploading document...',
            },
        });
        try {
            const document = await uploadSharedDocuments(proposalId!, kind, file);
            setProposal({
                ...proposal!,
                employerFiles: [
                    ...proposal!.employerFiles,
                    document,
                ],
            })
        } catch (e) {
            // error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleDocumentDelete = async (id: number) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Deleting document...',
            },
        });
        try {
            await deleteSharedDocuments(id);
            setProposal({
                ...proposal!,
                employerFiles: proposal!.employerFiles.filter((file) => file.id !== id),
            })
        } catch (e) {
            // error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleDocumentsSend = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Sending documents...',
            },
        });
        try {
            await sendSharedDocuments(proposalId!);
        } catch (e) {
            // error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
        dispatch({
            type: ACTIONS.SET_PROMPT,
            payload: {
                type: 'success',
                message: t('proposal.documents_sent'),
            },
        });
    };

    const handleJobDetailsClick = () => {
        if (job) {
            window.open(`/jobs/${job.id}`, '_blank');
        }
    };

    const handleCandidateDetailsClick = () => {
        if (state.isCandidate) {
            window.open('/profile');
        } else if (candidate) {
            window.open(`/candidates/${candidate.id}`, '_blank');
        }
    };

    const handleHiringConfModalOpen = async () => {
        try {
            dispatch({ type: ACTIONS.START_LOADING });

            //Since the current design assumes to update some missing data in a separate tab,
            //it's necessary to fetch the latest data from the server before showing the modal.
            const user = await getCurrentUser();
            const company = user.company!;
            const proposal = await getProposal(proposalId!);
            setProposal(proposal);
            dispatch({
                type: ACTIONS.UPDATE_COMPANY,
                payload: { company }
            });
            
            setHiringConfirmationModalOpen(true);
        } catch {
        } finally {
            dispatch({ type: ACTIONS.STOP_LOADING });
        }

    }

    useEffect(() => {
        (async () => {
            try {
                dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('proposal.fetching_proposal_details'),
                    },
                })
                const proposal = await getProposal(proposalId!);
                if (state.isEmployer && proposal.state === 'screen') {
                    navigate(`/applications/${proposalId}/review`);
                }
                setProposal(proposal);
                const job = await getJob(proposal.jobPostId);
                setJob(job)
                const candidate = await getCandidate(proposal.candidateId);
                setCandidate(candidate)
                const chatMessages = await getChatMessages(proposalId!);
                setMessages(chatMessages);
                const activeInterviewIndex = (proposal.state === 'screen'
                    ? -1
                    : (proposal?.interviews.length > 0 ? proposal.interviews.length - 1 : 0)
                );
                const activeInterview = proposal.interviews[activeInterviewIndex]
                const activeInterviewSlot = activeInterview
                    ?.slots
                    .find((slot) => slot.id === activeInterview?.selectedSlotId);
                const activeInterviewDate = activeInterviewSlot
                    ? new Date(activeInterviewSlot.start)
                    : undefined;
                if (activeInterviewDate && activeInterviewDate.getTime() < today.getTime() && !activeInterview.canceledAt) {
                    setActiveInterviewIndex(activeInterviewIndex + 1);
                    setActiveInterview(undefined);
                    setActiveInterviewDate(activeInterviewDate);
                    setIsRescheduling(true);
                } else {
                    setActiveInterviewIndex(activeInterviewIndex);
                    setActiveInterview(activeInterview);
                    setActiveInterviewDate(activeInterviewDate);
                    setIsRescheduling(false);
                }
                setReason(proposal.state === 'declined'
                    ? proposal.declineReason
                    : (proposal.state === 'canceled' ? proposal.cancelReason : undefined)
                );
                setComment(proposal.state === 'declined'
                    ? (proposal.declineComment ?? undefined)
                    : (proposal.state === 'canceled'
                        ? (proposal.cancelComment ?? undefined)
                        : undefined
                    )
                );
                const meetingInformation = await getMeetingInformation(proposalId!);
                setMeetingLink(meetingInformation.meetingInvitationLink);
                setMeetingId(meetingInformation.meetingId);
                setMeetingPassword(meetingInformation.meetingPassword);
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        })()
    }, []);

    return (
        <LocalPage>
            <Container>
                <LeftContainer>
                    <TitleRow center>
                        <PageHeading>{t('proposal.proposal')}</PageHeading>

                        {/* Menu while the candidate's application is in progress */}
                        {(!cancelled && proposal?.state !== 'declined' && proposal?.state !== 'hired' && proposal?.state !== 'rejected') && (
                            <Menu style={{ marginLeft: '20px' }} title={t('core.other_actions')}>
                                {!state.isEmployer && (
                                    <MenuItem onClick={() => setWithdrawModalOpen(true)}>
                                        {t('proposal.withdraw_proposal')}
                                    </MenuItem>
                                )}
                                {(state.isEmployer && ((activeInterviewDate && activeInterviewDate.getTime() < today.getTime()) || !!activeInterview?.manuallyCompletedAt) && !skipped && !completed) && (
                                    <MenuItem onClick={() => setSkipped(true)}>
                                        {t('proposal.skip_next_interview')}
                                    </MenuItem>
                                )}
                                {state.isEmployer && (
                                    <MenuItem onClick={() => setDeclineModalOpen(true)}>
                                        {t('proposal.decline_proposal')}
                                    </MenuItem>
                                )}
                            </Menu>
                        )}
                    </TitleRow>

                    {/* Show the interview history (if not hired) */}
                    {(proposal?.state !== 'hired') && (
                        <InterviewSection>
                            {proposal?.state === 'canceled' && (
                                <>
                                    <ProposalStatus>
                                        {state.isEmployer
                                            ? t('proposal.partner_or_candidate_has_withdrawn_proposal')
                                            : t('proposal.you_have_withdrawn_proposal')
                                        }
                                    </ProposalStatus>
                                    <Text style={{ marginBottom: '5px' }}>
                                        {t('proposal.reason_for_withdraw')}
                                    </Text>
                                    <Message style={{ marginBottom: '46px'}}>
                                        <Text style={{ marginBottom: '10px' }}>
                                            {t(`proposal.reason_${proposal.cancelReason}`)}
                                        </Text>
                                        <Text>
                                            {proposal.cancelComment}
                                        </Text>
                                    </Message>
                                </>
                            )}
                            <Column style={{ width: '100%' }}>
                                <StepLabel
                                    completed={!cancelled}
                                    number={1}
                                    comment={proposal?.createdAt
                                        ? moment(proposal.createdAt)
                                        .utcOffset('+09:00')
                                        .format('YYYY/MM/DD HH:mm') + ' JST'
                                        : undefined
                                    }
                                >
                                    { state.isCandidate? t('proposal.apply') : t('proposal.propose')}
                                </StepLabel>
                                <StepContent active={!cancelled}>
                                    {
                                        !!proposal?.scoutId && (state.isPartner || state.isCandidate) &&
                                        <Text>{t(`scout.${state.isCandidate? 'applied_from_scout_label' : 'proposed_from_scout_label'}`)}</Text>
                                    }
                                </StepContent>
                                <StepLabel
                                    active={proposal?.state === 'screen'}
                                    completed={proposal?.state !== 'screen' && !cancelled}
                                    number={2}
                                >
                                    {t('proposal.status_screen')}
                                </StepLabel>
                                <StepContent active={proposal?.state !== 'screen' && !cancelled}>
                                    {
                                        !!proposal?.scoutId && state.isEmployer &&
                                        <Text>{t('scout.applied_via_scout_label')}</Text>
                                    }
                                    {(proposal?.state === 'declined' && !proposal?.interviews.some(
                                            (interview) => !!interview.selectedSlotId)
                                    ) && (
                                        <>
                                            <Message>{t('proposal.status_declined')}</Message>
                                            <Text style={{ marginTop: '24px' }}>
                                                {t('proposal.reason_for_decline')}
                                            </Text>
                                            <Message style={{ marginTop: '10px' }}>
                                                <Text>
                                                    {t(`proposal.decline_proposal_reason.${reason}`)}
                                                </Text>
                                                {!!comment && (
                                                    <Text style={{ marginTop: '8px' }}>{comment}</Text>
                                                )}
                                            </Message>
                                        </>
                                    )}
                                </StepContent>
                                {proposal?.interviews
                                    .filter((interview, index) => index < activeInterviewIndex
                                        || proposal.state !== 'declined'
                                        || !!interview.selectedSlotId
                                    )
                                    .map((interview, index) => {
                                        if (interview.canceledAt && index < activeInterviewIndex) {
                                            return null;
                                        }

                                        const interviewed = proposal.interviews
                                            .filter((interview, i) => i < index && !interview.canceledAt)
                                            .length;
                                        return (
                                            <InterviewParagraph
                                                key={interview.id}
                                                active={index === activeInterviewIndex && !cancelled && !declined && !activeInterview?.manuallyCompletedAt}
                                                completed={(index < activeInterviewIndex && !cancelled) || declined || !!activeInterview?.manuallyCompletedAt}
                                                scheduling={isRescheduling}
                                                number={interviewed + 3}
                                                label={t(`proposal.status_${interviewed === 0 ? 'first' : (interviewed === 1 ? 'second' : 'third')}_interview`)}
                                                interview={interview}
                                                state={index === proposal.interviews.length - 1
                                                    ? proposal?.state
                                                    : undefined
                                                }
                                                schedulingDates={suggestedInterviewTimes}
                                                meetingLink={meetingLink}
                                                meetingId={meetingId}
                                                meetingPassword={meetingPassword}
                                                reason={reason}
                                                comment={comment}
                                                onSchedulingDatesChange={setSuggestedInterviewTimes}
                                                onInterviewSlotSelect={setInterviewSlotId}
                                                onInterviewCancel={() => setCancelModalOpen(true)}
                                                onInterviewEdit={handleInterviewEdit}
                                                onInterviewComplete={handleInterviewComplete}
                                            />
                                        );
                                    })
                                }
                                {!(proposal?.interviews && proposal.interviews.length) && (
                                    <InterviewParagraph
                                        active={activeInterviewIndex > -1}
                                        completed={false}
                                        scheduling={isRescheduling}
                                        number={3}
                                        label={t('proposal.status_first_interview')}
                                        state={proposal?.state}
                                        schedulingDates={suggestedInterviewTimes}
                                        onSchedulingDatesChange={setSuggestedInterviewTimes}
                                        onInterviewSlotSelect={setInterviewSlotId}
                                    />
                                )}
                                {schedulingNewInterview && (
                                    <InterviewParagraph
                                        active
                                        scheduling
                                        completed={false}
                                        number={effectiveInterviewCount + 3}
                                        label={t(`proposal.status_${effectiveInterviewCount === 1 ? 'second' : 'third'}_interview`)}
                                        state={proposal?.state}
                                        schedulingDates={suggestedInterviewTimes}
                                        onSchedulingDatesChange={setSuggestedInterviewTimes}
                                        onInterviewSlotSelect={setInterviewSlotId}
                                    />
                                )}
                                <ResultParagraph
                                    active={activeInterviewDate
                                        && !activeInterview
                                        && !cancelled
                                        && (!state.isEmployer || completed || skipped)
                                    }
                                    number={effectiveInterviewCount
                                        + (proposal?.state === 'rejected' ? 2 : 3)
                                        + (schedulingNewInterview ? 1 : 0)
                                    }
                                    comment={proposal?.state === 'rejected'
                                        ? t('proposal.completed_at')
                                        + moment(proposal.updatedAt)
                                            .utcOffset('+09:00')
                                            .format('MMMM Do, YYYY H:mm')
                                        + ' JST'
                                        : undefined
                                    }
                                    state={proposal?.state}
                                />
                            </Column>
                        </InterviewSection>
                    )}

                    {/* Content after the candidate is hired */}
                    {(proposal && candidate && proposal.state === 'hired') && (state.isEmployer
                        ? (
                            <InformationSharingSection
                                proposal={proposal}
                                documents={proposal.employerFiles}
                                candidate={candidate}
                                employmentOfferSentDate={proposal.employmentOfferSentAt}
                                onDocumentUpload={handleDocumentUpload}
                                onDocumentDelete={handleDocumentDelete}
                                onDocumentsSend={handleDocumentsSend}
                            />
                        )
                        : (
                            <ContactInformationSection candidate={candidate} />
                        )
                    )}

                    <LocalChatSection
                        messages={messages}
                        onClickSubmit={handleMessageSubmit}
                        onClickDelete={handleMessageDelete}
                    />
                </LeftContainer>


                <RightContainer>
                    {/* Job post overview */}
                    <TitleRow center style={{ justifyContent: 'space-between', width: '100%' }}>
                        <SectionHeading>
                            {t('proposal.job')}
                        </SectionHeading>
                        <TitleButton variant="quart" onClick={handleJobDetailsClick}>
                            {t('core.details')}
                        </TitleButton>
                    </TitleRow>
                    <LocalCard onClick={handleJobDetailsClick}>
                        {job && (
                            <JobOverview
                                minimized
                                thumbnail={job.images.length
                                    ? job.images[0].url
                                    : `/images/job-type-thumbnails/${job.jobTypeId}.webp`
                                }
                                title={
                                    decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.name)
                                    ?? job.name
                                }
                                location={getJobLocation(job)}
                                industry={jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId) }
                                job={jobPostCategory.getJobTypeName(job.jobTypeId)}
                                salaryType={job.salaryType}
                                salary={getSalary(job)}
                            />
                        )}
                    </LocalCard>

                    {/* Candidate overview */}
                    <TitleRow center style={{ justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                        <SectionHeading>
                            {t('proposal.candidate')}
                        </SectionHeading>
                        <TitleButton variant="quart" onClick={handleCandidateDetailsClick}>
                            {t('core.details')}
                        </TitleButton>
                    </TitleRow>
                    <LocalCard onClick={handleCandidateDetailsClick}>
                        {candidate && (
                            <CandidateOverview
                                minimized
                                avatar={candidate.image?.url}
                                name={`${candidate.enFirstName} ${candidate.enLastName}`}
                                age={candidate.age}
                                gender={candidate.gender}
                                nationality={getNationalityName(candidate.nationalityId)}
                                japaneseLevel={candidate.jlptLevel}
                                visa={candidate.visaStatus}
                                nearestPossibleDate={candidate.nearestPossibleDate}
                                additionalInformation={candidate.additionalInformations}
                            />
                        )}
                    </LocalCard>
                </RightContainer>
            </Container>

            

            {/* Action buttons in a bar */}
            {actionable && (
                <ActionBar>
                    {state.isEmployer && (completed || skipped)
                        ? (
                            <>
                                <Button onClick={handleHiringConfModalOpen}>
                                    {t('proposal.hire')}
                                </Button>
                                <Button variant="secondary" onClick={handleRejectClick}>
                                    {t('proposal.reject')}
                                </Button>
                            </>
                        )
                        : (isRescheduling || schedulingNewInterview
                            ? (
                                <Button
                                    disabled={suggestedInterviewTimes.length < 1 || suggestedInterviewTimes.length > 20}
                                    onClick={handleUpdateSlots}
                                >
                                    {t('proposal.send')}
                                </Button>
                            )
                            : (
                                <>
                                    {!activeInterview?.canceledAt && (
                                        <Button
                                            disabled={interviewSlotId === -1}
                                            onClick={handleSelectSlot}
                                        >
                                            {t('proposal.confirm')}
                                        </Button>
                                    )}
                                    <Button
                                        variant="secondary"
                                        onClick={() => setIsRescheduling(true)}
                                    >
                                        {t('proposal.suggest_other_dates')}
                                    </Button>
                                </>
                            )
                        )
                    }
                </ActionBar>
            )}


            {/* All related modals */}
            <CancelInterviewModal
                open={cancelModalOpen}
                options={state.isEmployer
                    ? EMPLOYER_INTERVIEW_CANCELLATION_REASON_OPTIONS
                    : PARTNER_INTERVIEW_CANCELLATION_REASON_OPTIONS
                }
                reason={reason}
                comment={comment}
                onReasonChange={setReason}
                onCommentChange={setComment}
                onWithdraw={() => {
                  setCancelModalOpen(false);
                  setWithdrawModalOpen(true);
                  setWithdrawModalAnimated(true);
                }}
                onDecline={() => {
                  setCancelModalOpen(false);
                  setDeclineModalOpen(true);
                  setDeclineModalAnimated(true);
                }}
                onCancel={() => setCancelModalOpen(false)}
                onConfirm={handleCancelSubmit}
            />
            <WithdrawProposalModal
                open={withdrawModalOpen}
                animated={withdrawModalAnimated}
                options={PROPOSAL_CANCELLATION_REASON_OPTIONS}
                reason={reason}
                comment={comment}
                onReasonChange={setReason}
                onCommentChange={setComment}
                onCancel={() => {
                    setWithdrawModalOpen(false);
                    setWithdrawModalAnimated(false);
                }}
                onConfirm={handleWithdrawSubmit}
            />
            <DeclineProposalModal
                open={declineModalOpen}
                animated={declineModalAnimated}
                options={INTERVIEW_DECLINE_REASON_OPTIONS}
                reason={reason}
                comment={comment}
                onReasonChange={setReason}
                onCommentChange={setComment}
                onCancel={() => {
                    setDeclineModalOpen(false);
                    setDeclineModalAnimated(false);
                }}
                onConfirm={handleDeclineSubmit}
            />
            <RescheduleInterviewModal
              open={rescheduleModalOpen}
              value={suggestedInterviewTimes}
              onValueChange={setSuggestedInterviewTimes}
              onClose={() => {
                  setRescheduleModalOpen(false);
                  setSuggestedInterviewTimes([]);
              }}
              onSubmit={() => {
                  handleUpdateSlots();
                  setRescheduleModalOpen(false);
                  setSuggestedInterviewTimes([]);
              }}
            />
            <EditAvailableDatesModal
              open={editTimeModalOpen}
              value={suggestedInterviewTimes}
              onValueChange={setSuggestedInterviewTimes}
              onClose={() => {
                  setEditTimeModalOpen(false);
                  setSuggestedInterviewTimes([]);
              }}
              onSubmit={() => {
                  handleUpdateSlots();
                  setEditTimeModalOpen(false);
                  setSuggestedInterviewTimes([]);
              }}
            />

            { state.isEmployer &&
                <HiringConfirmationModal 
                    open={hiringConfirmationModalOpen}
                    onHire={handleHireClick}
                    onClose={() => setHiringConfirmationModalOpen(false)}
                    companyId={state.company!.id}
                    proposalId={proposalId!}
                    isAutoSendOn={isAutoSendOn}
                    isEmployerAddressFullyAvailable={isEmployerAddressFullyAvailable}
                />
            }
        
        
        </LocalPage>
    );
};

export default ProposalDetailsPage;