import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChatMessageType } from '../types';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { decodeHTMLEntities, formatTime } from '../../core/utils';
import GoogleTranslateLogo from '../../core/components/GoogleTranslateLogo';
import { ReactComponent as TrashCanIcon } from '../../assets/icon-trash-can.svg';

interface ChatMessageProps extends HTMLAttributes<HTMLDivElement> {
    message: ChatMessageType;
    onClickDelete: (id: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 5px;
  margin-top: 4px;
  
  > * {
    white-space: nowrap;
  }
`;

const TimeStampDeleteIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const DeleteIcon = styled(TrashCanIcon)`
  cursor: pointer;
  width: 16px;
  height: 18px;
`;

const Timestamp = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
`;

const TextButton = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  cursor: pointer;
  
  &:hover {
    color: #999999;
    text-decoration: underline;
  }
`;

const ChatMessage: FunctionComponent<ChatMessageProps> = ({
    message,
    onClickDelete,
}) => {
    const { state } = useReducerContext();
    const { t, i18n } = useTranslation();
    const [originalShown, setOriginalShown] = useState(false);
    const translatedText = decodeHTMLEntities(message
            .translations
            .find((translation) => translation.language.code === i18n.language)
            ?.text
        ?? message.originalText);
    const isOwner = state.isCandidate
        ? message.candidateId === state.candidate?.id
        : (state.isEmployer
            ? message.employerId === state.company?.employer?.id
            : message.partnerId === state.company?.partner?.id
        );

    return (
        <Container style={{ alignItems: isOwner ? 'flex-end' : 'flex-start'}}>
            <Message style={{ background: isOwner ? '#D9E8FF' : '#F6F6F6' }}>
                <div>{isOwner ? message.originalText : translatedText}</div>
                {originalShown && (
                    <div style={{ marginTop: 5, color: '#999999' }}>
                        {message.originalText}
                    </div>
                )}
            </Message>
            <BottomContainer>
                <TimeStampDeleteIconContainer>
                    { isOwner &&
                        <DeleteIcon onClick={() => onClickDelete(message.id)}/>
                    }
                    <Timestamp>{formatTime(message.createdAt)}</Timestamp>
                </TimeStampDeleteIconContainer>
                
                {!isOwner && (
                    <>
                        <TextButton onClick={() => setOriginalShown(!originalShown)}>
                            {t(originalShown ? 'core.hide_original' : 'core.show_original')}
                        </TextButton>
                        <GoogleTranslateLogo />
                    </>
                )}
            </BottomContainer>
        </Container>
    );
};

export default ChatMessage;