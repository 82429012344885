import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  Description,
  RadioButtonsContainer,
} from "../../commonStyles";
import NumberField from "../../../../../core/components/NumberField";
import { FormBlockSectionProps } from "../../types";
import Radio from "../../../../../core/components/Radio";
import Text from "../../../../../core/components/Text";
import MultiLangInput from "../../components/MultilingualInput";
import { canAssignEditableTrans, findItemByKind, prepareNewEditableTranslations } from "../../../../../core/utils";
import { EditableTranslationKind } from "../../../../../job/enums/translation";

interface VacationSectionProps extends FormBlockSectionProps {}

const VacationSection: FunctionComponent<VacationSectionProps> = ({
  employmentConditions: ec,
  company,
  onChange,
  onClickLangInputButton
}) => {
  const ecEditableTrans = ec?.editableTranslations ?? [];
  const empEditableTrans = company?.employer?.editableTranslations ?? [];

  return (
    <FormSection>
      <SectionHeading>休暇</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>年次有給休暇（6ヶ月勤務後）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <NumberField 
            placeholder="10"
            value={ec?.leavesAnnualPaidLeavesAfter_6MonthsDays ?? ""}
            onValueChange={val => onChange?.({ leavesAnnualPaidLeavesAfter_6MonthsDays: val })}
          />
          <Text>日</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>年次有給休暇（6ヶ月未満）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
              checked={
                ec?.leavesAnnualPaidLeavesBefore_6Months === true
              }
              onValueChange={() => onChange?.({ leavesAnnualPaidLeavesBefore_6Months: true })}
            >
              あり
            </Radio>
            <Radio
              checked={
                ec?.leavesAnnualPaidLeavesBefore_6Months === false
              }
              onValueChange={() => onChange?.({ leavesAnnualPaidLeavesBefore_6Months: false })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>

      { ec?.leavesAnnualPaidLeavesBefore_6Months &&
        <>
          <FormRow>
            <LabelContainer>
              <ItemLabel>6ヶ月未満の付与期間</ItemLabel>
            </LabelContainer>
            <InputContainer>
            <NumberField 
              placeholder="3"
              value={ec?.leavesAnnualPaidLeavesBefore_6MonthsMonths ?? ""}
              onValueChange={val => onChange?.({ leavesAnnualPaidLeavesBefore_6MonthsMonths: val })}
              />
            ヶ月
          </InputContainer>
          </FormRow>
          <FormRow>
            <LabelContainer>
              <ItemLabel>6ヶ月未満の付与日数</ItemLabel>
            </LabelContainer>
            <InputContainer>
            <NumberField 
              placeholder="5"
              value={ec?.leavesAnnualPaidLeavesBefore_6MonthsDays ?? ""}
              onValueChange={val => onChange?.({ leavesAnnualPaidLeavesBefore_6MonthsDays: val })}
              />
            日
          </InputContainer>
          </FormRow>
        </>
      }
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他の休暇（有給）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput 
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.LeavesOtherPaidLeaves)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "その他の休暇（有給）",
              kind: EditableTranslationKind.LeavesOtherPaidLeaves
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.LeavesOtherPaidLeaves
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.LeavesOtherPaidLeaves
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他の休暇（無給）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.LeavesOtherUnpaidLeaves)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "その他の休暇（無給）",
              kind: EditableTranslationKind.LeavesOtherUnpaidLeaves
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.LeavesOtherUnpaidLeaves
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.LeavesOtherUnpaidLeaves
              )
            }
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>一時帰国休暇</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <Description>乙が一時帰国を希望した場合は、上記の範囲内で必要な休暇を取得させることとする</Description>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>備考</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.LeavesNote)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "備考",
              kind: EditableTranslationKind.LeavesNote
            })}
            onClickReflectOrgInfo={() => onChange?.({
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.LeavesNote
              )
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.LeavesNote
              )
            }
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default VacationSection;
