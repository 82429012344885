import { FunctionComponent, useMemo } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  ItemTextInputCompact,
  SectionHeaderContainer,
  TransActionButton,
} from "../../commonStyles";
import { FormBlockSectionProps } from "../../types";
import styled from "styled-components";
import { isEmpty, isNotEmpty } from "../../../../../core/utils";
import { EmploymentCondition, EmploymentConditionExtended } from "../../../../types";
import { Company } from "../../../../../core/types/api";

interface EmployerSectionProps extends FormBlockSectionProps {}


const AdditionalHeaderContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 8px 30px 8px 20px;
`;

const EmployerSection: FunctionComponent<EmployerSectionProps> = ({
  employmentConditions: ec,
  onChange,
  company
}) => {

  const keyMapping: Partial<Record<keyof EmploymentCondition, keyof Company>> = {
    employerJaOrganizationName: "jaName",
    employerEnOrganizationName: "enName",
    employerPostalCode: "postalCode",
    employerJaPrefecture: "jaPrefecture",
    employerJaCityWard: "jaCityWard",
    employerJaTown: "jaTown",
    employerJaAddressNumber: "jaAddressNumber",
    employerJaBuilding: "jaBuildingName",
    employerEnPrefecture: "enPrefecture",
    employerEnCityWard: "enCityWard",
    employerEnTown: "enTown",
    employerEnAddressNumber: "enAddressNumber",
    employerEnBuilding: "enBuildingName",
    employerPhoneNumber: "phoneNumber",
    employerJaRepresentativeName: "jaRepresentativeName",
    employerEnRepresentativeName: "enRepresentativeName",
    employerJaRepresentativeTitle: "jaRepresentativeTitle",
    employerEnRepresentativeTitle: "enRepresentativeTitle",
  }

  const showOrgInfoReflectButton = useMemo(() => {
    if (!ec || !company) return false;

    return Object.entries(keyMapping).some(([ecKey, companyKey]) => {
      const ecVal = ec?.[ecKey as keyof EmploymentCondition];
      const companyVal = company?.[companyKey];

      return !!!ecVal && !!companyVal;
    });
  }, [ec, company]);

  const onClickReflectButton = () => {
    const newData: Partial<EmploymentConditionExtended> = {};
    const assignIfEmpty = <K extends keyof EmploymentCondition>(
      key: K,
      value: EmploymentCondition[K]
    ) => {
      if (!ec) return;

      if (isEmpty(ec[key])) {
        newData[key] = value;
      }
    }

    assignIfEmpty("employerJaOrganizationName", company?.jaName ?? '');
    assignIfEmpty("employerEnOrganizationName", company?.enName ?? '');
    assignIfEmpty("employerPostalCode", company?.postalCode ?? '');
    assignIfEmpty("employerJaPrefecture", company?.jaPrefecture ?? '');
    assignIfEmpty("employerJaCityWard", company?.jaCityWard ?? '');
    assignIfEmpty("employerJaTown", company?.jaTown ?? '');
    assignIfEmpty("employerJaAddressNumber", company?.jaAddressNumber ?? '');
    assignIfEmpty("employerJaBuilding", company?.jaBuildingName ?? '');
    assignIfEmpty("employerEnPrefecture", company?.enPrefecture ?? '');
    assignIfEmpty("employerEnCityWard", company?.enCityWard ?? '');
    assignIfEmpty("employerEnTown", company?.enTown ?? '');
    assignIfEmpty("employerEnAddressNumber", company?.enAddressNumber ?? '');
    assignIfEmpty("employerEnBuilding", company?.enBuildingName ?? '');
    assignIfEmpty("employerPhoneNumber", company?.phoneNumber ?? '');
    assignIfEmpty("employerJaRepresentativeName", company?.jaRepresentativeName ?? '');
    assignIfEmpty("employerEnRepresentativeName", company?.enRepresentativeName ?? '');
    assignIfEmpty("employerJaRepresentativeTitle", company?.jaRepresentativeTitle ?? '');
    assignIfEmpty("employerEnRepresentativeTitle", company?.enRepresentativeTitle ?? '');

    if (isNotEmpty(newData))
      onChange?.(newData);
  }

  return (
    <FormSection>
      <SectionHeaderContainer>
        <SectionHeading>雇用者</SectionHeading>
        <AdditionalHeaderContent>
          {showOrgInfoReflectButton && 
            <TransActionButton
              onClick={onClickReflectButton}
            >
              組織情報を一括反映
            </TransActionButton>
          }
        </AdditionalHeaderContent>
      </SectionHeaderContainer>
      <FormRow>
        <LabelContainer>
          <ItemLabel>会社/機関名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="日本工業株式会社" 
            value={ ec?.employerJaOrganizationName ?? ""}
            onTextChange={val => onChange?.({ employerJaOrganizationName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>会社/機関名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Nihon Kougyou" 
            value={ec?.employerEnOrganizationName ?? ""}
            onTextChange={val => onChange?.({ employerEnOrganizationName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            value={ec?.employerPostalCode ?? ""}
            onTextChange={val => onChange?.({ employerPostalCode: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="東京都" 
            value={ec?.employerJaPrefecture ?? ""}
            onTextChange={val => onChange?.({ employerJaPrefecture: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="港区" 
            value={ec?.employerJaCityWard ?? ""}
            onTextChange={val => onChange?.({ employerJaCityWard: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="海岸" 
            value={ec?.employerJaTown ?? ""}
            onTextChange={val => onChange?.({ employerJaTown: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="１丁目９−１８" 
            value={ec?.employerJaAddressNumber ?? ""}
            onTextChange={val => onChange?.({ employerJaAddressNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="國際浜松町ビル 2階" 
            value={ec?.employerJaBuilding ?? ""}
            onTextChange={val => onChange?.({ employerJaBuilding: val })}
          />
        </InputContainer>
      </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>都道府県（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Tokyo"
                    value={ec?.employerEnPrefecture ?? ""}
                    onTextChange={val => onChange?.({ employerEnPrefecture: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>市区（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Minato-ku"
                    value={ec?.employerEnCityWard ?? ""}
                    onTextChange={val => onChange?.({ employerEnCityWard: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>町村（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="kaigan"
                    value={ec?.employerEnTown ?? ""}
                    onTextChange={val => onChange?.({ employerEnTown: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>番地（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="1-9-18"
                    value={ec?.employerEnAddressNumber ?? ""}
                    onTextChange={val => onChange?.({ employerEnAddressNumber: val })}
                />
            </InputContainer>
        </FormRow>
        <FormRow>
            <LabelContainer>
                <ItemLabel>建物名等（英字）</ItemLabel>
            </LabelContainer>
            <InputContainer>
                <ItemTextInput
                    placeholder="Kokusai Hamamatsucho Building 2F"
                    value={ec?.employerEnBuilding ?? ""}
                    onTextChange={val => onChange?.({ employerEnBuilding: val })}
                />
            </InputContainer>
        </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>電話番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            value={ec?.employerPhoneNumber ?? ""}
            onTextChange={val => onChange?.({ employerPhoneNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="特定太郎" 
            value={ec?.employerJaRepresentativeName ?? ""}
            onTextChange={val => onChange?.({ employerJaRepresentativeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Taro Tokutei" 
            value={ec?.employerEnRepresentativeName ?? ""}
            onTextChange={val => onChange?.({ employerEnRepresentativeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者役職</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="代表取締役" 
            value={ec?.employerJaRepresentativeTitle ?? ""}
            onTextChange={val => onChange?.({ employerJaRepresentativeTitle: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>代表者役職（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="CEO" 
            value={ec?.employerEnRepresentativeTitle ?? ""}
            onTextChange={val => onChange?.({ employerEnRepresentativeTitle: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default EmployerSection;
