import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  TextContainer,
  Description,
  TextAndInputContainer,
} from "../../commonStyles";
import { FormBlockSectionProps } from "../../types";
import { LocalNumberField } from "../../../../../job/pages/JobFormPage/commonStyle";
import MultiLangInput from "../../components/MultilingualInput";
import { EditableTranslationKind } from "../../../../../job/enums/translation";
import { canAssignEditableTrans, findItemByKind, prepareNewEditableTranslations } from "../../../../../core/utils";

interface ResignationSectionProps extends FormBlockSectionProps {}

const ResignationSection: FunctionComponent<ResignationSectionProps> = ({
  employmentConditions: ec,
  company,
  onChange,
  onClickLangInputButton
}) => {
  const ecEditableTrans = ec?.editableTranslations ?? [];
  const empEditableTrans = company?.employer?.editableTranslations ?? [];

  return (
    <FormSection>
      <SectionHeading>退職に関する事項</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>自己都合退職の手続</ItemLabel>
        </LabelContainer>
        <TextAndInputContainer>
          <span>退職する</span>
              <LocalNumberField
                placeholder="14"
                value={ec?.resignationProcedureForResignationForPersonalReasons ?? ""}
                onValueChange={val => onChange?.({ resignationProcedureForResignationForPersonalReasons: val })}
                style={{ width: 80, textAlign: "right" }}
              />
          <span>日前に社長・工場長等に届けること</span>
        </TextAndInputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>解雇の事由及び手続</ItemLabel>
        </LabelContainer>
        <TextContainer>
          <Description>
            解雇は、やむを得ない事由がある場合に限り少なくとも30日前に予告をするか、又は30日分以上の平均賃金を支払って解雇する。特定技能外国人の責めに帰すべき事由に基づいて解雇する場合には、所轄労働基準監督署長の認定を受けることにより予告も平均賃金の支払も行わず即時解雇されることもあり得る。
          </Description>
        </TextContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>備考</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <MultiLangInput
            translation={findItemByKind(ecEditableTrans, EditableTranslationKind.ResignationNote)}
            onClickInput={() => onClickLangInputButton?.({
              heading: "備考",
              kind: EditableTranslationKind.ResignationNote
            })}
            onClickReflectOrgInfo={() => onChange?.({ 
              editableTranslations: prepareNewEditableTranslations(
                ecEditableTrans,
                empEditableTrans, 
                EditableTranslationKind.ResignationNote
              ) 
            })}
            showReflectOrgInfo={
              canAssignEditableTrans(
                ecEditableTrans, 
                empEditableTrans, 
                EditableTranslationKind.ResignationNote
              )
            }
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default ResignationSection;
